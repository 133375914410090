// Utils
import { type TLevelMapItem } from "@utils/api/types";

const formatLevelMap = (data: TLevelMapItem[][]): TLevelMapItem[][] => {
  for (const row of data) {
    for (const cell of row) {
      const { name } = cell;

      if (name) {
        if (["39", "40", "41"].indexOf(name) !== -1) {
          cell.type = "laser";
          cell.position = "BOTTOM";
        }

        if (["36", "37", "38"].indexOf(name) !== -1) {
          cell.type = "laser";
          cell.position = "RIGHT";
        }

        if (["30", "31", "32"].indexOf(name) !== -1) {
          cell.type = "laser";
          cell.position = "TOP";
        }

        if (["33", "34", "35"].indexOf(name) !== -1) {
          cell.type = "laser";
          cell.position = "LEFT";
        }

        if (["42", "43", "44"].indexOf(name) !== -1) {
          cell.type = "spike";
          cell.position = "TOP";
        }

        if (["45", "46", "47"].indexOf(name) !== -1) {
          cell.type = "spike";
          cell.position = "LEFT";
        }

        if (["48", "49", "50"].indexOf(name) !== -1) {
          cell.type = "spike";
          cell.position = "RIGHT";
        }

        if (["51", "52", "53"].indexOf(name) !== -1) {
          cell.type = "spike";
          cell.position = "BOTTOM";
        }
      }
    }
  }

  return data;
};

export default formatLevelMap;
