import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #000000;
`;

const Cover = styled.div`
  /* background: url("/assets/splash-screen.png") no-repeat; */
  height: calc(100vh - 162px);
  background-size: cover;
  background-position: center;
  padding: 28px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 100%;
  height: 50px;
  object-fit: contain;
`;

const Bottom = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 182px;
  border-radius: 30px 30px 0px 0px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

const LoaderText = styled.p`
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 15.3px;
  margin-right: -15.3px;
  text-align: right;
  text-transform: uppercase;
`;

const Version = styled.p`
  position: absolute;
  bottom: calc(100vh - var(--tg-viewport-height, 100vh));
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
`;

const Title = styled.p`
  margin: 30px 0 0 0;
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const List = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ListItemTitle = styled.p`
  color: #fc0;
  font-family: "Stapel_Bold";
  font-size: 40px;
  font-weight: 900;
  line-height: 26px;
  width: 33px;
  text-align: center;
`;

const ListItemValue = styled.p`
  color: #d1d1d6;
  font-size: 30px;
  font-weight: 300;
`;

const IconRow = styled.div`
  width: 356px;
  height: 356px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Space = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  position: absolute;
  /* animation: ${rotating} 5s infinite linear; */
`;

const Icon = styled.img`
  width: 150px;
  height: 150px;
  z-index: 2;
`;

const Based = styled.p`
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  z-index: 2;
`;

const BasedBlue = styled.span`
  color: #04bbf9;
  font-family: "Stapel_Bold";
  font-weight: 700;
`;

const Styles = {
  Container,
  Cover,
  Logo,
  Bottom,
  LoaderText,
  Version,
  Title,
  TitleYellow,
  List,
  ListItem,
  ListItemTitle,
  ListItemValue,
  IconRow,
  Space,
  Icon,
  Based,
  BasedBlue,
};

export default Styles;
