import * as React from "react";
import { Container } from "@pixi/react";
import type { ISpritesheetData, Spritesheet } from "pixi.js";

// Components
import WallBlock from "@components/Game/WallBlock";
import CoinBlock from "@components/Game/CoinBlock";
import EmptyBlock from "@components/Game/EmptyBlock";
import TargetBlock from "@components/Game/TargetBlock";
import SpikeBlock from "@components/Game/SpikeBlock";
import LaserBlock from "@components/Game/LaserBlock";
import EnemyBlock from "@components/Game/EnemyBlock";
import CannonBlock from "@components/Game/CannonBlock";

// Utils
import { type TLevelMapItem } from "@utils/api/types";
import type { TCoordinates } from "@utils/game";

interface Props {
  levelMap: TLevelMapItem[][];
  cellSize: number;
  updateEnemyPosition: (x: number, y: number, name: string) => void;
  sprites: Spritesheet<ISpritesheetData>;
  playerCoordinates: TCoordinates;
  onDamage: () => void;
}

const LevelMap: React.FC<Props> = (props) => {
  const {
    levelMap,
    cellSize,
    updateEnemyPosition,
    sprites,
    playerCoordinates,
    onDamage,
  } = props;

  return (
    <Container x={0} y={0}>
      {levelMap.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          const x = colIndex * cellSize;
          const y = rowIndex * cellSize;

          if (cell.type === "wall") {
            return (
              <WallBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                texture={sprites.textures?.[`wall_${cell.name}`]}
              />
            );
          } else if (cell.type === "empty" || cell.type === "player") {
            return (
              <EmptyBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                texture={sprites.textures.wall_empty}
              />
            );
          } else if (cell.type === "finish") {
            return (
              <TargetBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                texture={sprites.textures.target}
              />
            );
          } else if (cell.type === "coin") {
            return (
              <CoinBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                coinTexture={sprites.textures.coin}
                wallEmpty={sprites.textures.wall_empty}
              />
            );
          } else if (cell.type === "spike") {
            return (
              <SpikeBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                cell={cell}
                cellSize={cellSize}
                playerCoordinates={playerCoordinates}
                onDamage={onDamage}
                activeSpike={
                  sprites.textures[
                    `spike_${cell.position?.toLowerCase()}_active`
                  ]
                }
                spike={
                  sprites.textures[`spike_${cell.position?.toLowerCase()}`]
                }
              />
            );
          } else if (cell.type === "laser") {
            return (
              <LaserBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                texture={
                  sprites.textures[`laser_${cell.position?.toLowerCase()}`]
                }
              />
            );
          } else if (cell.type === "enemy") {
            return (
              <EnemyBlock
                key={`${rowIndex}-${colIndex}`}
                name={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                cell={cell}
                map={levelMap}
                cellSize={cellSize}
                updateEnemyPosition={updateEnemyPosition}
                wallEmpty={sprites.textures.wall_empty}
              />
            );
          } else if (cell.type === "cannon") {
            return (
              <CannonBlock
                key={`${rowIndex}-${colIndex}`}
                x={x}
                y={y}
                cellSize={cellSize}
                name={cell.name as string}
                map={levelMap}
                playerCoordinates={playerCoordinates}
                onDamage={onDamage}
              />
            );
          }
          return null;
        })
      )}
    </Container>
  );
};

export default LevelMap;
