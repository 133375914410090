import * as React from "react";
import { Container } from "@pixi/react";

interface Props {
  rowPlayerPos: number;
  colPlayerPos: number;
  gridWidth: number;
  gridHeight: number;
  cellSize: number;
  children?: React.ReactNode;
}

function calcScreenPosition(
  gridWidth: number,
  gridHeight: number,
  rowPlayerPos: number,
  colPlayerPos: number,
  cellSize: number
) {
  const x = gridWidth / 2 - colPlayerPos * cellSize;
  const y = gridHeight / 2 - rowPlayerPos * cellSize;
  return { x, y };
}

function lerp(start: number, end: number, t: number) {
  return start + (end - start) * t;
}

const PlayerScreen: React.FC<Props> = (props) => {
  const {
    rowPlayerPos,
    colPlayerPos,
    gridWidth,
    gridHeight,
    cellSize,
    children,
  } = props;

  const [screenPosition, setScreenPosition] = React.useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  React.useEffect(() => {
    const targetPosition = calcScreenPosition(
      gridWidth,
      gridHeight,
      rowPlayerPos,
      colPlayerPos,
      cellSize
    );

    let animationFrame: number;

    const animate = () => {
      setScreenPosition((prevPosition) => {
        const x = lerp(prevPosition.x, targetPosition.x, 0.1); // 0.1 - коэффициент сглаживания
        const y = lerp(prevPosition.y, targetPosition.y, 0.1);
        return { x, y };
      });

      animationFrame = requestAnimationFrame(animate);
    };

    animate();

    return () => cancelAnimationFrame(animationFrame);
  }, [rowPlayerPos, colPlayerPos]);

  return (
    <Container name="playerScreen" x={screenPosition.x} y={screenPosition.y}>
      {children}
    </Container>
  );
};

export default PlayerScreen;
