import * as React from "react";
import { ReactSVG } from "react-svg";

// Components
import Spinner from "@components/Spinner";

// Utils
import { formatPoints } from "@utils/format";

// Styles
import Styles from "./styles";

interface Props {
  title: string;
  value: number;
  maxValue?: number;
  icon: string;
  withLineBreak?: boolean;
  percentage?: number;
  isClaimable: boolean;
  isCompleted: boolean;
  isLoading: boolean;
  onStart?: () => void;
  onClickButton: () => void;
}

const AirdropCard: React.FC<Props> = (props) => {
  const {
    title,
    value,
    maxValue,
    icon,
    withLineBreak,
    percentage,
    isClaimable,
    isCompleted,
    isLoading,
    onStart,
    onClickButton,
  } = props;

  return (
    <Styles.Container>
      <Styles.Icon src={icon} alt="icon" />
      <Styles.Row>
        <Styles.Title>{title}</Styles.Title>
        <Styles.RewardRow>
          <Styles.CoinIcon src="/assets/cr-coin.png" alt="coin" />
          <Styles.Reward>
            {formatPoints(value)}{" "}
            {maxValue ? <Styles.MaxValue>of {maxValue}</Styles.MaxValue> : null}
          </Styles.Reward>
          <Styles.Symbol>$CP</Styles.Symbol>
        </Styles.RewardRow>
      </Styles.Row>
      {!isClaimable && !isCompleted ? (
        <Styles.Button onClick={onStart}>
          {percentage ? `${percentage}%` : "Start"}
        </Styles.Button>
      ) : (
        <Styles.Button
          className="active"
          disabled={isLoading || isCompleted}
          onClick={onClickButton}
        >
          {isLoading ? (
            <Spinner size={16} />
          ) : (
            <>
              {isClaimable ? (
                "Claim"
              ) : (
                <ReactSVG src="/assets/icons/check.svg" className="check" />
              )}
            </>
          )}
        </Styles.Button>
      )}
    </Styles.Container>
  );
};

export default AirdropCard;
