import styled, { keyframes } from "styled-components";

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const swipeUp = keyframes`
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &.shake {
    animation: ${shake} 0.5s infinite;
  }
`;

const SpinnerRow = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
`;

const PointsHandRow = styled.div`
  display: flex;
  justify-content: center;
`;

const PointsHand = styled.img`
  width: 32px;
  height: 32px;
  transition: all 0.5s;
  animation: ${swipeUp} 2s infinite;
  position: absolute;
  top: 70px;
  z-index: 2;
`;

const LivesHand = styled.img`
  width: 32px;
  height: 32px;
  transition: all 0.5s;
  animation: ${swipeUp} 2s infinite;
  position: absolute;
  top: 70px;
  right: 45px;
  z-index: 2;
`;

const Overlay = styled.div`
  &.active {
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.25);
    filter: blur(5px);
    z-index: 0;
  }
`;

const SkipButton = styled.button`
  z-index: 2;
  position: absolute;
  border-radius: 10px;
  border: 1px solid #aeaeb2;
  background-color: transparent;
  top: 60px;
  left: 20px;
  color: #aeaeb2;
  font-family: "Stapel-Light";
  font-size: 14px;
  font-weight: 300;
  padding: 8px;
`;

const Styles = {
  Container,
  SpinnerRow,
  PointsHandRow,
  PointsHand,
  LivesHand,
  Overlay,
  SkipButton,
};

export default Styles;
