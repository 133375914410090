import * as React from "react";
import { Sprite } from "@pixi/react";
import type { Resource, Texture } from "pixi.js";

interface Props {
  x: number;
  y: number;
  width: number;
  height: number;
  texture: Texture<Resource>;
}

const EmptyBlock: React.FC<Props> = (props) => {
  const { x, y, width, height, texture } = props;

  return <Sprite texture={texture} x={x} y={y} width={width} height={height} />;
};

export default EmptyBlock;
