import * as React from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { useLocalStorage, useWindowSize } from "usehooks-ts";
import dayjs from "dayjs";

// Components
import Wrapper from "@components/Wrapper";
import Button from "@components/Button";

// Sheets
import DayStreakSheet from "@sheets/DayStreak";

// Store
import { useAppSelector } from "@store/index";

// Utils
import { formatPoints } from "@utils/format";
import type { TDailyReward } from "@utils/api/types";

// Styles
import Styles from "./styles";

const DayStreakPage: React.FC = () => {
  const [isSheetVisible, setSheetVisible] = React.useState<boolean>(false);
  const [isConfettiVisible, setConfettiVisible] = React.useState<boolean>(true);
  const [dailyReward, setDailyRewards] = React.useState<TDailyReward | null>(
    null
  );

  const dayStreak = useAppSelector((state) => state.app.dayStreak);
  const dailyRewards = useAppSelector((state) => state.app.dailyRewards);

  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [, setDayStreakVisitDate] = useLocalStorage<string | null>(
    "day-streak-visit-date",
    null
  );

  React.useEffect(() => {
    setDayStreakVisitDate(dayjs().utc().toISOString());

    setTimeout(() => {
      setConfettiVisible(false);
    }, 3000);

    onGetRewards();
  }, []);

  const onGetRewards = (): void => {
    const getReward = dailyRewards.find((i) => i.day === dayStreak);

    if (getReward) {
      setDailyRewards(getReward);
    }
  };

  const onNext = (): void => {
    setSheetVisible(true);
  };

  const onCloseSheet = (): void => {
    navigate("/game", {
      replace: true,
    });
  };

  return (
    <Wrapper withProfile>
      {isConfettiVisible ? <Confetti width={width} height={height} /> : null}
      <Styles.Container>
        <Styles.Top>
          <Styles.Title>{dayStreak}</Styles.Title>
          <Styles.SubTitle>Day check-in</Styles.SubTitle>
          <Styles.Description>
            Come back every day to increase your streak. Don't skip a day, or
            you'll lose it!
          </Styles.Description>
        </Styles.Top>
        <Styles.Bottom>
          {dailyReward ? (
            <Styles.Rewards>
              <Styles.RewardCard>
                <Styles.Glow />
                <Styles.RewardIcon src="/assets/3coins.png" alt="icon" />
                <Styles.RewardValueRow>
                  <Styles.Reward>
                    +{formatPoints(dailyReward.points)}
                  </Styles.Reward>
                  <Styles.RewardLabel>crypto points</Styles.RewardLabel>
                </Styles.RewardValueRow>
              </Styles.RewardCard>
              <Styles.RewardCard>
                <Styles.Glow />
                <Styles.RewardIcon
                  src="/assets/day-streak-ticket.png"
                  alt="icon"
                />
                <Styles.RewardValueRow>
                  <Styles.Reward className="blue">
                    +{dailyReward.tickets}
                  </Styles.Reward>
                  <Styles.RewardLabel>ticket</Styles.RewardLabel>
                </Styles.RewardValueRow>
              </Styles.RewardCard>
            </Styles.Rewards>
          ) : null}

          <Button title="Next" onClick={onNext} />
          <Styles.BottomText>
            Check-ins are your best friend here. Keep them going to earn bonuses
            that are straight 🔥
          </Styles.BottomText>
        </Styles.Bottom>
      </Styles.Container>
      <DayStreakSheet
        isOpen={isSheetVisible}
        onClose={onCloseSheet}
        dayStreak={dayStreak}
      />
    </Wrapper>
  );
};

export default DayStreakPage;
