import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Bottom = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  padding: 25px 20px;
  padding-bottom: calc(25px + calc(100vh - var(--tg-viewport-height, 100vh)));
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 26px 0 0 0;
  }
`;

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: pre-line;
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  color: #c7c7cc;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
`;

const Dots = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Dot = styled.img`
  width: 14px;
  height: 14px;
`;

const SlideImage = styled.img``;

const Overlay = styled.div`
  opacity: 0.8;
  background: radial-gradient(
    57.1% 57.1% at 50% 50%,
    #bd52fd 0%,
    rgba(189, 82, 253, 0) 100%
  );
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: calc(100vh - 200px - calc(100vh - var(--tg-viewport-height, 100vh)));
`;

const Styles = {
  Container,
  Bottom,
  Title,
  Description,
  Dots,
  Dot,
  SlideImage,
  Overlay,
  Video,
};

export default Styles;
