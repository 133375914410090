import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #aeaeb2;
  font-size: 20px;
  font-weight: 400;
`;

const Icon = styled.img`
  margin: 15px 0 0 0;
  width: 100%;
  height: 200px;
`;

const ButtonRow = styled.div`
  padding: 30px 0 20px 0;
  width: 100%;
`;

const Styles = {
  Container,
  Title,
  Description,
  Icon,
  ButtonRow,
};

export default Styles;
