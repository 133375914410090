import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import dayjs from "dayjs";
import packageJson from "../../../package.json";

// Components
import Wrapper from "@components/Wrapper";
import ProgressBar from "@components/ProgressBar";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import {
  setCharacters,
  setDayStreak,
  setUser,
  setBalance,
  setLives,
  setSessionId,
  setDailyRewards,
  setMining,
  setFirstVisit,
} from "@store/reducers/app";
import { setLevelMap } from "@store/reducers/game";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { sleep } from "@utils/index";
import {
  createUser,
  getBalance,
  getCharacters,
  getDayStreak,
  getMaps,
  getUserInfo,
  getLives,
  getSessionId,
  getDailyRewards,
  getMiningInfo,
} from "@utils/api";

// Styles
import Styles from "./styles";

interface Props {
  children: React.ReactNode;
}

const list = ["Pick a character", "Earn $CP", "Get Airdrop"];

const SplashScreen: React.FC<Props> = (props) => {
  const { children } = props;

  const [isLoading, setLoading] = React.useState(true);

  const { initWebApp, initData } = useWebApp();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.app.user);

  const [dayStreakVisitDate] = useLocalStorage<string | null>(
    "day-streak-visit-date",
    null
  );

  React.useEffect(() => {
    initWebApp();
    onCheckInitData();
  }, []);

  React.useEffect(() => {
    if (user) {
      onGetData();
    }
  }, [user]);

  const onCheckInitData = async (): Promise<void> => {
    if (!initData) {
      return;
    }

    const user = await getUserInfo(initData);

    dispatch(setFirstVisit(user === null));

    if (user) {
      dispatch(setUser(user));
    } else {
      dispatch(setUser(await createUser(initData)));
    }
  };

  const onGetData = async (): Promise<void> => {
    if (!user) {
      return;
    }

    const sessionId = await getSessionId(initData);

    if (!sessionId) {
      return;
    }

    const timeOne = new Date().getTime();

    dispatch(setSessionId(sessionId));

    const characters = await getCharacters(initData, sessionId);

    dispatch(setLevelMap(await getMaps(initData, sessionId)));
    dispatch(setDayStreak(await getDayStreak(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
    dispatch(setLives(await getLives(initData, sessionId)));
    dispatch(setDailyRewards(await getDailyRewards(initData, sessionId)));
    dispatch(setMining(await getMiningInfo(initData, sessionId)));

    if (characters.length) {
      dispatch(setCharacters(characters));

      if (
        dayStreakVisitDate &&
        dayjs()
          .utc()
          .endOf("day")
          .diff(dayjs(dayStreakVisitDate).utc().endOf("day"), "days") <= 0
      ) {
        navigate("/game");
      } else {
        navigate("/day-streak");
      }
    }

    const timeTwo = new Date().getTime();
    const timeDiff = timeTwo - timeOne;

    if (timeDiff < 3000) {
      await sleep(3000 - timeDiff);
    }

    setLoading(false);
  };

  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <Wrapper>
      <Styles.Container>
        <Styles.Cover>
          <Styles.Logo src="/assets/new-logo.png" alt="logo" />
          <Styles.Title>
            Play to <Styles.TitleYellow>airdrop</Styles.TitleYellow>
          </Styles.Title>
          <Styles.List>
            {list.map((listItem, listItemIndex) => (
              <Styles.ListItem key={listItem}>
                <Styles.ListItemTitle>{listItemIndex + 1}</Styles.ListItemTitle>
                <Styles.ListItemValue>{listItem}</Styles.ListItemValue>
              </Styles.ListItem>
            ))}
          </Styles.List>
          <Styles.IconRow>
            <Styles.Space src="/assets/airdrop-space.gif" alt="space" />
            <Styles.Icon src="/assets/ton.png" alt="ton" />
            <Styles.Based>
              Based on <Styles.BasedBlue>ton</Styles.BasedBlue>
            </Styles.Based>
          </Styles.IconRow>
        </Styles.Cover>
        <Styles.Bottom>
          <ProgressBar value={40} />
          <Styles.LoaderText>Loading</Styles.LoaderText>
          <Styles.Version>v{packageJson.version}</Styles.Version>
        </Styles.Bottom>
      </Styles.Container>
    </Wrapper>
  );
};

export default SplashScreen;
