import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Bold";
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const ButtonRow = styled.div`
  padding: 25px 0;
`;

const Styles = {
  Title,
  TitleYellow,
  ButtonRow,
};

export default Styles;
