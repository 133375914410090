// Utils
import { type TLevelMapItem } from "@utils/api/types";

export type Direction = "UP" | "DOWN" | "RIGHT" | "LEFT";

export type Rectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type TCoordinates = {
  x: number;
  y: number;
};

export enum EWallPlayer {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right",
}

export const DIRECTION_TO_VECTOR: Record<Direction, TCoordinates> = {
  RIGHT: { x: 1, y: 0 },
  LEFT: { x: -1, y: 0 },
  UP: { x: 0, y: -1 },
  DOWN: { x: 0, y: 1 },
};

export const startPlayerPosition = (levelMap: TLevelMapItem[][]) => {
  let colPos = 0;
  let rowPos = 0;

  levelMap.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      if (cell.type === "player") {
        colPos = colIndex;
        rowPos = rowIndex;
      }
    });
  });

  return {
    rowPlayerPos: rowPos,
    colPlayerPos: colPos,
    wall: EWallPlayer.bottom,
  };
};

export const checkMove = (cell: TLevelMapItem): boolean => {
  const { type, name } = cell;
  return (
    type === "empty" ||
    type === "finish" ||
    type === "coin" ||
    type === "player" ||
    type === "enemy" ||
    name === "84" ||
    name === "85"
  );
};

export const collisionDetection = (
  rect1: Rectangle,
  rect2: Rectangle
): boolean => {
  return (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
};

export const getPlayerHitBox = (
  coordinates: TCoordinates,
  cellSize: number
): Rectangle => {
  return {
    x: coordinates.x - cellSize / 4 + 1,
    y: coordinates.y - cellSize / 4 + 2,
    width: cellSize / 4,
    height: cellSize / 4,
  };
};

export const getInitialCoordinates = (
  levelMap: TLevelMapItem[][],
  cellSize: number
): TCoordinates => {
  let x = 0;
  let y = 0;
  const d = cellSize / 2;

  levelMap.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      if (cell.type === "player") {
        x = colIndex * cellSize + d;
        y = rowIndex * cellSize + d;
      }
    });
  });

  return {
    x,
    y,
  };
};

export const multiplyVector = (
  factor: number,
  vector: TCoordinates
): TCoordinates => ({
  x: factor * vector.x,
  y: factor * vector.y,
});

export const directionToVector = (
  direction: Direction,
  distance = 1
): TCoordinates => multiplyVector(distance, DIRECTION_TO_VECTOR[direction]);

export const getLaserDeviation = (cell: TLevelMapItem): TCoordinates => {
  let x = 0;
  let y = 0;

  const { position } = cell;

  if (position === "BOTTOM") {
    y -= 2;
  }

  if (position === "RIGHT") {
    x -= 2;
  }

  if (position === "TOP") {
    y += 2;
  }

  if (position === "LEFT") {
    x += 2;
  }

  return {
    x,
    y,
  };
};

const LASER_PLAYER_ANGLES: Record<"TOP" | "RIGHT" | "BOTTOM" | "LEFT", number> =
  {
    TOP: 180,
    RIGHT: 270,
    BOTTOM: 0,
    LEFT: 90,
  };

export const checkPlayerLaserAngle = (
  playerAngle: number,
  cell: TLevelMapItem
): boolean => {
  const { position } = cell;

  if (position) {
    return playerAngle === LASER_PLAYER_ANGLES[position];
  }

  return false;
};

export const angleByDirection: Record<Direction, number> = {
  DOWN: 0,
  LEFT: 90,
  UP: 180,
  RIGHT: 270,
};

export const checkLaserDamage = (
  playerCoords: TCoordinates,
  cellSize: number,
  cell: TLevelMapItem,
  colIndex: number,
  rowIndex: number,
  playerAngle: number
): boolean => {
  const { x: deviationX, y: deviationY } = getLaserDeviation(cell);
  const d = cellSize / 2;

  const isPathXIncluded =
    playerCoords.x - d === (colIndex + deviationX) * cellSize;
  const isPathYIncluded =
    playerCoords.y - d === (rowIndex + deviationY) * cellSize;
  const isAngleRight = checkPlayerLaserAngle(playerAngle, cell);

  return isPathXIncluded && isPathYIncluded && isAngleRight;
};

export const getSpikeHitBoxDeviation = (cell: TLevelMapItem): TCoordinates => {
  let x = 0;
  let y = 0;

  const { position } = cell;

  if (position === "BOTTOM") {
    y -= 1;
  }

  if (position === "RIGHT") {
    x -= 1;
  }

  if (position === "TOP") {
    y += 1;
  }

  if (position === "LEFT") {
    x += 1;
  }

  return {
    x,
    y,
  };
};

export const checkSpikeDamage = (
  cell: TLevelMapItem,
  colIndex: number,
  playerCoords: TCoordinates,
  cellSize: number,
  rowIndex: number
): boolean => {
  const { x: deviationX, y: deviationY } = getSpikeHitBoxDeviation(cell);
  const d = cellSize / 2;

  const isAxisXCorrect =
    colIndex + deviationX === (playerCoords.x - d) / cellSize;
  const isAxisYCorrect =
    rowIndex + deviationY === (playerCoords.y - d) / cellSize;

  return isAxisXCorrect && isAxisYCorrect;
};
