import * as React from "react";

// Components
import Avatar from "@components/Avatar";

// Utils
import { formatPoints, short } from "@utils/format";

// Styles
import Styles from "./styles";

interface Props {
  rank: number;
  points: number;
  avatar: string;
  user_name: string | null;
  chat_id: string;
}

const LeaderboardCard: React.FC<Props> = (props) => {
  const { rank, points, avatar, user_name, chat_id } = props;

  return (
    <Styles.Container>
      <Avatar size={50} avatar={avatar} />
      <Styles.Row>
        <Styles.Username>
          {short(user_name || `user${chat_id}`, 15)}
        </Styles.Username>
        <Styles.Stats>
          <Styles.StatsIcon src="/assets/cr-coin.png" alt="coin" />
          <Styles.StatsValue>{formatPoints(points)}</Styles.StatsValue>
          <Styles.StatsValueSymbol>$CP</Styles.StatsValueSymbol>
        </Styles.Stats>
      </Styles.Row>
      {rank > 3 ? (
        <Styles.Rank>#{rank}</Styles.Rank>
      ) : (
        <Styles.CupIcon src={`/assets/icons/cup-${rank}.svg`} alt="cup" />
      )}
    </Styles.Container>
  );
};

export default LeaderboardCard;
