import * as React from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

// Components
import Wrapper from "@components/Wrapper";
import TaskCard from "@components/TaskCard";
import Spinner from "@components/Spinner";

// Store
import { tasksApi, useGetTasksQuery } from "@store/services/tasksApi";
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { groupArray } from "@utils/format";
import { startTask, claimTask, getBalance } from "@utils/api";
import type { TTask } from "@utils/api/types";

// Styles
import Styles from "./styles";

const TasksPage: React.FC = () => {
  const [sections, setSections] = React.useState<string[]>([]);
  const [sectionIndex, setSectionIndex] = React.useState<number>(0);
  const [tasks, setTasks] = React.useState<TTask[]>([]);

  const { initData, openLink, openTelegramLink } = useWebApp();

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.app.sessionId);

  const { data, isFetching, refetch } = useGetTasksQuery({
    initData,
    sessionId,
    section:
      sectionIndex !== 0 && sections.length
        ? sections[sectionIndex]
        : undefined,
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    setTasks([]);
    dispatch(tasksApi.util.resetApiState());

    refetch();
  }, [sectionIndex]);

  React.useEffect(() => {
    if (data?.length) {
      setTasks(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (tasks?.length && !sections.length) {
      onGetSections();
    }
  }, [sections.length, tasks?.length]);

  const onGetSections = (): void => {
    if (!tasks?.length) {
      return;
    }

    const mapSections = [...new Set(tasks.map((task) => task.section))];

    setSections(["All", ...mapSections]);
  };

  const onStartTask =
    (taskId: number, link: string) => async (): Promise<void> => {
      if (!sessionId) {
        return;
      }

      if (link.indexOf("/tutorial") !== -1) {
        navigate("/tutorial");
      } else if (link.indexOf("t.me") !== -1) {
        openTelegramLink(link);
      } else {
        openLink(link);
      }

      await startTask(initData, taskId, sessionId);
      refetch();
    };

  const onClaimTask = (taskId: number) => async (): Promise<void> => {
    if (!sessionId) {
      return;
    }

    await claimTask(initData, taskId, sessionId);
    refetch();

    dispatch(setBalance(await getBalance(initData, sessionId)));
  };

  const onClickTab = (tabIndex: number) => (): void => {
    setSectionIndex(tabIndex);
  };

  const groupTasks = groupArray(tasks, "section");

  return (
    <Wrapper withProfile withNavigation>
      <Styles.Container>
        <Styles.Title>Tasks</Styles.Title>
        <Styles.Description>
          <Styles.DescriptionBold>GET REWARDS</Styles.DescriptionBold> FOR
          COMPLETING QUESTS
        </Styles.Description>
        {sections.length ? (
          <Styles.Tabs>
            {sections.map((section, index) => (
              <Styles.Tab
                key={section}
                className={cn({
                  active: sectionIndex === index,
                })}
                onClick={onClickTab(index)}
              >
                {section}
              </Styles.Tab>
            ))}
          </Styles.Tabs>
        ) : null}
        {isFetching && !tasks.length ? (
          <Styles.SpinnerRow>
            <Spinner size={40} />
          </Styles.SpinnerRow>
        ) : null}
        {tasks?.length && sessionId ? (
          <Styles.Groups>
            {Object.keys(groupTasks).map((key) => (
              <Styles.Group key={key}>
                {sectionIndex === 0 ? (
                  <Styles.GroupTitle>{key}</Styles.GroupTitle>
                ) : null}
                {groupTasks[key].map((task) => (
                  <TaskCard
                    key={`${task.id}/${task.name}`}
                    task={task}
                    onStart={onStartTask(task.id, task.link)}
                    onClaim={onClaimTask(task.id)}
                    initData={initData}
                    sessionId={sessionId}
                    resetTask={refetch}
                  />
                ))}
              </Styles.Group>
            ))}
          </Styles.Groups>
        ) : null}
      </Styles.Container>
    </Wrapper>
  );
};

export default TasksPage;
