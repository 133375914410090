import * as React from "react";

// Hooks
import useIsomorphicLayoutEffect from "@hooks/useIsomorphicLayoutEffect";

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = React.useRef(callback);

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
};

export default useInterval;
