import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Expanded_Bold";
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
`;

const SubTitle = styled.p`
  margin: 10px 0 0 0;
  color: #aeaeb2;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  text-transform: uppercase;
`;

const CoverSpace = styled.img`
  position: absolute;
  z-index: -1;
  width: calc(100% + 40px);
  left: -20px;
  top: -30px;
  height: calc(100%+ 30px);
`;

const Rewards = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const RewardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 2;

  .reward {
    color: #ffffff;
    font-family: "Stapel_Semi_Expanded_Bold";
    font-size: 32px;
    font-weight: 700;
    line-height: 49px;
    color: #fc0;
  }
`;

const RewardIcon = styled.img`
  width: 45px;
  height: 45px;
`;

const Symbol = styled.p`
  color: #c7c7cc;
  font-family: "Stapel_Medium";
  font-size: 24px;
  font-weight: 500;
  line-height: 49px;
  text-transform: uppercase;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: calc(100vh - var(--tg-viewport-height, 100vh));
`;

const Shadow = styled.div`
  opacity: 0.25;
  background: radial-gradient(
    65.85% 65.85% at 50% 50%,
    #bd52fd 0%,
    rgba(189, 82, 253, 0) 100%
  );
  position: absolute;
  width: calc(100% + 40px);
  top: -30px;
  height: calc(100% + 30px);
  left: -20px;
`;

const RewardValue = styled.p`
  color: #04bbf9;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 32px;
  font-weight: 700;
  line-height: 49px;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Title,
  SubTitle,
  CoverSpace,
  Rewards,
  RewardRow,
  RewardIcon,
  Symbol,
  Actions,
  Shadow,
  RewardValue,
};

export default Styles;
