import styled, { keyframes } from "styled-components";

const whiteColor = keyframes`
  0% { fill: #04BBF9; }
  100% { fill: #ffffff; }
`;

const Container = styled.div`
  flex: 1;
  padding: 10px;
  background-color: rgba(19, 36, 60, 0.75);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  width: 195px;
  position: relative;

  .question {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 10px;

    &.active {
      svg > rect {
        animation: ${whiteColor} 0.5s infinite;
      }
    }
  }
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Boosters = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const Booster = styled.img`
  flex: 1;
  height: 80px;
  object-fit: contain;
`;

const BuyButton = styled.button`
  background: linear-gradient(90deg, #0992fd 0.75%, #fc35de 60.28%), #d9d9d9;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  border: none;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
`;

const BuyButtonIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const Stats = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StatsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StatsItemPlus = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const StatsItemIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const StatsItemValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
`;

const StatsItemSymbol = styled.p`
  margin: 0 0 0 -2.5px;
  color: #c7c7cc;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const QuestionIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Description = styled.div`
  margin: 5px 0 0 0;
  color: #c7c7cc;
  font-size: 12px;
  font-weight: 300;
`;

const Styles = {
  Container,
  Title,
  Boosters,
  Booster,
  BuyButton,
  BuyButtonIcon,
  Stats,
  StatsItem,
  StatsItemPlus,
  StatsItemIcon,
  StatsItemValue,
  StatsItemSymbol,
  QuestionIcon,
  Description,
};

export default Styles;
