import styled, { keyframes } from "styled-components";

const minOff = keyframes`
  to { width: 0%; }
`;

const Container = styled.div`
  position: absolute;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  height: 60px;
  z-index: 1;

  &.active {
    z-index: 2;
  }
`;

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Bold";
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
`;

const Progress = styled.div`
  width: 130px;
  height: 22px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProgressCoin = styled.img`
  width: 22px;
  height: 22px;
`;

const ProgressRow = styled.div`
  width: 108px;
  height: 22px;
`;

const ProgressRowArrowIcon = styled.img`
  width: 6px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 3px;
`;

const ProgressRowBorder = styled.div`
  width: 102px;
  height: 3px;
  background-color: #ffffff;
  position: absolute;
  left: 22px;

  &:last-child {
    bottom: 0;
  }
`;

const ProgressLineRow = styled.div`
  background-color: rgb(189 82 253 / 25%);
  height: 16px;
  width: 102px;
  position: absolute;
  left: 25px;
  top: 3px;
`;

const ProgressLine = styled.div`
  height: 16px;
  background-color: #bd52fd;
  position: absolute;
  left: 0;
  transition: all 0.5s;

  &.active {
    animation: ${minOff} 2s forwards;
  }
`;

const LivesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const LivesIcons = styled.div`
  width: 70px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img:not(:first-child) {
    margin: 0 0 0 -10px;
  }
`;

const LivesIcon = styled.img`
  width: 22px;
  height: 20px;
`;

const Lives = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Title,
  Progress,
  ProgressCoin,
  ProgressRow,
  ProgressRowArrowIcon,
  ProgressRowBorder,
  ProgressLineRow,
  ProgressLine,
  LivesRow,
  LivesIcons,
  LivesIcon,
  Lives,
};

export default Styles;
