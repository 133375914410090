export const formatPoints = (points: number): string => {
  return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatNameInitials = (
  firstName: string,
  lastName: string | null
): string => {
  return lastName
    ? `${lastName.charAt(0)}${firstName.charAt(0)}`
    : firstName.charAt(0);
};

export const abbreviateNumber = (value: number, fractionDigits = 1): string => {
  const SI_SYMBOL = ["", "K", "M", "B", "T", "P", "E"];

  const tier = (Math.log10(Math.abs(value)) / 3) | 0;

  if (tier === 0) {
    return value.toFixed(0).toString();
  }

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = value / scale;

  return `${scaled.toFixed(fractionDigits)}${suffix}`;
};

export const short = (
  data: string,
  max: number,
  wrap: "middle" | "end" = "middle"
): string => {
  if (max > data.length) {
    return data;
  }

  if (wrap === "end") {
    return `${data.slice(0, max)}...`;
  }

  return (
    data.substring(0, max / 2 - 1) +
    "..." +
    data.substring(data.length - max / 2 + 2, data.length)
  );
};

export const groupArray = <D>(
  data: D[],
  field: keyof D
): Record<string, D[]> => {
  return data.reduce((r, a) => {
    r[a[field]] = r[a[field]] || [];
    r[a[field]].push(a);
    return r;
  }, Object.create(null));
};
