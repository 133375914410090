import * as React from "react";

// Styles
import Styles from "./styles";

interface Props {
  value: number;
  disabled?: boolean;
  onClick: () => void;
  label: string;
  buttonIcon: string;
  icon: string;
  buttonTitle: string;
}

const SpinnerPageCard: React.FC<Props> = (props) => {
  const { value, disabled, onClick, label, buttonIcon, icon, buttonTitle } =
    props;

  return (
    <Styles.Card>
      <Styles.CardHeading>
        <Styles.CardIcon src={icon} alt="icon" />
        <Styles.CardValue>· {value}</Styles.CardValue>
      </Styles.CardHeading>
      <Styles.CardLabel>{label}</Styles.CardLabel>
      <Styles.CardButton disabled={disabled} onClick={onClick}>
        <Styles.CardButtonIcon src={buttonIcon} alt="icon" /> {buttonTitle}
      </Styles.CardButton>
    </Styles.Card>
  );
};

export default SpinnerPageCard;
