import type { ISpritesheetData } from "pixi.js";

const getSpritesData = (level: number): ISpritesheetData => ({
  frames: {
    wall_78: { frame: { x: 0, y: 0, w: 35, h: 35 } },
    coin: { frame: { x: 252, y: 108, w: 35, h: 35 } },
    wall_empty: { frame: { x: 36, y: 36, w: 35, h: 35 } },
    laser_right: { frame: { x: 144, y: 108, w: 35, h: 35 } },
    laser_top: { frame: { x: 144, y: 72, w: 35, h: 35 } },
    laser_left: { frame: { x: 144, y: 36, w: 35, h: 35 } },
    laser_bottom: { frame: { x: 144, y: 0, w: 35, h: 35 } },
    target: { frame: { x: 288, y: 108, w: 35, h: 35 } },
    wall_18: { frame: { x: 0, y: 0, w: 35, h: 35 } },
    wall_17: { frame: { x: 36, y: 0, w: 35, h: 35 } },
    wall_16: { frame: { x: 72, y: 0, w: 35, h: 35 } },
    wall_23: { frame: { x: 108, y: 0, w: 35, h: 35 } },
    wall_28: { frame: { x: 216, y: 0, w: 35, h: 35 } },
    wall_13: { frame: { x: 36, y: 72, w: 35, h: 35 } },
    wall_26: { frame: { x: 216, y: 72, w: 35, h: 35 } },
    wall_27: { frame: { x: 216, y: 108, w: 35, h: 35 } },
    wall_29: { frame: { x: 216, y: 36, w: 35, h: 35 } },
    wall_11: { frame: { x: 0, y: 36, w: 35, h: 35 } },
    wall_12: { frame: { x: 0, y: 72, w: 35, h: 35 } },
    wall_19: { frame: { x: 0, y: 108, w: 35, h: 35 } },
    wall_20: { frame: { x: 36, y: 108, w: 35, h: 35 } },
    wall_15: { frame: { x: 72, y: 36, w: 35, h: 35 } },
    wall_14: { frame: { x: 72, y: 72, w: 35, h: 35 } },
    wall_21: { frame: { x: 72, y: 108, w: 35, h: 35 } },
    wall_24: { frame: { x: 108, y: 36, w: 35, h: 35 } },
    wall_25: { frame: { x: 108, y: 72, w: 35, h: 35 } },
    wall_22: { frame: { x: 108, y: 108, w: 35, h: 35 } },
    wall_84: { frame: { x: 324, y: 108, w: 35, h: 35 } },
    wall_85: { frame: { x: 360, y: 108, w: 35, h: 35 } },
    wall_58: { frame: { x: 288, y: 0, w: 35, h: 35 } },
    wall_59: { frame: { x: 252, y: 36, w: 35, h: 35 } },
    wall_86: { frame: { x: 288, y: 36, w: 35, h: 35 } },
    wall_74: { frame: { x: 360, y: 72, w: 35, h: 35 } },
    spike_bottom_active: { frame: { x: 180, y: 0, w: 35, h: 35 } },
    spike_left_active: { frame: { x: 180, y: 36, w: 35, h: 35 } },
    spike_top_active: { frame: { x: 180, y: 72, w: 35, h: 35 } },
    spike_right_active: { frame: { x: 180, y: 108, w: 35, h: 35 } },
    spike_bottom: { frame: { x: 468, y: 36, w: 35, h: 35 } },
    spike_left: { frame: { x: 468, y: 72, w: 35, h: 35 } },
    spike_top: { frame: { x: 468, y: 108, w: 35, h: 35 } },
    spike_right: { frame: { x: 504, y: 0, w: 35, h: 35 } },
  },
  meta: {
    image: `/assets/game/sprites/level-${level}.png`,
    format: "RGBA8888",
    size: { w: 539, h: 143 },
    scale: 1,
  },
});

export default getSpritesData;
