import * as React from "react";
import { Sprite } from "@pixi/react";
import { useInterval } from "usehooks-ts";
import type { Resource, Texture } from "pixi.js";

// Utils
import type { TLevelMapItem } from "@utils/api/types";
import type { TCoordinates } from "@utils/game";

interface Props {
  x: number;
  y: number;
  cell: TLevelMapItem;
  cellSize: number;
  playerCoordinates: TCoordinates;
  onDamage: () => void;
  activeSpike: Texture<Resource>;
  spike: Texture<Resource>;
}

const SPIKE_STATES: Record<number, string> = {
  0: "spike_min-active",
  1: "spike_max-active",
};

type TExtraSpikePosition = {
  x: number;
  y: number;
  angle: number;
};

const SpikeBlock: React.FC<Props> = (props) => {
  const {
    cell,
    x,
    y,
    cellSize,
    playerCoordinates,
    onDamage,
    activeSpike,
    spike,
  } = props;

  const [isTriggered, setTriggered] = React.useState<boolean>(false);
  const [extraSpikePosition, setExtraSpikePosition] =
    React.useState<TExtraSpikePosition>({
      x,
      y,
      angle: 0,
    });
  const [spikeState, setSpikeState] = React.useState<number>(0);
  const [damageCoordinates, setDamageCoordinates] =
    React.useState<TCoordinates>({ x: 0, y: 0 });
  const [isActive, setActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isTriggered) {
      onCheckTriggered();
    }
  }, [playerCoordinates]);

  React.useEffect(() => {
    onCheckDamage();
  }, [playerCoordinates, spikeState]);

  React.useEffect(() => {
    if (!cell.position) {
      return;
    }

    const positions = {
      BOTTOM: { x: x + cellSize, y, angle: 180 },
      RIGHT: { x, y, angle: 90 },
      LEFT: { x: x + cellSize, y: y + cellSize, angle: 270 },
      TOP: { x, y: y + cellSize, angle: 0 },
    };

    setExtraSpikePosition(positions[cell.position]);

    const damageCoordinates = {
      BOTTOM: { x, y: y - cellSize },
      RIGHT: { x: x - cellSize, y },
      LEFT: { x: x + cellSize, y },
      TOP: { x, y: y + cellSize },
    };

    setDamageCoordinates(damageCoordinates[cell.position]);
  }, []);

  useInterval(() => {
    setActive((prev) => !prev);
  }, 500);

  useInterval(
    () => {
      if (spikeState === 1) {
        setSpikeState(0);
        setTriggered(false);
      } else {
        setSpikeState((prev) => prev + 1);
      }
    },
    isTriggered ? 500 : null
  );

  const onCheckTriggered = (): void => {
    const d = cellSize / 2;

    const isXCorrect = playerCoordinates.x - d === damageCoordinates.x;
    const isYCorrect = playerCoordinates.y - d === damageCoordinates.y;

    if (isXCorrect && isYCorrect && !isTriggered) {
      setTriggered(true);
    }
  };

  const onCheckDamage = (): void => {
    const d = cellSize / 2;

    const isXCorrect = playerCoordinates.x - d === damageCoordinates.x;
    const isYCorrect = playerCoordinates.y - d === damageCoordinates.y;

    if (isXCorrect && isYCorrect && isTriggered && spikeState === 1) {
      onDamage();
    }
  };

  return (
    <>
      {isTriggered ? (
        <Sprite
          image={`/assets/game/spikes/${SPIKE_STATES[spikeState]}.png`}
          x={extraSpikePosition.x}
          y={extraSpikePosition.y}
          width={cellSize}
          height={cellSize}
          angle={extraSpikePosition.angle}
        />
      ) : null}
      <Sprite
        texture={isActive ? activeSpike : spike}
        x={x}
        y={y}
        width={cellSize}
        height={cellSize}
      />
    </>
  );
};

export default SpikeBlock;
