import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  svg {
    animation: ${rotating} 1s infinite linear;
  }
`;

const Styles = {
  Container,
};

export default Styles;
