import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Bold";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Description = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
`;

const Actions = styled.div`
  margin: 25px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const ValueRow = styled.div`
  margin: 25px 0 0 0;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 15px;
  background-color: #000000;
  overflow: hidden;
  position: relative;
`;

const Coin = styled.img`
  width: 45px;
  height: 45px;
`;

const Glow = styled.div`
  width: 100px;
  height: 95px;
  background: url("/assets/glow.png") no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.p`
  margin: 0 0 0 -25px;
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 32px;
  font-weight: 700;
  line-height: 49px;
  text-transform: uppercase;
  text-decoration: line-through;
  text-decoration-color: #ff3b30;
  text-decoration-thickness: 5px;
`;

const ValueSymbol = styled.span`
  color: #c7c7cc;
  font-family: "Stapel_Medium";
  font-size: 24px;
  font-weight: 500;
  line-height: 49px;
  text-transform: uppercase;
`;

const Styles = {
  Title,
  Description,
  Actions,
  ValueRow,
  Coin,
  Glow,
  Value,
  ValueSymbol,
};

export default Styles;
