import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 36px;
  font-weight: 700;
  line-height: 26px;
`;

const Groups = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GroupStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GroupStatsPlus = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  text-transform: uppercase;
`;

const GroupStatsIcon = styled.img`
  width: 28px;
  height: 28px;
`;

const GroupStatsValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 22px;
  font-weight: 400;
  line-height: 31px;
  text-transform: uppercase;
`;

const GroupStatsSymbol = styled.p`
  margin: 0 0 0 -2.5px;
  color: #c7c7cc;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const GroupDescription = styled.p`
  color: #aeaeb2;
  font-size: 20px;
  font-weight: 300;
  white-space: pre-line;
`;

const ButtonRow = styled.div`
  padding: 16px 0;
`;

const Styles = {
  Container,
  Title,
  Groups,
  Group,
  GroupStats,
  GroupStatsPlus,
  GroupStatsIcon,
  GroupStatsValue,
  GroupStatsSymbol,
  GroupDescription,
  ButtonRow,
};

export default Styles;
