import * as React from "react";

// Utils
import type { Direction } from "@utils/game";

const useKeyboardControls = (movePlayer: (direction: Direction) => void) => {
  const [keyPressed, setKeyPressed] = React.useState<string | null>(null);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (keyPressed === null) {
        if (event.key === "ArrowUp") {
          setKeyPressed("up");
          movePlayer("UP");
        } else if (event.key === "ArrowDown") {
          setKeyPressed("down");
          movePlayer("DOWN");
        } else if (event.key === "ArrowLeft") {
          setKeyPressed("left");
          movePlayer("LEFT");
        } else if (event.key === "ArrowRight") {
          setKeyPressed("right");
          movePlayer("RIGHT");
        }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (
        event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight"
      ) {
        setKeyPressed(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [movePlayer, keyPressed]);
};

export default useKeyboardControls;
