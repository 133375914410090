import * as React from "react";

type UseCounterReturn = {
  count: number;
  increment: () => void;
  decrement: () => void;
  reset: () => void;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

const useCounter = (
  initialValue?: number,
  initialStep: number = 1
): UseCounterReturn => {
  const [count, setCount] = React.useState(initialValue ?? 0);
  const [step, setStep] = React.useState<number>(initialStep ?? 1);

  React.useEffect(() => {
    setCount(initialValue ?? 0);
  }, [initialValue]);

  React.useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const increment = React.useCallback(() => {
    setCount((x) => +Number(x + step).toFixed(1));
  }, [step]);

  const decrement = React.useCallback(() => {
    setCount((x) => +Number(x - step).toFixed(1));
  }, [step]);

  const reset = React.useCallback(() => {
    setCount(initialValue ?? 0);
  }, [initialValue]);

  return {
    count,
    increment,
    decrement,
    reset,
    setCount,
  };
};

export default useCounter;
