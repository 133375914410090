import * as React from "react";
import cn from "classnames";
import { useCountdown } from "usehooks-ts";
import dayjs from "dayjs";

// Components
import Avatar from "@components/Avatar";

// Utils
import { formatPoints, short } from "@utils/format";
import type { THillKing } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  king: THillKing;
  onOpenProfile: () => void;
}

const formatTime = (data: number): string => {
  const sec_num = parseInt(Number(data).toString(), 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  let time = [];

  if (Number(hours) > 0) {
    time = [hours, minutes, seconds];
  } else {
    time = [minutes, seconds];
  }

  return time.map((v) => (v < 10 ? "0" + v : v)).join(":");
};

const TopUser: React.FC<Props> = (props) => {
  const {
    king: { rank, avatar_url, user_name, user_id, balance, updated_at },
    onOpenProfile,
  } = props;

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: dayjs().utc().diff(dayjs(updated_at), "seconds"),
    countStop: Infinity,
    isIncrement: true,
    intervalMs: 1000,
  });

  React.useEffect(() => {
    resetCountdown();
    startCountdown();
  }, [updated_at]);

  return (
    <Styles.Container>
      <Styles.AvatarRow
        className={cn({
          gold: rank === 1,
          silver: rank === 2,
          bronze: rank === 3,
        })}
        onClick={onOpenProfile}
      >
        <Styles.Time>{formatTime(count)}</Styles.Time>
        <Avatar size={0} avatar={avatar_url} />
      </Styles.AvatarRow>
      <Styles.Stats>
        <Styles.Name>{short(user_name || `user${user_id}`, 13)}</Styles.Name>
        <Styles.BalanceRow>
          <Styles.BalanceIcon src="/assets/cr-coin.png" alt="coin" />
          <Styles.Balance>{formatPoints(balance)}</Styles.Balance>
        </Styles.BalanceRow>
      </Styles.Stats>
    </Styles.Container>
  );
};

export default TopUser;
