import styled from "styled-components";

const Title = styled.p`
  color: #fff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 36px;
  font-weight: 400;
  line-height: 26px;
`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #aeaeb2;
  font-size: 20px;
  font-weight: 400;
`;

const Actions = styled.div`
  margin: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Styles = {
  Title,
  Description,
  Actions,
};

export default Styles;
