import styled from "styled-components";

const Container = styled.button`
  height: 60px;
  border-radius: 10px;
  border: none;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;

  &.primary {
    background: linear-gradient(90deg, #0992fd 0%, #fc35de 92.53%);
    box-shadow: 0px 3.161px 0px 0px rgba(255, 255, 255, 0.25);
    color: #ffffff;

    &:active:not(:disabled) {
      background: #ffffff;
      color: #000000;
    }
  }

  &.outline {
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: transparent;

    &:active:not(:disabled) {
      border: 1px solid #0992fd;
      color: #0992fd;
    }
  }

  &.notfilled {
    background-color: transparent;
    color: #aeaeb2;

    &:active:not(:disabled) {
      color: #0992fd;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const Price = styled.p`
  color: #fc0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

const PriceIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const YellowTitle = styled.span`
  color: #fc0;
`;

const Styles = {
  Container,
  Icon,
  PriceRow,
  Price,
  PriceIcon,
  YellowTitle,
};

export default Styles;
