import * as React from "react";
import { TypeAnimation } from "react-type-animation";
import cn from "classnames";

// Config
import texts from "./texts";

// Styles
import Styles from "./styles";

interface Props {
  step: number;
  onClickButton: () => void;
  isActive?: boolean;
}

const TutorialCard: React.FC<Props> = (props) => {
  const { step, onClickButton, isActive } = props;

  const [text, setText] = React.useState<string | null>(null);
  const [isButtonVisible, setButtonVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    setText(null);
    setButtonVisible(false);

    setTimeout(() => {
      setText(texts[step]);
    }, 50);
  }, [step]);

  const onSequenceComplete = (): void => {
    setButtonVisible(true);
  };

  return (
    <Styles.Container
      className={cn({
        active: isActive,
      })}
    >
      <Styles.Card>
        <Styles.DarkCorner />
        <Styles.AvatarRow>
          <Styles.Corner className="corner-top" />
          <Styles.Corner className="corner-bottom-right" />
          <Styles.Corner className="corner-bottom-left" />
          <Styles.NameRow>
            <Styles.Name>Azura</Styles.Name>
          </Styles.NameRow>
        </Styles.AvatarRow>
        <Styles.DividerLine />
        <Styles.Grid>
          <Styles.Corner className="corner-top-left" />
          <Styles.Corner className="corner-top-right" />
          <Styles.Corner className="corner-bottom-left" />
          {text ? (
            <TypeAnimation
              sequence={[text, onSequenceComplete]}
              wrapper="span"
              speed={90}
            />
          ) : null}
          {isButtonVisible && step !== 1 ? (
            <Styles.Button onClick={onClickButton}>
              {step === 0 ? "Start" : "Ok"}{" "}
              <Styles.ButtonIcon
                src="/assets/icons/card-button-arrow.svg"
                alt="icon"
              />
            </Styles.Button>
          ) : null}
        </Styles.Grid>
        <Styles.DarkCorner />
      </Styles.Card>
    </Styles.Container>
  );
};

export default TutorialCard;
