import { createRoot } from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
// import { TwaAnalyticsProvider } from "@tonsolutions/telemetree-react";

// Pages
import OnboardingPage from "@pages/Onboarding";
import SelectCharacterPage from "@pages/SelectCharacter";
import GamePage from "@pages/Game";
import LeaderboardPage from "@pages/Leaderboard";
import TasksPage from "@pages/Tasks";
import ReferalPage from "@pages/Referal";
import AirdropPage from "@pages/Airdrop";
import ShopPage from "@pages/Shop";
import CryptoBoxPage from "@pages/CryptoBox";
import GameplayPage from "@pages/Gameplay";
import DayStreakPage from "@pages/DayStreak";
import TutorialPage from "@pages/Tutorial";
import TestLevelsPage from "@pages/TestLevels";
import SpinnerPage from "@pages/Spinner";
import ShopOfferPage from "@pages/ShopOffer";

// Store
import { store } from "@store/index";

import Root from "./Root";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <OnboardingPage /> },
      { path: "/select-character", element: <SelectCharacterPage /> },
      { path: "/game", element: <GamePage /> },
      { path: "/leaderboard", element: <LeaderboardPage /> },
      { path: "/tasks", element: <TasksPage /> },
      { path: "/referal", element: <ReferalPage /> },
      { path: "/airdrop", element: <AirdropPage /> },
      { path: "/shop", element: <ShopPage /> },
      { path: "/crypto-box", element: <CryptoBoxPage /> },
      { path: "/gameplay", element: <GameplayPage /> },
      { path: "/day-streak", element: <DayStreakPage /> },
      { path: "/tutorial", element: <TutorialPage /> },
      { path: "/test-levels", element: <TestLevelsPage /> },
      { path: "/spinner", element: <SpinnerPage /> },
      { path: "/shop-offer", element: <ShopOfferPage /> },
    ],
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <TonConnectUIProvider manifestUrl="https://webapp-dev.cryptorush.fun/tonconnect-manifest.json">
    <Provider store={store}>
      {/* <TwaAnalyticsProvider
        projectId="ae436dd8-a739-42c7-9a72-1662732d9399"
        apiKey="a7a2cc71-d25b-4bd7-bc13-c768bf3d6896"
        appName="cryptorush"
      > */}
      <App router={router} />
      {/* </TwaAnalyticsProvider> */}
    </Provider>
  </TonConnectUIProvider>
);

reportWebVitals();
