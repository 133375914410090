import styled from "styled-components";

const Container = styled.div`
  border-radius: 6px;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0 20px 0 0;
`;

const IconRow = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.5px;
`;

const Name = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 400;
`;

const RewardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const RewardIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const Reward = styled.p`
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
`;

const RewardSymbol = styled.p`
  margin: 0 0 0 -2.5px;
  color: #c7c7cc;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 65px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #ffffff;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border: 1px solid #34c759;
    background-color: #34c759;
  }

  .check {
    width: 18px;
    height: 18px;

    > div > svg {
      width: 18px;
      height: 18px;

      path {
        fill: #ffffff;
        stroke: #ffffff;
      }
    }
  }
`;

const Styles = {
  Container,
  IconRow,
  Icon,
  Row,
  Name,
  RewardRow,
  RewardIcon,
  Reward,
  RewardSymbol,
  Button,
};

export default Styles;
