import * as React from "react";
import { fromNano } from "@ton/core";

// Components
import Wrapper from "@components/Wrapper";
import RouletteWheel from "@components/RouletteWheel";
import Button from "@components/Button";
import RoulettePrize from "@components/RoulettePrize";
import Card from "./SpinnerPageCard";

// Sheets
import BuyTicketsSheet from "@sheets/BuyTickets";
import PaymentApprovedSheet from "@sheets/PaymentApproved";
import PaymentNotApprovedSheet from "@sheets/PaymentNotApproved";
import WithdrawSheet from "@sheets/Withdraw";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { getBalance, getRouletteGifts, wheelRoulette } from "@utils/api";
import type { TRouletteGift } from "@utils/api/types";

// Styles
import Styles from "./styles";

const SpinnerPage: React.FC = () => {
  const [isSpinning, setSpinning] = React.useState<boolean>(false);
  const [prizeNumber, setPrizeNumber] = React.useState(0);
  const [isWinning, setWinning] = React.useState<boolean>(false);
  const [activeSheet, setActiveSheet] = React.useState<
    "buy-tickets" | "approved" | "not-approved" | "withdraw" | null
  >(null);
  const [gifts, setGifts] = React.useState<TRouletteGift[]>([]);
  const [prizeGift, setPrizeGift] = React.useState<TRouletteGift | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const balance = useAppSelector((state) => state.app.balance);
  const sessionId = useAppSelector((state) => state.app.sessionId);

  const { initData, impactOccurred } = useWebApp();

  React.useEffect(() => {
    onGetGifts();
  }, []);

  const onGetGifts = async (): Promise<void> => {
    setGifts(await getRouletteGifts(initData, sessionId));
  };

  const onStartSpin = async (): Promise<void> => {
    if (isSpinning || isLoading) {
      return;
    }

    setLoading(true);

    impactOccurred("medium");

    const wheel = await wheelRoulette(initData, sessionId);

    setPrizeGift(wheel);

    if (!wheel) {
      setLoading(false);
      return;
    }

    const findIndex = gifts.findIndex((gift) => gift.id === wheel.id);

    if (findIndex === -1) {
      return;
    }

    setPrizeNumber(findIndex);
    setSpinning(true);
    setLoading(false);
  };

  const onClosePrize = (): void => {
    setWinning(false);
    setPrizeGift(null);
  };

  const onBuyTickets = (): void => {
    setActiveSheet("buy-tickets");
  };

  const onCloseSheet = (): void => {
    setActiveSheet(null);
  };

  const onSuccessPaid = async (): Promise<void> => {
    setActiveSheet("approved");

    if (sessionId) {
      dispatch(setBalance(await getBalance(initData, sessionId)));
    }
  };

  const onFailedPaid = (): void => {
    setActiveSheet("not-approved");
  };

  const onStopSpinning = async (): Promise<void> => {
    impactOccurred("medium");

    setWinning(true);
    setSpinning(false);

    if (sessionId) {
      dispatch(setBalance(await getBalance(initData, sessionId)));
    }
  };

  const onWithdraw = (): void => {
    setActiveSheet("withdraw");
  };

  return (
    <Wrapper withProfile withNavigation>
      <Styles.Container>
        <Styles.Overlay />
        <Styles.Row>
          <Styles.Cards>
            <Card
              value={Number(balance?.tickets)}
              onClick={onBuyTickets}
              label="tickets"
              buttonIcon="/assets/icons/tickets.svg"
              icon="/assets/spinner-ticket.png"
              buttonTitle="Tickets"
            />
            <Card
              value={+fromNano(Number(balance?.ton_balance || 0))}
              onClick={onWithdraw}
              disabled={!Number(balance?.ton_balance)}
              label="ton"
              buttonIcon="/assets/icons/withdraw.svg"
              icon="/assets/spinner-ton.png"
              buttonTitle="Withdraw"
            />
          </Styles.Cards>
          <RouletteWheel
            gifts={gifts}
            isSpinning={isSpinning}
            prizeNumber={prizeNumber}
            onStopSpinning={onStopSpinning}
          />
        </Styles.Row>
        {gifts.length ? (
          <Styles.Bottom>
            {Number(balance?.tickets) > 0 ? (
              <Button
                title={
                  isSpinning || isLoading
                    ? "Wait for the reward..."
                    : "Start spin"
                }
                onClick={onStartSpin}
                disabled={isSpinning || isLoading}
                yellowTitle={isSpinning || isLoading ? undefined : "1"}
                rightIcon={
                  isSpinning || isLoading
                    ? undefined
                    : "/assets/icons/ticket.png"
                }
              />
            ) : (
              <Button title="Buy tickets" onClick={onBuyTickets} />
            )}
          </Styles.Bottom>
        ) : null}
      </Styles.Container>
      {isWinning && prizeGift ? (
        <RoulettePrize gift={prizeGift} onClose={onClosePrize} />
      ) : null}
      <BuyTicketsSheet
        isOpen={activeSheet === "buy-tickets"}
        onClose={onCloseSheet}
        onSuccessPaid={onSuccessPaid}
        onFailedPaid={onFailedPaid}
      />
      <PaymentApprovedSheet
        isOpen={activeSheet === "approved"}
        onClose={onCloseSheet}
      />
      <PaymentNotApprovedSheet
        isOpen={activeSheet === "not-approved"}
        onClose={onCloseSheet}
      />
      <WithdrawSheet
        isOpen={activeSheet === "withdraw"}
        onClose={onCloseSheet}
      />
    </Wrapper>
  );
};

export default SpinnerPage;
