import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: url("/assets/main-background.png") no-repeat;
  background-size: cover;
  background-position: center 0;
`;

const Row = styled.div`
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  z-index: 2;
`;

const Logo = styled.img`
  width: 100%;
  height: 48px;
  object-fit: contain;
`;

const BalanceRow = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const TicketsLabel = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

const TicketsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TicketIcon = styled.img`
  width: 30px;
  height: 42px;
`;

const Tickets = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
`;

const Bottom = styled.div`
  border-radius: 25px 25px 0px 0px;
  background-color: #1c1d1f;
  padding: 16px 28px;
  z-index: 3;

  button {
    height: 50px;

    img {
      width: 12px;
      height: 17px;
    }
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  filter: blur(2px);
  position: absolute;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  gap: 40px;
`;

const Card = styled.div`
  flex: 1;
  border-radius: 8px;
  background-color: #221e2a;
  padding: 12px 0 0 0;
`;

const CardHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CardIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const CardValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
`;

const CardLabel = styled.p`
  margin: 3px 0 0 0;
  color: #d1d1d6;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
`;

const CardButton = styled.button`
  margin: 12px 0 0 0;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #bd52fd;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Stapel-Light";

  &:disabled {
    opacity: 0.5;
  }
`;

const CardButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Styles = {
  Container,
  Row,
  Logo,
  BalanceRow,
  TicketsLabel,
  TicketsRow,
  TicketIcon,
  Tickets,
  Bottom,
  Overlay,
  Cards,
  Card,
  CardHeading,
  CardIcon,
  CardValue,
  CardLabel,
  CardButton,
  CardButtonIcon,
};

export default Styles;
