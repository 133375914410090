import * as React from "react";
import cn from "classnames";
import { Spritesheet, Texture, utils, type ISpritesheetData } from "pixi.js";

// Components
import Wrapper from "@components/Wrapper";
import PixiProvider from "@components/PixiProvider";
import UserInterface from "@components/Game/UserInterface";
import PlayerScreen from "@components/Game/PlayerScreen";
import LevelMap from "@components/Game/LevelMap";
import Player from "@components/Game/Player";
import Spinner from "@components/Spinner";

// Hooks
import useMapSize from "@hooks/useMapSize";
import useWebApp from "@hooks/useWebApp";
import useTouch from "@hooks/useTouch";
import useKeyboardControls from "@hooks/useKeyboardControls";

// Utils
import formatLevelMap from "@utils/formatLevelMap";
import type { TLevelMapItem, TSkin } from "@utils/api/types";
import {
  getInitialCoordinates,
  DIRECTION_TO_VECTOR,
  checkMove,
  angleByDirection,
  type Direction,
  type TCoordinates,
} from "@utils/game";

// Config
import getSpritesData from "@config/spritesData";
import { DEFAULT_LIVES } from "@config/consts";

// Styles
import Styles from "./styles";

type TEnemy = TCoordinates & {
  name: string;
};

const skin = {
  default_speed: 60,
};

const TestLevelsPage: React.FC = () => {
  const [day, setDay] = React.useState<number>(1);
  const [level, setLevel] = React.useState<number>(1);
  const [style, setStyle] = React.useState<number>(1);
  const [screenAnimation, setScreenAnimation] = React.useState<"shake" | null>(
    null
  );
  const [sprites, setSprites] =
    React.useState<Spritesheet<ISpritesheetData> | null>(null);
  const [isLevelMapLoading, setLevelMapLoading] =
    React.useState<boolean>(false);
  const [levelMap, setLevelMap] = React.useState<TLevelMapItem[][]>([]);
  const [playerCoordinates, setPlayerCoordinates] =
    React.useState<TCoordinates>({
      x: 0,
      y: 0,
    });
  const [playerAngle, setPlayerAngle] = React.useState(0);
  const [enemies, setEnemies] = React.useState<TEnemy[]>([]);
  const [score, setScore] = React.useState<number>(0);
  const [isLevelCompleted, setLevelCompleted] = React.useState<boolean>(false);
  const [totalCoins, setTotalCoins] = React.useState<number>(0);
  const [lives, setLives] = React.useState<number>(DEFAULT_LIVES);
  const [activeModal, setActiveModal] = React.useState<
    "game-over" | "level-completed" | null
  >(null);
  const [isDamaged, setDamaged] = React.useState<boolean>(false);
  const [moveCoordinates, setMoveCoordinates] = React.useState<TCoordinates[]>(
    []
  );
  const [nextDirection, setNextDirection] = React.useState<Direction | null>(
    null
  );
  const [isPlayerMoving, setPlayerMoving] = React.useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { gridWidth, gridHeight, cellSize } = useMapSize();
  const { initData, impactOccurred } = useWebApp();

  React.useEffect(() => {
    if (screenAnimation) {
      setTimeout(() => {
        setScreenAnimation(null);
      }, 500);
    }
  }, [screenAnimation]);

  React.useEffect(() => {
    if (nextDirection && !isPlayerMoving) {
      onMovePlayer();
    }
  }, [nextDirection, isPlayerMoving]);

  React.useEffect(() => {
    onCheckEnemies();
  }, [enemies, playerCoordinates]);

  const onCheckEnemies = (): void => {
    if (isLevelCompleted) {
      return;
    }

    const d = cellSize / 2;
    const findEnemy = enemies.find(
      (i) => i.x === playerCoordinates.x - d && i.y === playerCoordinates.y - d
    );

    if (findEnemy) {
      onDamage();
    }
  };

  const onCollectCoin = (x: number, y: number): void => {
    setLevelMap((prevMap) =>
      prevMap.map((rowArray, rowIndex) =>
        rowArray.map((cell, colIndex) => {
          if (colIndex === x && rowIndex === y) {
            impactOccurred("medium");
            setScore((prev) => prev + 1);

            return {
              type: "empty",
            };
          }

          return cell;
        })
      )
    );
  };

  const onMovePlayer = async (): Promise<void> => {
    if (!nextDirection || isPlayerMoving || isLevelCompleted) {
      return;
    }

    setPlayerMoving(true);

    const directionToVector = DIRECTION_TO_VECTOR[nextDirection];
    const axis = directionToVector.x === 0 ? "y" : "x";
    const { x, y } = playerCoordinates;

    const d = cellSize / 2;
    let currentPositionX = (x - d) / cellSize + directionToVector.x;
    let currentPositionY = (y - d) / cellSize + directionToVector.y;

    while (checkMove(levelMap[currentPositionY][currentPositionX])) {
      await sleep(skin.default_speed / 3);
      const currentWall = levelMap[currentPositionY][currentPositionX];

      if (currentWall.type === "coin") {
        onCollectCoin(currentPositionX, currentPositionY);
      }

      const findEnemy = enemies.find(
        (i) =>
          i.x === currentPositionX * cellSize &&
          i.y === currentPositionY * cellSize
      );

      if (findEnemy) {
        onDamage();
      }

      if (currentWall.type === "finish") {
        impactOccurred("medium");
        setLevelCompleted(true);
      }

      setPlayerCoordinates((prev) => ({
        ...prev,
        [axis]: prev[axis] + directionToVector[axis] * cellSize,
      }));

      currentPositionX += directionToVector.x;
      currentPositionY += directionToVector.y;

      const nextWall = levelMap[currentPositionY][currentPositionX];

      if (nextWall.type === "laser") {
        onDamage();
      }
    }

    setPlayerMoving(false);
    setNextDirection(null);
  };

  const onChangeDay = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;

    if (Number(value)) {
      setDay(Number(value));
    }
  };

  const onChangeLevel = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;

    if (Number(value)) {
      setLevel(Number(value));
    }
  };

  const onChangeStyle = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;

    if (Number(value)) {
      setStyle(Number(value));
    }
  };

  const onLoadLevel = (): void => {
    onGetLevelMap();
    utils.clearTextureCache();
    onParseSprites();
  };

  const onParseSprites = async (): Promise<void> => {
    const spritesData = getSpritesData(style);

    const sheet = new Spritesheet(
      Texture.from(spritesData.meta.image as string),
      spritesData
    );

    await sheet.parse();

    setSprites(sheet);
  };

  const onGetLevelMap = async (): Promise<void> => {
    setLevelMapLoading(true);

    const levelMapData: TLevelMapItem[][] = []; //require(`@config/levels/day_${day}/Day_${day}_level_${level}.json`);

    setLevelMap(formatLevelMap(levelMapData));
    setPlayerCoordinates(getInitialCoordinates(levelMapData, cellSize));
    setLevelMapLoading(false);
    setPlayerAngle(0);
    setEnemies([]);
    setScore(0);
    setLevelCompleted(false);
    setLives(DEFAULT_LIVES);

    let total = 0;

    for (const row of levelMapData) {
      for (const cell of row) {
        if (cell.type === "coin") {
          total += 1;
        }
      }
    }

    setTotalCoins(total);
  };

  const updateEnemyPosition = (x: number, y: number, name: string): void => {
    if (isLevelCompleted) {
      return;
    }
    setEnemies((prev) => {
      const findEnemy = prev.find((enemy) => enemy.name === name);
      if (findEnemy) {
        return prev.map((enemy) => {
          if (enemy.name === name) {
            return {
              x,
              y,
              name,
            };
          }
          return enemy;
        });
      }
      return [...prev, { x, y, name }];
    });
  };

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getDirectionDistance = (direction: Direction): number => {
    let distance = 0;

    const directionToVector = DIRECTION_TO_VECTOR[direction];
    const { x, y } = playerCoordinates;

    const d = cellSize / 2;
    let currentPositionY = (y - d) / cellSize + directionToVector.y;
    let currentPositionX = (x - d) / cellSize + directionToVector.x;

    while (checkMove(levelMap[currentPositionY][currentPositionX])) {
      distance += 1;

      currentPositionY += directionToVector.y;
      currentPositionX += directionToVector.x;
    }

    return distance;
  };

  const updateMoveCoordinates = async (
    direction: Direction,
    distance: number
  ): Promise<void> => {
    if (distance < 2 || isPlayerMoving) {
      return;
    }

    const directionToVector = DIRECTION_TO_VECTOR[direction];
    const { x, y } = playerCoordinates;
    const axis = directionToVector.x === 0 ? "y" : "x";

    for (const [index] of Array(distance - 1)
      .fill("i")
      .entries()) {
      const nextMove = {
        x,
        y,
      };

      nextMove[axis] =
        playerCoordinates[axis] +
        (directionToVector[axis] < 0
          ? directionToVector[axis] - index
          : directionToVector[axis] + index) *
          cellSize;

      setMoveCoordinates((prev) => [...prev, nextMove]);

      await sleep(skin.default_speed / 1.5);
    }

    setMoveCoordinates([]);
  };

  const handleDirection = (direction: Direction): void => {
    const distance = getDirectionDistance(direction);

    if (distance) {
      setPlayerAngle(angleByDirection[direction]);
      setNextDirection(direction);
      updateMoveCoordinates(direction, distance);
    }
  };

  useTouch(containerRef, handleDirection);
  useKeyboardControls(handleDirection);

  const onDamage = async (): Promise<void> => {
    if (isLevelCompleted || !lives || isDamaged) {
      return;
    }

    setDamaged(true);

    setScreenAnimation("shake");
    impactOccurred("medium");

    setTimeout(() => {
      setDamaged(false);
    }, 1000);
  };

  return (
    <Wrapper>
      <Styles.Container
        ref={containerRef}
        className={cn({
          shake: screenAnimation,
        })}
      >
        {isLevelMapLoading || !sprites ? (
          <Styles.SpinnerRow>
            <Spinner size={40} />
          </Styles.SpinnerRow>
        ) : (
          <>
            <UserInterface
              title="Tutorial"
              score={score}
              level={level}
              lives={lives}
              isActive={activeModal === "level-completed"}
              totalCoins={totalCoins}
            />
            <PixiProvider>
              <PlayerScreen
                rowPlayerPos={playerCoordinates.y / cellSize}
                colPlayerPos={playerCoordinates.x / cellSize}
                gridWidth={gridWidth}
                gridHeight={gridHeight}
                cellSize={cellSize}
              >
                <LevelMap
                  levelMap={levelMap}
                  cellSize={cellSize}
                  updateEnemyPosition={updateEnemyPosition}
                  sprites={sprites}
                  playerCoordinates={playerCoordinates}
                  onDamage={onDamage}
                />
                <Player
                  width={cellSize}
                  height={cellSize}
                  playerCoordinates={playerCoordinates}
                  playerAngle={playerAngle}
                  moveCoordinates={moveCoordinates}
                  thumbnail="/assets/game/player_00.png"
                />
              </PlayerScreen>
            </PixiProvider>
          </>
        )}
        <Styles.Options>
          <p>Options</p>
          <select onChange={onChangeDay} value={day}>
            <option value={0}>Please choose day</option>
            <option value={1}>Day 1</option>
            <option value={2}>Day 2</option>
            <option value={3}>Day 3</option>
            <option value={4}>Day 4</option>
            <option value={5}>Day 5</option>
            <option value={6}>Day 6</option>
            <option value={7}>Day 7</option>
          </select>
          <select onChange={onChangeLevel} value={level}>
            <option value={0}>Please choose level</option>
            <option value={1}>Level 1</option>
            <option value={2}>Level 2</option>
            <option value={3}>Level 3</option>
            <option value={4}>Level 4</option>
            <option value={5}>Level 5</option>
          </select>
          <select onChange={onChangeStyle} value={style}>
            <option value={0}>Please choose style</option>
            <option value={1}>Style 1</option>
            <option value={2}>Style 2</option>
            <option value={3}>Style 3</option>
            <option value={4}>Style 4</option>
            <option value={5}>Style 5</option>
          </select>
          <button onClick={onLoadLevel}>Load level</button>
        </Styles.Options>
      </Styles.Container>
    </Wrapper>
  );
};

export default TestLevelsPage;
