import * as React from "react";
import {
  useTonConnectUI,
  useTonAddress,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import Countdown, { type CountdownRenderProps } from "react-countdown";
import dayjs from "dayjs";
import { beginCell, Cell } from "@ton/core";

// Components
import Wrapper from "@components/Wrapper";
import BackButton from "@components/BackButton";
import AirdropCard from "@components/AirdropCard";
import Button from "@components/Button";
import Spinner from "@components/Spinner";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { sleep } from "@utils/index";
import {
  getAirdropTasks,
  getAirdropTx,
  checkAirdropTx,
  claimAirdropTask,
  getBalance,
} from "@utils/api";
import type { TAirdropTask, TAirdropTaskName } from "@utils/api/types";

// Styles
import Styles from "./styles";

const TASKS_NAMES: Record<TAirdropTaskName, string> = {
  connect_wallet: "Connect wallet and make transaction",
  more_than_100000: "Play to Earn 100k+ $CP",
};

const AirdropPage: React.FC = () => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [completedTasks, setCompletedTasks] = React.useState<number>(0);
  const [tasks, setTasks] = React.useState<TAirdropTask[]>([]);
  const [isConnectComplete, setConnectComplete] =
    React.useState<boolean>(false);

  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const {
    open: openModal,
    state: { status: modalStatus },
  } = useTonConnectModal();
  const { initData } = useWebApp();

  const dispatch = useAppDispatch();
  const balance = useAppSelector((state) => state.app.balance);
  const user = useAppSelector((state) => state.app.user);
  const formatBalance = Math.min(100000, balance?.balance || 0);
  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    if (modalStatus === "closed" && isLoading && address) {
      onSend();
    } else if (modalStatus === "closed" && isLoading && !address) {
      setLoading(false);
    } else if (modalStatus === "opened") {
      setLoading(true);
    }
  }, [modalStatus, isLoading, address]);

  React.useEffect(() => {
    onGetTasks();

    if (tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
  }, []);

  React.useEffect(() => {
    setCompletedTasks(tasks.filter((task) => task.status === "Done").length);

    const findTask = tasks.find((task) => task.name === "connect_wallet");

    setConnectComplete(findTask?.status === "Done");
  }, [tasks]);

  const onGetTasks = async (): Promise<void> => {
    setTasks(await getAirdropTasks(initData, sessionId));
  };

  const onConnect = (): void => {
    if (!address) {
      openModal();
    } else {
      setLoading(true);

      onSend();
    }
  };

  const onSend = async (): Promise<void> => {
    const tx = await getAirdropTx(initData, sessionId);

    if (!tx || !user) {
      setLoading(false);
      return;
    }

    const {
      valid_until: validUntil,
      messages: [message],
    } = tx;

    const payload = beginCell()
      .storeUint(0, 32)
      .storeStringTail(message.payload)
      .endCell()
      .toBoc()
      .toString("base64");

    tonConnectUI
      .sendTransaction(
        {
          validUntil,
          messages: [
            {
              address: message.address,
              amount: message.amount,
              payload,
            },
          ],
        },
        {
          notifications: [],
        }
      )
      .then(async (response) => {
        const txId = Cell.fromBase64(response.boc).hash().toString("hex");

        await checkTx(txId);
        setTasks(await getAirdropTasks(initData, sessionId));

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const checkTx = async (txId: string): Promise<TAirdropTask> => {
    await sleep(5000);

    const result = await checkAirdropTx(
      initData,
      encodeURIComponent(txId),
      sessionId
    );

    if (result) {
      return result;
    }

    return checkTx(txId);
  };

  const onClickTask =
    (taskName: TAirdropTaskName) => async (): Promise<void> => {
      const findTask = tasks.find((task) => task.name === taskName);

      if (taskName === "connect_wallet" && findTask) {
        if (findTask.status === "None") {
          onConnect();
        } else if (findTask.status === "Validated") {
          await claimAirdropTask(initData, sessionId);

          if (sessionId) {
            dispatch(setBalance(await getBalance(initData, sessionId)));
          }

          onGetTasks();
        }
      }
    };

  return (
    <Wrapper withProfile>
      <BackButton withBottomBack />
      <Styles.Container>
        <Styles.CoverSpace src="/assets/airdrop-space.gif" alt="space" />
        <Styles.Overlay />
        <Styles.Row>
          <Styles.Title>
            Get $CRF <Styles.TitleYellow>Airdrop!</Styles.TitleYellow>
          </Styles.Title>
          <Styles.Description>
            Earn $CP & Claim Your Share of 50B $CRF!
          </Styles.Description>
          <Styles.List>
            <Styles.ListItem>
              <Styles.ListItemIconRow>
                <Styles.ListItemIcon
                  src="/assets/icons/airdrop-check.svg"
                  alt="check"
                />
              </Styles.ListItemIconRow>
              <Styles.ListItemTitle>Game launch</Styles.ListItemTitle>
            </Styles.ListItem>
            <Styles.ListItem>
              <Styles.ListItemIconRow>
                <Styles.ListItemPosition>2</Styles.ListItemPosition>
              </Styles.ListItemIconRow>
              <Styles.ListItemTitle>5 million users</Styles.ListItemTitle>
            </Styles.ListItem>
            <Styles.ListItem>
              <Styles.ListItemIconRow>
                <Styles.ListItemPosition>3</Styles.ListItemPosition>
              </Styles.ListItemIconRow>
              <Styles.ListItemTitle>Airdrop and listing</Styles.ListItemTitle>
            </Styles.ListItem>
          </Styles.List>
          <Styles.FollowSteps>
            To participate in the airdrop, follow three simple steps
          </Styles.FollowSteps>
        </Styles.Row>

        <Styles.Bottom>
          {!tasks.length ? (
            <Styles.SpinnerRow>
              <Spinner size={40} />
            </Styles.SpinnerRow>
          ) : (
            <>
              <Styles.BottomHeading>
                <Styles.BottomTitle>Steps to Participate</Styles.BottomTitle>
                <Styles.BottomHeadingStats>
                  {completedTasks}/2
                </Styles.BottomHeadingStats>
              </Styles.BottomHeading>
              <Styles.BottomList>
                {tasks.map((task) => {
                  const { status, name, amount_reward } = task;
                  const isConnectTask = name === "connect_wallet";

                  return (
                    <AirdropCard
                      key={task.name}
                      title={TASKS_NAMES[name]}
                      value={isConnectTask ? amount_reward : formatBalance}
                      icon={`/assets/icons/${name}-task.png`}
                      isClaimable={status === "Validated"}
                      isCompleted={status === "Done"}
                      isLoading={false}
                      onStart={isConnectTask ? onConnect : undefined}
                      onClickButton={onClickTask(name)}
                      maxValue={!isConnectTask ? 100000 : undefined}
                      percentage={
                        !isConnectTask
                          ? +Number(
                              Number(formatBalance / 100000) * 100
                            ).toFixed(2)
                          : undefined
                      }
                      withLineBreak={!isConnectTask}
                    />
                  );
                })}
              </Styles.BottomList>
              <Styles.BottomValue>
                Token Fund:{"\n"}
                <Styles.BottomValuePurple>
                  50,000,000,000 $CRF
                </Styles.BottomValuePurple>
              </Styles.BottomValue>
              <Button
                title={
                  completedTasks === 2
                    ? "CLAIM Available after season end"
                    : isConnectComplete
                    ? "COMPLETE ALL THE TASKS"
                    : "Connect wallet and make transaction"
                }
                onClick={onConnect}
                isLoading={isLoading}
                disabled={completedTasks === 2 || isConnectComplete}
              />
            </>
          )}
        </Styles.Bottom>
      </Styles.Container>
    </Wrapper>
  );
};

export default AirdropPage;
