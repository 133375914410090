import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #c7c7cc;
  font-size: 20px;
  font-weight: 400;
`;

const Icon = styled.img`
  margin: 15px 0 0 0;
  width: 100%;
  height: 200px;
`;

const Styles = {
  Title,
  Description,
  Icon,
};

export default Styles;
