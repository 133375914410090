import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown, { type CountdownRenderProps } from "react-countdown";
import { useLocalStorage } from "usehooks-ts";
import dayjs from "dayjs";

// Components
import Wrapper from "@components/Wrapper";
import Button from "@components/Button";
import GameCharacter from "@components/GameCharacter";
import GameMap from "@components/GameMap";
import Mining from "@components/Mining";

// Sheets
import DayStreakSheet from "@sheets/DayStreak";
import NewsSheet from "@sheets/News";
import DontEnoughLivesSheet from "@sheets/DontEnoughLives";
import NoLivesSheet from "@sheets/NoLives";
import LivesRestoredSheet from "@sheets/LivesRestored";
import MaxLevelsCompletedSheet from "@sheets/MaxLevelsCompleted";
import MiningSheet from "@sheets/Mining";
import SpecialOfferSheet from "@sheets/SpecialOffer";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setMining, setBalance, setLives } from "@store/reducers/app";
import { setCharacterIndex, setLevelMap } from "@store/reducers/game";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { getMiningInfo, getBalance, getLives, getMaps } from "@utils/api";

// Config
import { DEFAULT_LIVES } from "@config/consts";

// Styles
import Styles from "./styles";

type TActiveSheet =
  | "day-streak"
  | "news"
  | "dont-enough-lives"
  | "no-lives"
  | "lives-restored"
  | "max-levels-completed"
  | "mining"
  | "special-offer";

const GamePage: React.FC = () => {
  const [activeSheet, setActiveSheet] = React.useState<TActiveSheet | null>(
    null
  );
  const [isDayComplete, setDayComplete] = React.useState<boolean>(false);

  const [isTutorialPlayed, setTutorialPlayed] = useLocalStorage<boolean>(
    "tutorial-played",
    false
  );
  const [specialOfferDate, setSpecialOfferDate] = useLocalStorage<
    string | null
  >("special-offer", null);

  const navigate = useNavigate();
  const { initData } = useWebApp();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const dayStreak = useAppSelector((state) => state.app.dayStreak);
  const characters = useAppSelector((state) => state.app.characters);
  const levelMap = useAppSelector((state) => state.game.levelMap);
  const lives = useAppSelector((state) => state.app.lives);
  const user = useAppSelector((state) => state.app.user);
  const sessionId = useAppSelector((state) => state.app.sessionId);
  const characterIndex = useAppSelector((state) => state.game.characterIndex);
  const mining = useAppSelector((state) => state.app.mining);
  const isFirstVisit = useAppSelector((state) => state.app.isFirstVisit);

  React.useEffect(() => {
    setDayComplete(
      levelMap.map((i) => i.is_completed).filter((i) => i).length === 5
    );
  }, [levelMap.length]);

  React.useEffect(() => {
    if (state?.activeSheet) {
      setActiveSheet(state.activeSheet);
    }
  }, [state]);

  React.useEffect(() => {
    onCheckSpecialOffer();
  }, []);

  const onCheckSpecialOffer = (): void => {
    if (user?.is_special_offer || isFirstVisit) {
      return;
    }

    if (specialOfferDate === null) {
      setSpecialOfferDate(dayjs().utc().toISOString());
      setActiveSheet("special-offer");

      return;
    } else {
      if (
        dayjs()
          .utc()
          .endOf("day")
          .diff(dayjs(specialOfferDate).utc().endOf("day"), "days") <= 0
      ) {
      } else {
        setActiveSheet("special-offer");
        setSpecialOfferDate(dayjs().utc().toISOString());
      }
    }
  };

  const onPlay = (): void => {
    if (isDayComplete) {
      setActiveSheet("max-levels-completed");
    } else if (!lives) {
      setActiveSheet("no-lives");
    } else {
      onPlayAfterRestored();
    }
  };

  const onCloseSheet = (): void => {
    setActiveSheet(null);
  };

  const onOpenDayStreakDrawer = (): void => {
    setActiveSheet("day-streak");
  };

  const onSuccessBuyLives = (): void => {
    dispatch(setLives(DEFAULT_LIVES));
    setActiveSheet("lives-restored");
  };

  const onPlayAfterRestored = (): void => {
    if (user && !user.is_onboarding_complete && !isTutorialPlayed) {
      setTutorialPlayed(true);

      return navigate("/tutorial");
    }

    const mapDay = levelMap.filter((level) => !level.is_completed)[0];

    navigate("/gameplay", {
      state: {
        character: characters[characterIndex],
        mapDay,
      },
    });
  };

  const onRefetchData = async (): Promise<void> => {
    if (!sessionId) {
      return;
    }

    dispatch(setLevelMap(await getMaps(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
    dispatch(setLives(await getLives(initData, sessionId)));
  };

  const setSlideIndex = (index: number): void => {
    dispatch(setCharacterIndex(index));
  };

  const onShowMining = (): void => {
    setActiveSheet("mining");
  };

  const onCompleteMining = async (): Promise<void> => {
    dispatch(setMining(await getMiningInfo(initData, sessionId)));
  };

  const onClickOffer = (): void => {
    setActiveSheet("special-offer");
  };

  return (
    <Wrapper withProfile withNavigation>
      <Styles.Container>
        {user ? (
          <GameCharacter
            characters={characters}
            onOpenDayStreakDrawer={onOpenDayStreakDrawer}
            slideIndex={characterIndex}
            setSlideIndex={setSlideIndex}
            user={user}
            onClickOffer={onClickOffer}
            onPlay={onPlay}
            sessionId={sessionId}
          />
        ) : null}
        <Mining
          levelMap={levelMap}
          isDayComplete={isDayComplete}
          onShowMining={onShowMining}
          onPlay={onPlay}
          onComplete={onRefetchData}
          mining={mining}
          onCompleteMining={onCompleteMining}
        />
      </Styles.Container>
      <DayStreakSheet
        isOpen={activeSheet === "day-streak"}
        onClose={onCloseSheet}
        dayStreak={dayStreak}
      />
      <NewsSheet isOpen={activeSheet === "news"} onClose={onCloseSheet} />
      <DontEnoughLivesSheet
        isOpen={activeSheet === "dont-enough-lives"}
        onClose={onCloseSheet}
      />
      {sessionId ? (
        <NoLivesSheet
          isOpen={activeSheet === "no-lives"}
          onClose={onCloseSheet}
          onSuccessBuyLives={onSuccessBuyLives}
          sessionId={sessionId}
        />
      ) : null}
      <LivesRestoredSheet
        isOpen={activeSheet === "lives-restored"}
        onClose={onCloseSheet}
        onPlay={onPlayAfterRestored}
      />
      <MaxLevelsCompletedSheet
        isOpen={activeSheet === "max-levels-completed"}
        onClose={onCloseSheet}
      />
      <MiningSheet isOpen={activeSheet === "mining"} onClose={onCloseSheet} />
      <SpecialOfferSheet
        isOpen={activeSheet === "special-offer"}
        onClose={onCloseSheet}
      />
    </Wrapper>
  );
};

export default GamePage;
