import * as React from "react";
import { useNavigate } from "react-router-dom";
import WebApp from "@twa-dev/sdk";
import CountUp from "react-countup";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";
import Spinner from "@components/Spinner";

// Store
import { setLevelMap } from "@store/reducers/game";
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import {
  completeLevel,
  completeTutorial,
  getBalance,
  getMaps,
  getNextLevel,
} from "@utils/api";
import type { TCompleteLevelRewards, TMapDay } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  score: number;
  level: number;
  levelId?: number;
  setNextLevel?: (nextLevel: TMapDay) => void;
  totalCoins: number;
}

const LevelCompletedSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, score, level, levelId, setNextLevel, totalCoins } =
    props;

  const [rewards, setRewards] = React.useState<TCompleteLevelRewards | null>(
    null
  );
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { initData } = useWebApp();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    if (isOpen) {
      onCompleteLevel();
    }
  }, [isOpen]);

  const onCompleteLevel = async (): Promise<void> => {
    if (!initData || !levelId || !sessionId) {
      return;
    }

    const amount = +Number(score / totalCoins).toFixed(2);
    const getRewards = await completeLevel(
      initData,
      levelId,
      amount,
      sessionId
    );

    if (getRewards === null) {
      onClose();
      return navigate("/game", {
        replace: true,
        state: null,
      });
    } else {
      setRewards(getRewards);
    }

    dispatch(setLevelMap(await getMaps(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
  };

  const onGoToMain = (): void => {
    navigate("/game", {
      replace: true,
      state: {
        activeSheet: level === 5 ? "max-levels-completed" : null,
      },
    });
    WebApp.BackButton.hide();
  };

  const onLoadNextLevel = async (): Promise<void> => {
    if (!sessionId) {
      return;
    }

    setLoading(true);

    const nextLevel = await getNextLevel(initData, sessionId);

    if (nextLevel && setNextLevel) {
      setNextLevel(nextLevel);
    }

    setLoading(false);
    onClose();
  };

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose} disableDrag isBlack>
      <Styles.Container>
        <Styles.CoverSpace src="/assets/airdrop-space.gif" alt="space" />
        <Styles.Title>Level {level}</Styles.Title>
        <Styles.SubTitle>Completed</Styles.SubTitle>
        <Styles.Rewards>
          {rewards ? (
            <>
              <Styles.RewardRow>
                <Styles.RewardIcon src="/assets/cr-coin.png" alt="icon" />
                <CountUp
                  start={0}
                  end={rewards.points}
                  duration={2}
                  className="reward"
                />
                <Styles.Symbol>$CP</Styles.Symbol>
              </Styles.RewardRow>
              <Styles.RewardRow>
                <Styles.RewardIcon
                  src="/assets/level-complete-ticket.png"
                  alt="icon"
                />
                <Styles.RewardValue>{rewards.tickets}</Styles.RewardValue>
                <Styles.Symbol>tickets</Styles.Symbol>
              </Styles.RewardRow>
            </>
          ) : (
            <Spinner size={40} />
          )}
        </Styles.Rewards>
        <Styles.Actions>
          {level === 5 ? (
            <Button
              title="Go to main"
              onClick={onGoToMain}
              disabled={isLoading}
            />
          ) : (
            <>
              <Button
                title="Next level"
                onClick={onLoadNextLevel}
                isLoading={isLoading}
              />
              <Button
                title="Go to main"
                variant="notfilled"
                onClick={onGoToMain}
                disabled={isLoading}
              />
            </>
          )}
        </Styles.Actions>
        <Styles.Shadow />
      </Styles.Container>
    </SheetWrapper>
  );
};

export default LevelCompletedSheet;
