const { NODE_ENV, REACT_APP_BACKEND_DEV_URL, REACT_APP_BACKEND_URL } =
  process.env;

export const IS_DEV = NODE_ENV === "development";

export const API_URL = IS_DEV
  ? REACT_APP_BACKEND_DEV_URL
  : REACT_APP_BACKEND_URL;

export const DEFAULT_LIVES = 5;
