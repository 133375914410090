import * as React from "react";
import cn from "classnames";

// Utils
import type { TMapDay } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  levelMap: TMapDay[];
  isDayComplete: boolean;
}

const MiningProgress: React.FC<Props> = (props) => {
  const { levelMap, isDayComplete } = props;

  const [percentValue, setPercentValue] = React.useState<number>(0);

  React.useEffect(() => {
    onGetPercent();
  }, [levelMap]);

  const onGetPercent = (): void => {
    const checkIsAllComplete =
      levelMap.filter((i) => i.is_completed).length === 5;

    if (checkIsAllComplete) {
      return setPercentValue(100);
    }

    const findMap = levelMap.find((i) => i.is_active && !i.is_completed);

    if (findMap) {
      setPercentValue(findMap.percent_complete - 20);
    }
  };

  return (
    <>
      <Styles.PercentProgress>{percentValue}%</Styles.PercentProgress>
      <Styles.ProgressLineRow>
        {Array(6)
          .fill("progress")
          .map((i, index) => {
            const isFinished = levelMap?.[index]?.is_completed;
            const isFirstNotFinised =
              levelMap.findIndex((i) => !i.is_completed) === index;
            const isActive = !isFinished && isFirstNotFinised;

            return (
              <React.Fragment key={`${i}/${index}`}>
                <Styles.ProgressLinePattern
                  className={cn({
                    side: index === 0 || index === 5,
                    active:
                      isFinished || isActive || (index === 5 && isDayComplete),
                  })}
                />
                {index !== 5 ? (
                  <Styles.ProgressLineBlockRow>
                    <Styles.ProgressLineBlock>
                      {isFinished ? <Styles.ProgressLineBlockDot /> : null}
                    </Styles.ProgressLineBlock>
                  </Styles.ProgressLineBlockRow>
                ) : null}
              </React.Fragment>
            );
          })}
      </Styles.ProgressLineRow>
    </>
  );
};

export default MiningProgress;
