import * as React from "react";

const cellSize = 36;

const useMapSize = () => {
  const [gridWidth, setGridWidth] = React.useState<number>(window.innerWidth);
  const [gridHeight, setGridHeight] = React.useState<number>(
    window.innerHeight
  );

  React.useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const calculatedWidth = Math.floor(screenWidth / cellSize) * cellSize;
    const calculatedHeight = Math.floor(screenHeight / cellSize) * cellSize;

    setGridWidth(calculatedWidth);
    setGridHeight(calculatedHeight);
  }, []);

  return { gridWidth, gridHeight, cellSize };
};

export default useMapSize;
