import * as React from "react";
import WebApp from "@twa-dev/sdk";
import { useNavigate } from "react-router-dom";

// Config
import { IS_DEV } from "@config/consts";

// Styles
import Styles from "./styles";

interface Props {
  withBottomBack?: boolean;
}

const BackButton: React.FC<Props> = (props) => {
  const { withBottomBack } = props;

  const navigate = useNavigate();

  React.useEffect(() => {
    if (IS_DEV) {
      return;
    }

    WebApp.BackButton.show();
  }, []);

  React.useEffect(() => {
    if (IS_DEV) {
      return;
    }

    WebApp.BackButton.onClick(onBack);

    return () => {
      WebApp.BackButton.offClick(onBack);
    };
  }, [BackButton]);

  const onBack = (): void => {
    navigate(-1);
    WebApp.BackButton.hide();
  };

  if (withBottomBack) {
    return (
      <Styles.Button onClick={onBack}>
        <Styles.ButtonIcon src="/assets/icons/arrow-left.svg" alt="arrow" />
      </Styles.Button>
    );
  }

  return null;
};

export default BackButton;
