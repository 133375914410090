import * as React from "react";
import { useNavigate } from "react-router-dom";
import WebApp from "@twa-dev/sdk";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setLives } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { buyItem, getFreeLives, getLivesShopId } from "@utils/api";

// Config
import { DEFAULT_LIVES } from "@config/consts";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  lives: number;
  onResetLevel: () => void;
  isTutorial?: boolean;
}

const GameOverSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, lives, onResetLevel, isTutorial } = props;

  const [isLoading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const { initData, openInvoice } = useWebApp();
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.app.sessionId);

  const onGoToMain = (): void => {
    navigate("/game", { replace: true, state: null });
    WebApp.BackButton.hide();
  };

  const onBuyRelive = async (): Promise<void> => {
    if (!sessionId) {
      return;
    }

    setLoading(true);

    const livesShopId = await getLivesShopId(initData, sessionId);

    if (livesShopId) {
      const invoiceUrl = await buyItem(initData, livesShopId, sessionId);

      if (invoiceUrl) {
        openInvoice(invoiceUrl, (status) => {
          if (status === "paid") {
            onResetLevel();
            onClose();
            dispatch(setLives(DEFAULT_LIVES));
            setLoading(false);
          } else if (status === "failed" || status === "cancelled") {
            setLoading(false);
          }
        });
      }
    } else {
      setLoading(false);
    }
  };

  const onGetFreeLives = async (): Promise<void> => {
    setLoading(true);

    const request = await getFreeLives(initData, sessionId);

    if (request) {
      onResetLevel();
      onClose();
      dispatch(setLives(DEFAULT_LIVES));
    }

    setLoading(false);
  };

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose} disableDrag isBlack>
      <Styles.Container>
        <Styles.Title>Game Over</Styles.Title>
        <Styles.Description>
          You can always continue the game with the resurrection booster.
        </Styles.Description>
        <Styles.LivesRow>
          <Styles.LivesIcons>
            {Array(DEFAULT_LIVES)
              .fill("live")
              .map((i, index) => lives > index)
              .sort((a, b) => Number(a) - Number(b))
              .map((i, index) => (
                <Styles.LivesIcon
                  key={`${i}/${index}`}
                  src={
                    i
                      ? "/assets/icons/lives-active.svg"
                      : "/assets/icons/lives-disabled.svg"
                  }
                  alt="live"
                />
              ))}
          </Styles.LivesIcons>
          <Styles.Lives>X{lives}</Styles.Lives>
        </Styles.LivesRow>
        <Styles.Actions>
          {isTutorial ? (
            <Button title="Start over" onClick={onResetLevel} />
          ) : (
            <>
              {lives ? (
                <Button title="Replay level" onClick={onResetLevel} />
              ) : (
                <Button
                  title="Get free"
                  isLoading={isLoading}
                  onClick={onGetFreeLives}
                  yellowTitle="lives"
                />
              )}
              <Button
                title="Go to main"
                onClick={onGoToMain}
                variant="notfilled"
              />
            </>
          )}
        </Styles.Actions>
      </Styles.Container>
    </SheetWrapper>
  );
};

export default GameOverSheet;
