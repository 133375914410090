import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Utils
import type { TTask } from "@utils/api/types";

// Config
import { API_URL } from "@config/consts";

type TTasksParams = {
  initData: string;
  sessionId: string;
  section?: string;
};

export const tasksApi = createApi({
  reducerPath: "tasksApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getTasks: builder.query<TTask[], TTasksParams>({
      query(params) {
        return {
          url: "tasks",
          headers: {
            "x-auth": params.initData,
            "x-session-id": params.sessionId,
          },
          params: {
            section: params.section,
          },
        };
      },
    }),
  }),
});

export const { useGetTasksQuery } = tasksApi;
