import * as React from "react";
import cn from "classnames";

// Components
import Profile from "@components/Profile";

// Styles
import Styles from "./styles";

interface Props {
  children: React.ReactNode;
  withProfile?: boolean;
  withNavigation?: boolean;
  backgroundColor?: string;
}

const Wrapper: React.FC<Props> = (props) => {
  const { children, withProfile, withNavigation, backgroundColor } = props;

  return (
    <Styles.Container style={{ backgroundColor }}>
      {withProfile ? <Profile /> : null}
      <Styles.Row
        className={cn({
          "with-profile": withProfile,
          "with-navigation": withNavigation,
        })}
      >
        {children}
      </Styles.Row>
    </Styles.Container>
  );
};

export default Wrapper;
