import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DontEnoughLivesSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>You don't have enough lives</Styles.Title>
      <Styles.Description>
        New lives will be available through:{" "}
        <Styles.DescriptionBold>24h</Styles.DescriptionBold>
      </Styles.Description>
    </SheetWrapper>
  );
};

export default DontEnoughLivesSheet;
