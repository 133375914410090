import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const Cover = styled.div`
  background: url("/assets/referal-background.png") no-repeat;
  height: 220px;
  background-size: cover;
  background-position: center;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const CoinsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const CoinsIcon = styled.img`
  width: 49px;
  height: 49px;
`;

const CoinsValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
`;

const CoinsSymbol = styled.p`
  color: #c7c7cc;
  font-size: 28px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const Description = styled.p`
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`;

const DescriptionExpandedBold = styled.span`
  font-family: "Stapel_Expanded_Bold";
  text-transform: uppercase;
`;

const DescriptionBold = styled.span`
  font-family: "Stapel_Bold";
`;

const Bottom = styled.div`
  border-radius: 25px 25px 0px 0px;
  background-color: #1c1d1f;
  position: fixed;
  bottom: 0;
  height: calc(100vh - 248px);
  width: 100%;
  padding: 30px 20px;
`;

const BottomHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BottomHeadingTitle = styled.p`
  color: #c7c7cc;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
`;

const List = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  height: calc(var(--tg-viewport-height, 100vh) - 462px);
  padding-bottom: 10px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 20px;
  background-color: rgba(39, 39, 39, 0.75);
  background-blend-mode: luminosity;
  backdrop-filter: blur(12.5px);
  position: fixed;
  bottom: calc(66px + calc(100vh - var(--tg-viewport-height, 100vh)));
  width: 100%;
  z-index: 2;

  button:first-child {
    flex: 1;
  }
`;

const Button = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #0992fd;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.copied {
    background: linear-gradient(90deg, #0992fd 0%, #fc35de 92.53%), #fff;
  }
`;

const ButtonIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const NotFoundText = styled.p`
  margin: 20px 0 0 0;
  color: #c7c7cc;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
`;

const SpinnerRow = styled.div`
  display: flex;
  justify-content: center;
`;

const Styles = {
  Container,
  Cover,
  CoinsRow,
  CoinsIcon,
  CoinsValue,
  CoinsSymbol,
  Description,
  DescriptionExpandedBold,
  DescriptionBold,
  Bottom,
  BottomHeading,
  BottomHeadingTitle,
  List,
  Actions,
  Button,
  ButtonIcon,
  NotFoundText,
  SpinnerRow,
};

export default Styles;
