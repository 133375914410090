import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const PaymentApprovedSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Container>
        <Styles.Title>Payment{"\n"}approved</Styles.Title>
        <Styles.Icon src="/assets/loving.png" alt="loving" />
        <Styles.ButtonRow>
          <Button title="Close" variant="outline" onClick={onClose} />
        </Styles.ButtonRow>
      </Styles.Container>
    </SheetWrapper>
  );
};

export default PaymentApprovedSheet;
