import * as React from "react";
import cn from "classnames";

// Utils
import { abbreviateNumber, formatPoints } from "@utils/format";

// Styles
import Styles from "./styles";

interface Props {
  title: string;
  salePercent?: number;
  badge?: string;
  prices: {
    stars: number;
    ton: number;
  };
  onBuy: () => void;
  isActive: boolean;
  max_claim: number;
  is_used: boolean;
}

const MiningCard: React.FC<Props> = (props) => {
  const {
    title,
    salePercent,
    badge,
    prices,
    onBuy,
    isActive,
    max_claim,
    is_used,
  } = props;

  const onClick = (): void => {
    if (!is_used) {
      onBuy();
    }
  };

  return (
    <Styles.Card
      className={cn({
        active: isActive,
        disabled: is_used,
      })}
      onClick={onClick}
    >
      <Styles.CardHeading>
        <Styles.CardCircle
          className={cn({
            active: isActive,
          })}
        >
          {isActive ? (
            <Styles.CardCircleIcon
              src="/assets/icons/radio-check.svg"
              alt="icon"
            />
          ) : null}
        </Styles.CardCircle>
        <Styles.CardTitle>{title}</Styles.CardTitle>
      </Styles.CardHeading>
      <Styles.CardPrices>
        {prices.stars === 0 && prices.ton === 0 ? (
          <Styles.FreePrice>{is_used ? "Used" : "Free"}</Styles.FreePrice>
        ) : (
          <>
            <Styles.CardPriceRow>
              <Styles.CardPrice>{formatPoints(prices.stars)}</Styles.CardPrice>
              <Styles.CardPriceIcon src="/assets/icons/stars.svg" alt="stars" />
            </Styles.CardPriceRow>
            <Styles.CardPriceRow>
              <Styles.CardPrice>{prices.ton}</Styles.CardPrice>
              <Styles.CardPriceIcon src="/assets/icons/ton.svg" alt="ton" />
            </Styles.CardPriceRow>
          </>
        )}
      </Styles.CardPrices>
      {salePercent ? (
        <Styles.CardSaleRow>
          <Styles.CardSale>-{salePercent}%</Styles.CardSale>
        </Styles.CardSaleRow>
      ) : null}
      <Styles.CardBadgeRow>
        <Styles.CardBadge>{abbreviateNumber(max_claim)} $CP</Styles.CardBadge>
      </Styles.CardBadgeRow>
    </Styles.Card>
  );
};

export default MiningCard;
