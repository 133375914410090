import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Avatar from "@components/Avatar";
import Package from "./BoostRatingPackage";
import Spinner from "@components/Spinner";

// Store
import { useAppSelector } from "@store/index";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { formatPoints } from "@utils/format";
import { getBoostPackages } from "@utils/api";
import type { TBoostPackage, TLeaderboardUser } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user: TLeaderboardUser;
  onSuccessPaid: () => void;
  onFailedPaid: () => void;
}

const BoostRatingSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, user, onSuccessPaid, onFailedPaid } = props;

  const [packages, setPackages] = React.useState<TBoostPackage[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { initData, openInvoice } = useWebApp();

  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    onGetPackages();
  }, []);

  const onGetPackages = async (): Promise<void> => {
    setLoading(true);
    setPackages(await getBoostPackages(initData, sessionId));
    setLoading(false);
  };

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose} snapPoints={[-50]}>
      <Styles.Title>
        Boost Your <Styles.TitleYellow>$RATING</Styles.TitleYellow>
      </Styles.Title>
      <Styles.Description>
        Come back tomorrow to keep your streak going. Tip: Skipping a day resets
        your streak.
      </Styles.Description>
      <Styles.Card>
        <Avatar size={50} avatar={user.avatar_url} />
        <Styles.CardRow>
          <Styles.CardTitle>
            {user.user_name || `user${user.chat_id}`}
          </Styles.CardTitle>
          <Styles.CardValueRow>
            <Styles.CardValueIcon src="/assets/cr-coin.png" alt="coin" />
            <Styles.CardValue>
              {formatPoints(user.user_balance)}
            </Styles.CardValue>
            <Styles.CardValueSymbol>$CP</Styles.CardValueSymbol>
          </Styles.CardValueRow>
        </Styles.CardRow>
        <Styles.CardRank>#{user.user_rank}</Styles.CardRank>
      </Styles.Card>

      <Styles.PackagesTitle>Packages</Styles.PackagesTitle>
      {isLoading ? (
        <Styles.SpinnerRow>
          <Spinner size={40} />
        </Styles.SpinnerRow>
      ) : null}

      {!isLoading && packages.length && sessionId ? (
        <Styles.PackagesList>
          {packages.map((pack) => (
            <Package
              key={`${pack.id}/${pack.title}`}
              data={pack}
              initData={initData}
              sessionId={sessionId}
              openInvoice={openInvoice}
              onSuccessPaid={onSuccessPaid}
              onFailedPaid={onFailedPaid}
            />
          ))}
        </Styles.PackagesList>
      ) : null}
    </SheetWrapper>
  );
};

export default BoostRatingSheet;
