import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide, type SwiperClass } from "swiper/react";
import "swiper/css";

// Components
import Wrapper from "@components/Wrapper";
import Button from "@components/Button";
import Pagination from "@components/Pagination";
import Character from "@components/Character";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setCharacters } from "@store/reducers/app";
import { setLevelMap } from "@store/reducers/game";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { getMaps, getSkins, setCharacter } from "@utils/api";
import type { TSkin } from "@utils/api/types";

// Styles
import Styles from "./styles";

const SelectCharacterPage: React.FC = () => {
  const [slideIndex, setSlideIndex] = React.useState<number>(-1);
  const [swiper, setSwiper] = React.useState<SwiperClass | null>(null);
  const [skins, setSkins] = React.useState<TSkin[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const { impactOccurred, initData } = useWebApp();

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    onGetSkins();
  }, []);

  const onGetSkins = async (): Promise<void> => {
    if (!initData || !sessionId) {
      return;
    }

    setLoading(true);

    const skinsData = await getSkins(initData, sessionId);

    setSkins(skinsData);

    if (skinsData.length) {
      setSlideIndex(0);
    }

    setLoading(false);
  };

  const onChoose = async (): Promise<void> => {
    if (!initData || !skins?.[slideIndex]?.id || !sessionId) {
      return;
    }

    setLoading(true);

    const newCharacter = await setCharacter(
      initData,
      skins[slideIndex].id,
      sessionId
    );

    if (newCharacter) {
      dispatch(setCharacters([newCharacter]));
    }

    dispatch(setLevelMap(await getMaps(initData, sessionId)));

    navigate("/day-streak");
    setLoading(false);
  };

  const onPrev = (): void => {
    swiper?.slidePrev();
  };

  const onNext = (): void => {
    swiper?.slideNext();
  };

  const onActiveIndexChange = (swiper: SwiperClass): void => {
    setSlideIndex(swiper.activeIndex);

    impactOccurred("heavy");
  };

  return (
    <Wrapper>
      <Styles.Container>
        <Styles.Row>
          <Styles.Title>Character selection</Styles.Title>
          <Styles.Description>
            Choose one character from the list
          </Styles.Description>
          <Styles.Slider>
            <Swiper
              slidesPerView="auto"
              centeredSlides
              spaceBetween={12}
              onSwiper={setSwiper}
              onActiveIndexChange={onActiveIndexChange}
            >
              {skins.map((skin) => {
                const { id, name, image_url } = skin;

                return (
                  <SwiperSlide key={`${id}/${name}`}>
                    <Character name={name} image={image_url} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Styles.Slider>
          {skins.length ? (
            <Pagination
              count={skins.length}
              currentIndex={slideIndex}
              onPrev={onPrev}
              onNext={onNext}
            />
          ) : null}
        </Styles.Row>
        <Button
          title="Choose"
          onClick={onChoose}
          isLoading={isLoading}
          disabled={slideIndex === -1}
        />
      </Styles.Container>
    </Wrapper>
  );
};

export default SelectCharacterPage;
