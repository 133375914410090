import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #c7c7cc;
  font-size: 16px;
  font-weight: 400;
`;

const Weeks = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Week = styled.button`
  flex: 1;
  height: 40px;
  border-radius: 6px;
  border: none;
  background: linear-gradient(90deg, #0992fd -39.22%, #fc35de 77.81%);
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;

  &:disabled {
    border: 1px solid #ffffff;
    background: transparent;
    opacity: 0.5;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -5px -5px 10px -5px;
`;

const ListItem = styled.div`
  width: 33.33%;
  padding: 5px;

  &:last-child {
    width: 100%;
  }
`;

const Card = styled.div`
  height: 123px;
  border-radius: 10px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.active {
    border: 1px solid #fc0;
  }
`;

const CardValueSymbol = styled.span`
  color: #c7c7cc;
  font-size: 12px;
  font-family: "Stapel-Light";
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const CardGlow = styled.div`
  width: 102px;
  height: 101px;
  position: absolute;
  background: url("/assets/glow.png") no-repeat;
  top: 14px;
  background-size: cover;

  &.big {
    background: url("/assets/big-glow.png") no-repeat;
    width: 100%;
    height: 163px;
    background-size: contain;
    top: 0;
    background-position: center -16px;
  }
`;

const CardOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 70%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckIcon = styled.img`
  width: 45px;
  height: 45px;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardDayRow = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 16px;
  font-weight: 500;
`;

const CardBoostRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const CardBoostIcon = styled.img`
  width: 10px;
  height: 12px;
`;

const CardBoostValue = styled.p`
  color: #bd52fd;
  font-family: "DS-Digital", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
`;

const CardValues = styled.div`
  position: absolute;
  bottom: 2px;
`;

const CardValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin: -5px 0 0;
  }
`;

const CardValuePlus = styled.p`
  color: #fc0;
  font-family: "Stapel_Bold";
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;

  &.blue {
    color: #04bbf9;
  }
`;

const CardValueIcon = styled.img`
  height: 11px;

  &.ticket {
    margin: 0 3px;
  }

  &.coin {
    margin: 0 1px;
  }
`;

const CardValue = styled.p`
  color: #fc0;
  font-family: "DS-Digital", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;

  &.blue {
    color: #04bbf9;
  }
`;

const CardValueRowSymbol = styled.p`
  margin: 0 0 0 2.5px;
  font-family: "DS-Digital", sans-serif;
  color: #c7c7cc;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const CardIcon = styled.img`
  margin: 10px 0 0 0;
  height: 50px;
`;

const ButtonRow = styled.div`
  padding: 28px 0 20px 0;
`;

const Styles = {
  Title,
  Description,
  Weeks,
  Week,
  List,
  ListItem,
  Card,
  CardGlow,
  CardOverlay,
  CheckIcon,
  CardRow,
  CardDayRow,
  CardTitle,
  CardBoostRow,
  CardBoostIcon,
  CardBoostValue,
  CardValues,
  CardValueRow,
  CardValuePlus,
  CardValueIcon,
  CardValue,
  CardValueRowSymbol,
  CardIcon,
  ButtonRow,
};

export default Styles;
