import axios, { AxiosResponse } from "axios";

// Config
import { API_URL } from "@config/consts";

// Types
import type {
  TRequestParams,
  TUser,
  TMapDay,
  TCharacter,
  TSkin,
  TReferralsResponse,
  TInvoiceUrl,
  TBalance,
  TLivesResponse,
  TShopItem,
  TLeaderboardStats,
  TTask,
  TLevelMapItem,
  TTonTx,
  TAirdropTask,
  THillKing,
  TBoostPackage,
  TRouletteGift,
  TRouletteTicket,
  TDailyReward,
  TCompleteLevelRewards,
  TMiningProduct,
  TMiningInfo,
  TSpecialOffer,
} from "./types";

export const sendRequest = async <T, D = {}>({
  url,
  method = "GET",
  data,
  params,
  headers,
  isExternalUrl,
}: TRequestParams<D>): Promise<T | null> => {
  try {
    const { data: responseData }: AxiosResponse<T> = await axios({
      method,
      url: isExternalUrl ? url : `${API_URL}/${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      data,
      params,
    });

    return responseData;
  } catch {
    return null;
  }
};

export const getUserInfo = async (initData: string): Promise<TUser | null> => {
  return sendRequest<TUser>({
    url: "users/info",
    headers: {
      "x-auth": initData,
    },
  });
};

export const buyItem = async (
  initData: string,
  product_id: number,
  sessionId: string
): Promise<string | null> => {
  const request = await sendRequest<TInvoiceUrl>({
    url: "shops/buy",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      product_id,
    },
  });

  return request?.url || null;
};

export const getMaps = async (
  initData: string,
  sessionId: string
): Promise<TMapDay[]> => {
  const request = await sendRequest<TMapDay[]>({
    url: "maps",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const createUser = async (initData: string): Promise<TUser | null> => {
  return sendRequest<TUser>({
    url: "users",
    method: "POST",
    headers: {
      "x-auth": initData,
    },
  });
};

export const getCharacters = async (
  initData: string,
  sessionId: string
): Promise<TCharacter[]> => {
  const request = await sendRequest<TCharacter[]>({
    url: "characters",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const getDayStreak = async (
  initData: string,
  sessionId: string
): Promise<number> => {
  const request = await sendRequest<number>({
    url: "users/day_streak",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || 1;
};

export const getSkins = async (
  initData: string,
  sessionId: string
): Promise<TSkin[]> => {
  const request = await sendRequest<TSkin[]>({
    url: "skins",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const setCharacter = async (
  initData: string,
  skin_id: number,
  sessionId: string
): Promise<TCharacter | null> => {
  return sendRequest<TCharacter>({
    url: "characters/skins",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      skin_id,
    },
  });
};

export const completeLevel = async (
  initData: string,
  level_id: number,
  amount: number,
  sessionId: string
): Promise<TCompleteLevelRewards | null> => {
  return sendRequest<TCompleteLevelRewards>({
    url: "maps/complete",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      amount,
      level_id,
    },
  });
};

export const getReferrals = async (
  initData: string,
  offset: number,
  sessionId: string
): Promise<TReferralsResponse | null> => {
  return sendRequest<TReferralsResponse>({
    url: "users/referrals",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    params: {
      limit: 100,
      offset,
    },
  });
};

export const getBalance = async (
  initData: string,
  sessionId: string
): Promise<TBalance | null> => {
  return sendRequest<TBalance>({
    url: "users/balance",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getLives = async (
  initData: string,
  sessionId: string
): Promise<number> => {
  const request = await sendRequest<TLivesResponse>({
    url: "users/lives",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request?.amount || 0;
};

export const subtractLive = async (
  initData: string,
  sessionId: string
): Promise<void> => {
  await sendRequest({
    url: "users/lives/subtraction",
    method: "PATCH",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      amount: 1,
    },
  });
};

export const getNextLevel = async (
  initData: string,
  sessionId: string
): Promise<TMapDay | null> => {
  return sendRequest<TMapDay>({
    url: "maps/next",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getLivesShopId = async (
  initData: string,
  sessionId: string
): Promise<number | null> => {
  const request = await sendRequest<TShopItem[]>({
    url: "shops/lives",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request?.length ? request[0].id : null;
};

export const getLeaderboard = async (
  initData: string,
  sessionId: string
): Promise<TLeaderboardStats | null> => {
  return sendRequest<TLeaderboardStats>({
    url: "users/leaderboard",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const updateActiveCharacter = async (
  initData: string,
  characterId: number,
  sessionId: string
): Promise<TCharacter | null> => {
  return sendRequest<TCharacter>({
    url: `characters/${characterId}`,
    method: "PATCH",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      is_active: true,
    },
  });
};

export const startTask = async (
  initData: string,
  task_id: number,
  sessionId: string
): Promise<TTask | null> => {
  return sendRequest<TTask>({
    url: "tasks/start",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      task_id,
    },
  });
};

export const claimTask = async (
  initData: string,
  task_id: number,
  sessionId: string
): Promise<TTask | null> => {
  return sendRequest<TTask>({
    url: "tasks/claim",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      task_id,
    },
  });
};

export const getTask = async (
  initData: string,
  taskId: number,
  sessionId: string
): Promise<TTask | null> => {
  return sendRequest<TTask>({
    url: `tasks/${taskId}`,
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const completeTutorial = async (
  initData: string,
  sessionId: string
): Promise<void> => {
  await sendRequest<TTask>({
    url: "users/onboarding_complete",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getMap = async (mapId: number): Promise<TLevelMapItem[][]> => {
  const request = await sendRequest<TLevelMapItem[][]>({
    url: `https://storage.cryptorush.fun/maps/${mapId}.json`,
    isExternalUrl: true,
  });

  return request || [];
};

export const getSessionId = async (
  initData: string
): Promise<string | null> => {
  return sendRequest<string>({
    url: "users/session",
    headers: {
      "x-auth": initData,
    },
  });
};

export const getAirdropTx = async (
  initData: string,
  sessionId: string
): Promise<TTonTx | null> => {
  return sendRequest<TTonTx>({
    url: "airdrop/transaction",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getAirdropTasks = async (
  initData: string,
  sessionId: string
): Promise<TAirdropTask[]> => {
  const request = await sendRequest<TAirdropTask[]>({
    url: "airdrop/tasks",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const claimAirdropTask = async (
  initData: string,
  sessionId: string
): Promise<TAirdropTask | null> => {
  return sendRequest<TAirdropTask>({
    url: "airdrop/tasks/claim",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const checkAirdropTx = async (
  initData: string,
  tx_id: string,
  sessionId: string
): Promise<TAirdropTask | null> => {
  return sendRequest<TAirdropTask>({
    url: "airdrop/transaction/check",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    params: {
      tx_id,
    },
  });
};

export const getFreeLives = async (
  initData: string,
  sessionId: string
): Promise<TLivesResponse | null> => {
  return sendRequest<TLivesResponse>({
    url: "users/lives",
    method: "PATCH",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getHillKings = async (
  initData: string,
  sessionId: string
): Promise<THillKing[]> => {
  const request = await sendRequest<THillKing[]>({
    url: "hill",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const getBecomeKingInvoice = async (
  initData: string,
  sessionId: string
): Promise<string | null> => {
  const request = await sendRequest<TInvoiceUrl>({
    url: "hill/become-a-king",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request?.url || null;
};

export const getBoostPackages = async (
  initData: string,
  sessionId: string
): Promise<TBoostPackage[]> => {
  const request = await sendRequest<TBoostPackage[]>({
    url: "hill/packages",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const getRouletteGifts = async (
  initData: string,
  sessionId: string
): Promise<TRouletteGift[]> => {
  const request = await sendRequest<TRouletteGift[]>({
    url: "gifts",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const getRouletteTickets = async (
  initData: string,
  sessionId: string
): Promise<TRouletteTicket[]> => {
  const request = await sendRequest<TRouletteTicket[]>({
    url: "gifts/tickets",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const wheelRoulette = async (
  initData: string,
  sessionId: string
): Promise<TRouletteGift | null> => {
  return sendRequest<TRouletteGift>({
    url: "gifts/wheel",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getDailyRewards = async (
  initData: string,
  sessionId: string
): Promise<TDailyReward[]> => {
  const request = await sendRequest<TDailyReward[]>({
    url: "users/daily_rewards",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const getMiningProducts = async (
  initData: string,
  sessionId: string
): Promise<TMiningProduct[]> => {
  const request = await sendRequest<TMiningProduct[]>({
    url: "mining/products",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const getMiningProductInvoice = async (
  initData: string,
  shopId: number,
  sessionId: string
): Promise<string | null> => {
  const request = await sendRequest<TInvoiceUrl>({
    url: `mining/stars/shop_id/${shopId}`,
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request?.url || null;
};

export const getMiningInfo = async (
  initData: string,
  sessionId: string
): Promise<TMiningInfo | null> => {
  return sendRequest<TMiningInfo>({
    url: "mining/info",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getMiningTx = async (
  initData: string,
  shopId: number,
  sessionId: string
): Promise<TTonTx | null> => {
  return sendRequest<TTonTx>({
    url: `mining/transaction/shop_id/${shopId}`,
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const checkMiningTx = async (
  initData: string,
  tx_id: string,
  sessionId: string
): Promise<TMiningInfo | null> => {
  return sendRequest<TMiningInfo>({
    url: "mining/transaction/check",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    params: {
      tx_id,
    },
  });
};

export const getSpecialOffer = async (
  initData: string,
  sessionId: string
): Promise<TSpecialOffer | null> => {
  return sendRequest<TSpecialOffer>({
    url: "shops/special_offer/info",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getSpecialOfferTx = async (
  initData: string,
  sessionId: string
): Promise<TTonTx | null> => {
  return sendRequest<TTonTx>({
    url: "shops/special_offer/transaction",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const checkSpecialOfferTx = async (
  initData: string,
  tx_id: string,
  sessionId: string
): Promise<TSpecialOffer | null> => {
  return sendRequest<TSpecialOffer>({
    url: "shops/special_offer/transaction/check",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    params: {
      tx_id,
    },
  });
};

export const startFreeMining = async (
  initData: string,
  sessionId: string
): Promise<TMiningInfo | null> => {
  return sendRequest<TMiningInfo>({
    url: "mining/free",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const claimMining = async (
  initData: string,
  sessionId: string
): Promise<TMiningInfo | null> => {
  return sendRequest<TMiningInfo>({
    url: "mining/claim",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const getShopTx = async (
  initData: string,
  shopId: number,
  sessionId: string
): Promise<TTonTx | null> => {
  return sendRequest<TTonTx>({
    url: `shops/transaction/product_id/${shopId}`,
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });
};

export const checkShopTx = async (
  initData: string,
  tx_id: string,
  sessionId: string
): Promise<TShopItem | null> => {
  return sendRequest<TShopItem>({
    url: "shops/transaction/check",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    params: {
      tx_id,
    },
  });
};

export const getFreeOffers = async (
  initData: string,
  sessionId: string
): Promise<TShopItem[]> => {
  const request = await sendRequest<TShopItem[]>({
    url: "shops/free_offers",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
  });

  return request || [];
};

export const takeFreeOffer = async (
  initData: string,
  sessionId: string,
  product_id: number
): Promise<void> => {
  await sendRequest<string | null>({
    url: "shops/free_offer/take",
    method: "POST",
    headers: {
      "x-auth": initData,
      "x-session-id": sessionId,
    },
    data: {
      product_id,
    },
  });
};
