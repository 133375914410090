import * as React from "react";
import { RouterProvider } from "react-router-dom";
import type { Router } from "@remix-run/router";

interface Props {
  router: Router;
}

const App: React.FC<Props> = (props) => {
  const { router } = props;

  return <RouterProvider router={router} />;
};

export default App;
