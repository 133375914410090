import * as React from "react";

const useCopied = () => {
  const [isCopied, setCopied] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [isCopied]);

  const onCopy = async (value: string): Promise<void> => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
  };

  return {
    isCopied,
    onCopy,
  };
};

export default useCopied;
