import * as React from "react";

// Hooks
import useBoolean from "@hooks/useBoolean";
import useCounter from "@hooks/useCounter";
import useInterval from "@hooks/useInterval";

type CountdownOptions = {
  countStart: number;
  intervalMs?: number;
  isIncrement?: boolean;
  countStop?: number;
  step?: number;
};

type CountdownControllers = {
  startCountdown: () => void;
  stopCountdown: () => void;
  resetCountdown: () => void;
  setCountStart: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const useCountdown = ({
  countStart: countStartInitial = 0,
  countStop = 0,
  intervalMs = 1000,
  isIncrement = false,
  step: stepInitial = 1,
}: CountdownOptions): [number, CountdownControllers] => {
  const [countStart, setCountStart] = React.useState<number>(countStartInitial);
  const [step, setStep] = React.useState<number>(stepInitial);

  const {
    count,
    increment,
    decrement,
    reset: resetCounter,
  } = useCounter(countStart, step);

  const {
    value: isCountdownRunning,
    setTrue: startCountdown,
    setFalse: stopCountdown,
  } = useBoolean(false);

  const resetCountdown = React.useCallback(() => {
    stopCountdown();
    resetCounter();
  }, [stopCountdown, resetCounter]);

  const countdownCallback = React.useCallback(() => {
    if (count === countStop) {
      stopCountdown();
      return;
    }

    if (isIncrement) {
      increment();
    } else {
      decrement();
    }
  }, [count, countStop, decrement, increment, isIncrement, stopCountdown]);

  useInterval(countdownCallback, isCountdownRunning ? intervalMs : null);

  return [
    count,
    { startCountdown, stopCountdown, resetCountdown, setCountStart, setStep },
  ];
};

export default useCountdown;
