import * as React from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

// Components
import Wrapper from "@components/Wrapper";
import BackButton from "@components/BackButton";
import PixiProvider from "@components/PixiProvider";
import UserInterface from "@components/Game/UserInterface";
import PlayerScreen from "@components/Game/PlayerScreen";
import LevelMap from "@components/Game/LevelMap";
import Player from "@components/Game/Player";
import Spinner from "@components/Spinner";

// Sheets
import GameOverSheet from "@sheets/GameOver";
import LevelCompletedSheet from "@sheets/LevelCompleted";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setActiveCharacter, setLives } from "@store/reducers/app";

// Hooks
import useGame from "@hooks/useGame";
import useWebApp from "@hooks/useWebApp";

// Utils
import { updateActiveCharacter } from "@utils/api";
import type { TCharacter, TMapDay } from "@utils/api/types";

// Styles
import Styles from "./styles";

type TLocationState = {
  character: TCharacter;
  mapDay: TMapDay;
};

const GameplayPage: React.FC = () => {
  const {
    state,
  }: {
    state: TLocationState;
  } = useLocation();

  const lives = useAppSelector((state) => state.app.lives);
  const dispatch = useAppDispatch();

  const removeLive = (): void => {
    dispatch(setLives(lives - 1));
  };

  const {
    containerRef,
    levelMap,
    isLevelMapLoading,
    gridWidth,
    gridHeight,
    cellSize,
    playerCoordinates,
    playerAngle,
    score,
    level,
    levelId,
    activeModal,
    screenAnimation,
    moveCoordinates,
    sprites,
    totalCoins,
    setNextLevel,
    onCloseModal,
    updateEnemyPosition,
    onResetLevel,
    onDamage,
  } = useGame({
    lives,
    skin: state.character.skin,
    mapDay: state.mapDay,
    withReplay: true,
    removeLive,
  });

  const sessionId = useAppSelector((state) => state.app.sessionId);

  const { initData } = useWebApp();

  React.useEffect(() => {
    onUpdateActiveCharacter();
  }, []);

  const onUpdateActiveCharacter = async (): Promise<void> => {
    const { is_active, id } = state.character;

    if (!is_active && sessionId) {
      dispatch(setActiveCharacter(id));

      await updateActiveCharacter(initData, id, sessionId);
    }
  };

  return (
    <Wrapper>
      <Styles.Container
        ref={containerRef}
        className={cn({
          shake: screenAnimation,
        })}
      >
        <BackButton />
        {isLevelMapLoading || sprites === null ? (
          <Styles.SpinnerRow>
            <Spinner size={40} />
          </Styles.SpinnerRow>
        ) : (
          <>
            <UserInterface
              score={score}
              level={level}
              lives={lives}
              isActive={activeModal === "level-completed"}
              totalCoins={totalCoins}
            />
            <PixiProvider>
              <PlayerScreen
                rowPlayerPos={playerCoordinates.y / cellSize}
                colPlayerPos={playerCoordinates.x / cellSize}
                gridWidth={gridWidth}
                gridHeight={gridHeight}
                cellSize={cellSize}
              >
                <LevelMap
                  levelMap={levelMap}
                  cellSize={cellSize}
                  updateEnemyPosition={updateEnemyPosition}
                  sprites={sprites}
                  playerCoordinates={playerCoordinates}
                  onDamage={onDamage}
                />
                <Player
                  width={cellSize}
                  height={cellSize}
                  playerCoordinates={playerCoordinates}
                  playerAngle={playerAngle}
                  moveCoordinates={moveCoordinates}
                  thumbnail={state.character.skin.thumbnail_url}
                />
              </PlayerScreen>
            </PixiProvider>
            <Styles.Boosters>
              {Array(3)
                .fill("booster")
                .map((i, index) => (
                  <Styles.Booster
                    key={`${i}/${index}`}
                    src={`/assets/game/boosters/${index + 1}.png`}
                    alt="booster"
                  />
                ))}
            </Styles.Boosters>
          </>
        )}
      </Styles.Container>
      <GameOverSheet
        isOpen={activeModal === "game-over"}
        onClose={onCloseModal}
        lives={lives}
        onResetLevel={onResetLevel}
      />
      <LevelCompletedSheet
        isOpen={activeModal === "level-completed"}
        onClose={onCloseModal}
        score={score}
        level={level}
        levelId={levelId}
        setNextLevel={setNextLevel}
        totalCoins={totalCoins}
      />
    </Wrapper>
  );
};

export default GameplayPage;
