import styled from "styled-components";

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Username = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.54px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Rank = styled.p`
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.54px;
`;

const CoinsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

const CoinsIcon = styled.img`
  width: 22px;
  height: 22px;
`;

const CoinsValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
`;

const CoinsSymbol = styled.p`
  color: #c7c7cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Row,
  Username,
  Rank,
  CoinsRow,
  CoinsIcon,
  CoinsValue,
  CoinsSymbol,
};

export default Styles;
