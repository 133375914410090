import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: calc(100vh - var(--tg-viewport-height, 100vh));
  background-color: rgba(39, 39, 39, 0.75);
  background-blend-mode: luminosity;
  backdrop-filter: blur(12.5px);

  &.black {
    background-color: #000000;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  height: 66px;
  padding: 7px 0;
`;

const Tab = styled.button`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  padding: 0;
  font-family: "Stapel-Light";

  &.active {
    font-family: "Stapel_Medium";
  }

  &:not(.active) {
    opacity: 0.5;
  }
`;

const TabIcon = styled.img`
  width: 35px;
  height: 35px;
`;

const Styles = {
  Container,
  Row,
  Tab,
  TabIcon,
};

export default Styles;
