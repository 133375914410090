import * as React from "react";
import cn from "classnames";
import Countdown, { type CountdownRenderProps } from "react-countdown";
import dayjs from "dayjs";

// Utils
import { abbreviateNumber } from "@utils/format";
import type { TMapDay } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isDayComplete: boolean;
  onComplete: () => void;
  levelMap: TMapDay[];
}

const MiningLevels: React.FC<Props> = (props) => {
  const { isDayComplete, onComplete, levelMap } = props;

  if (isDayComplete) {
    return (
      <Styles.CompleteRow>
        <Styles.CompleteText>
          New levels will be available in{"\n"}
          <Countdown
            renderer={({ hours, minutes, seconds }: CountdownRenderProps) => (
              <Styles.CompleteTextTimer>
                {hours}:{minutes > 10 ? minutes : `0${minutes}`}:{seconds}
              </Styles.CompleteTextTimer>
            )}
            date={dayjs().utc().endOf("day").toDate()}
            onComplete={onComplete}
          />
        </Styles.CompleteText>
      </Styles.CompleteRow>
    );
  }

  return (
    <Styles.Levels>
      {Array(5)
        .fill("level")
        .map((i, index) => {
          const findLevel = levelMap?.[index];

          const isFinished = levelMap?.[index]?.is_completed;
          const isFirstNotFinised =
            levelMap.findIndex((i) => !i.is_completed) === index;
          const isActive = !isFinished && isFirstNotFinised;

          return (
            <Styles.Level
              key={`${i}/${index}`}
              className={cn({
                active: isActive,
              })}
            >
              <Styles.LevelHeading>
                <Styles.LevelTitle>Level</Styles.LevelTitle>
                <Styles.LevelDayRow>
                  {isFinished ? (
                    <Styles.LevelCheckIcon
                      src="/assets/icons/check.svg"
                      alt="icon"
                    />
                  ) : (
                    <Styles.LevelDay>{index + 1}</Styles.LevelDay>
                  )}
                </Styles.LevelDayRow>
              </Styles.LevelHeading>
              {findLevel ? (
                <Styles.LevelBody>
                  <Styles.LevelRewardRow>
                    <Styles.LevelReward className="blue">
                      +{findLevel.tickets}
                    </Styles.LevelReward>
                    <Styles.LevelRewardLabel>ticket</Styles.LevelRewardLabel>
                  </Styles.LevelRewardRow>
                  <Styles.LevelRewardRow>
                    <Styles.LevelReward>
                      +{abbreviateNumber(findLevel.max_amount, 0)}
                    </Styles.LevelReward>
                    <Styles.LevelRewardLabel>$cp</Styles.LevelRewardLabel>
                  </Styles.LevelRewardRow>
                </Styles.LevelBody>
              ) : null}
            </Styles.Level>
          );
        })}
    </Styles.Levels>
  );
};

export default MiningLevels;
