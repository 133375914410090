import * as React from "react";
import { QRCodeSVG } from "qrcode.react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  referralLink: string;
}

const QrCodeSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, referralLink } = props;

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>Scan the qr</Styles.Title>
      <Styles.QrCodeRow>
        <QRCodeSVG
          value={referralLink}
          size={195}
          bgColor="#1c1d1f"
          fgColor="#ffffff"
        />
      </Styles.QrCodeRow>
      <Styles.ButtonRow>
        <Button title="Close" variant="outline" onClick={onClose} />
      </Styles.ButtonRow>
    </SheetWrapper>
  );
};

export default QrCodeSheet;
