import * as React from "react";

// Components
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public onReboot() {
    window.open("/", "_self");
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Styles.Container>
          <Styles.Title>Sorry, something{"\n"}went wrong.</Styles.Title>
          <Styles.Description>
            We are already working on the issue.
          </Styles.Description>
          <Button title="Reboot" onClick={this.onReboot} />
        </Styles.Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
