import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const Advantages = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AdvantageCard = styled.div`
  flex: 1;
  height: 40px;
  border-radius: 6px;
  background: rgba(255, 204, 0, 0.15);
  backdrop-filter: blur(3px);
  background-image: url("/assets/advantage-glow.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AdvantageCardIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const AdvantageCardTitle = styled.p`
  flex: 1;
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 3vw;
  font-weight: 700;
  text-transform: uppercase;
`;

const List = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const Card = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: #10111a;
  backdrop-filter: blur(3px);
  background-image: url("/assets/auto-farm-card-glow.svg");
  background-repeat: no-repeat;
  padding: 12px 20px 12px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid #10111a;

  &.active {
    border: 1px solid #fc0;
  }

  &.disabled {
    opacity: 0.5;
  }
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 3vw;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
`;

const CardPrices = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const CardPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CardPrice = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 500;
  text-align: right;
`;

const CardPriceIcon = styled.img`
  width: 13px;
  height: 13px;
`;

const CardSaleRow = styled.div`
  width: 75px;
  height: 33.5px;
  background: url("/assets/auto-farm-card-sale.svg") no-repeat;
  position: absolute;
  right: -12px;
  top: -8px;
`;

const CardSale = styled.p`
  color: #ffffff;
  text-align: right;
  font-family: "Stapel_Condensed_Extra_Bold";
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  line-height: 85%;
  text-transform: uppercase;
  margin: 6px 12px 0 0;
`;

const CardBadgeRow = styled.div`
  width: 94px;
  height: 33px;
  background: url("/assets/auto-farm-badge.svg") no-repeat;
  background-size: contain;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const CardBadge = styled.p`
  color: #fc0;
  font-family: "Stapel_Bold";
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  font-family: "Stapel_Medium";
  color: #c7c7cc;
  font-size: 18px;
  font-weight: 300;
`;

const DescriptionYellow = styled.span`
  color: #fc0;
  font-size: 18px;
  font-weight: 500;
`;

const ListText = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ListTextItem = styled.p`
  color: #c7c7cc;
  font-size: 16px;
  font-weight: 400;
  font-family: "Stapel_Medium";
  white-space: pre-line;
`;

const ListTextItemYellow = styled.span`
  color: #fc0;
`;

const IconRow = styled.div`
  margin: -80px 0 -30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Icon = styled.img`
  width: 300px;
  height: 300px;
  z-index: 2;
`;

const Glow = styled.img`
  width: 100%;
  position: absolute;
  animation: ${rotating} 5s infinite linear;
`;

const Stats = styled.p`
  margin: 20px 0 0 0;
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
`;

const StatsYellow = styled.span`
  color: #fc0;
`;

const CardHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const CardCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  border: 1.5px solid #fc0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.active) {
    opacity: 0.5;
  }

  &.active {
    background-color: #fc0;
  }
`;

const CardCircleIcon = styled.img`
  width: 15px;
  height: 15px;
`;

const ButtonRow = styled.div`
  padding: 20px 22.5px;

  button {
    height: 50px;
  }
`;

const FreePrice = styled.p`
  color: #28ce62;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
`;

const CoinIcon = styled.img`
  width: 35px;
  height: 35px;
`;

const Symbol = styled.p`
  color: #d9d9d9;
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Styles = {
  Heading,
  Title,
  TitleYellow,
  Advantages,
  AdvantageCard,
  AdvantageCardIcon,
  AdvantageCardTitle,
  List,
  Card,
  CardTitle,
  CardPrices,
  CardPriceRow,
  CardPrice,
  CardPriceIcon,
  CardSaleRow,
  CardSale,
  CardBadgeRow,
  CardBadge,
  Description,
  DescriptionYellow,
  ListText,
  ListTextItem,
  ListTextItemYellow,
  IconRow,
  Icon,
  Glow,
  Stats,
  StatsYellow,
  CardHeading,
  CardCircle,
  CardCircleIcon,
  ButtonRow,
  FreePrice,
  CoinIcon,
  Symbol,
};

export default Styles;
