import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Name = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 15.376px;
  font-weight: 400;
  letter-spacing: 0.769px;
  text-transform: uppercase;
  margin: 14px 0 0 48px;
`;

const Grid = styled.div`
  margin: 14px 0 0 3px;
  width: 186px;
  height: 317px;
  background: url("/assets/character-grid.png") no-repeat;
  background-size: cover;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Styles = {
  Container,
  Name,
  Grid,
  Image,
};

export default Styles;
