import * as React from "react";
import cn from "classnames";
import {
  useTonConnectUI,
  useTonAddress,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import { beginCell, Cell } from "@ton/core";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";
import Spinner from "@components/Spinner";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { sleep } from "@utils/index";
import { formatPoints } from "@utils/format";
import {
  getSpecialOfferTx,
  getBalance,
  getSpecialOffer,
  checkSpecialOfferTx,
} from "@utils/api";
import type { TSpecialOffer } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type TState = "idle" | "error" | "success";

const descriptions: Record<TState, string> = {
  error: "Try again and get your reward",
  idle: "Support TON ecosystem and Earn Big Rewards!",
  success: "Earn Big Rewards!",
};

const SpecialOfferSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  const [specialOffer, setSpecialOffer] = React.useState<TSpecialOffer | null>(
    null
  );
  const [state, setState] = React.useState<"idle" | "error" | "success">(
    "idle"
  );
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { initData } = useWebApp();

  const sessionId = useAppSelector((state) => state.app.sessionId);
  const dispatch = useAppDispatch();

  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const {
    open: openModal,
    state: { status: modalStatus },
  } = useTonConnectModal();

  React.useEffect(() => {
    onGetOffer();
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      setState("idle");
      setLoading(false);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (modalStatus === "closed" && isLoading && address) {
      onSendTon();
    } else if (modalStatus === "closed" && isLoading && !address) {
      setLoading(false);
      setState("error");
    } else if (modalStatus === "opened") {
      setLoading(true);
    }
  }, [modalStatus, address]);

  const onGetOffer = async (): Promise<void> => {
    setSpecialOffer(await getSpecialOffer(initData, sessionId));
  };

  const onClaim = async (): Promise<void> => {
    if (state === "success") {
      dispatch(setBalance(await getBalance(initData, sessionId)));
      return onClose();
    }

    if (!address) {
      openModal();
    } else {
      onSendTon();
    }
  };

  const onSendTon = async (): Promise<void> => {
    setLoading(true);

    if (state !== "idle") {
      setState("idle");
    }

    const tx = await getSpecialOfferTx(initData, sessionId);

    if (!tx) {
      setLoading(false);
      return setState("error");
    }

    const {
      valid_until: validUntil,
      messages: [message],
    } = tx;

    const payload = beginCell()
      .storeUint(0, 32)
      .storeStringTail(message.payload)
      .endCell()
      .toBoc()
      .toString("base64");

    tonConnectUI
      .sendTransaction(
        {
          validUntil,
          messages: [
            {
              address: message.address,
              amount: message.amount,
              payload,
            },
          ],
        },
        {
          notifications: [],
        }
      )
      .then(async (response) => {
        const txId = Cell.fromBase64(response.boc).hash().toString("hex");

        await checkTx(txId);

        setState("success");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setState("error");
      });
  };

  const checkTx = async (txId: string): Promise<TSpecialOffer> => {
    await sleep(5000);

    const result = await checkSpecialOfferTx(
      initData,
      encodeURIComponent(txId),
      sessionId
    );

    if (result) {
      return result;
    }

    return checkTx(txId);
  };

  return (
    <SheetWrapper
      isOpen={isOpen}
      onClose={onClose}
      backgroundImage={`url("/assets/auto-farm-sheet-bg.png")`}
      isBlack
      disableDrag={isLoading}
    >
      <Styles.Container>
        {state === "idle" ? (
          <Styles.Title>
            Exclusive{"\n"}
            <Styles.TitleYellow>🚀 Special Offer 🚀</Styles.TitleYellow>
          </Styles.Title>
        ) : null}
        {state === "error" ? (
          <Styles.Title>
            connecting <Styles.TitleBlue>ton</Styles.TitleBlue>-wallet{" "}
            <Styles.TitleRed>Error</Styles.TitleRed>
          </Styles.Title>
        ) : null}

        <Styles.IconRow>
          <Styles.Icon src={`/assets/special-offer/${state}.png`} alt="icon" />
          <Styles.Glow src="/assets/price-glow.png" alt="glow" />
        </Styles.IconRow>

        <Styles.Description>{descriptions[state]}</Styles.Description>

        {specialOffer ? (
          <Styles.Rewards
            className={cn({
              disabled: state === "error",
            })}
          >
            <Styles.Reward>
              <Styles.RewardIcon
                src="/assets/special-offer/coin.png"
                alt="icon"
              />
              <Styles.RewardRow>
                <Styles.RewardValue>
                  {formatPoints(specialOffer.points)}
                </Styles.RewardValue>
                <Styles.RewardLabel>crypto points</Styles.RewardLabel>
              </Styles.RewardRow>
            </Styles.Reward>
            <Styles.Reward>
              <Styles.RewardIcon
                src="/assets/special-offer/tickets.png"
                alt="icon"
              />
              <Styles.RewardRow>
                <Styles.RewardValue>{specialOffer.tickets}</Styles.RewardValue>
                <Styles.RewardLabel>spin tickets</Styles.RewardLabel>
              </Styles.RewardRow>
            </Styles.Reward>
          </Styles.Rewards>
        ) : (
          <Styles.SpinnerRow>
            <Spinner size={40} />
          </Styles.SpinnerRow>
        )}

        <Styles.ButtonRow>
          <Button
            title={
              state === "idle" || state === "success" ? "Claim" : "Try again"
            }
            yellowTitle={
              state === "idle"
                ? "your offer"
                : state === "success"
                ? "reward"
                : undefined
            }
            onClick={onClaim}
            isLoading={isLoading}
          />
        </Styles.ButtonRow>
      </Styles.Container>
    </SheetWrapper>
  );
};

export default SpecialOfferSheet;
