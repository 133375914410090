import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
`;

const Row = styled.div`
  height: var(--tg-viewport-height, 100vh);
  overflow-y: scroll;
  display: flex;

  &.with-profile {
    padding-top: 50px;
  }

  &.with-navigation {
    padding-bottom: 66px;
  }
`;

const Styles = {
  Container,
  Row,
};

export default Styles;
