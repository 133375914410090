import * as React from "react";
import cn from "classnames";

// Components
import Wrapper from "@components/Wrapper";
import ReferalCard from "@components/ReferalCard";
import Button from "@components/Button";
import Spinner from "@components/Spinner";

// Sheets
import QrcodeSheet from "@sheets/QrCode";

// Store
import { useAppSelector } from "@store/index";

// Hooks
import useCopied from "@hooks/useCopied";
import useWebApp from "@hooks/useWebApp";

// Utils
import { getReferrals } from "@utils/api";
import { formatPoints } from "@utils/format";
import type { TReferral } from "@utils/api/types";

// Styles
import Styles from "./styles";

const ReferalPage: React.FC = () => {
  const [activeSheet, setActiveSheet] = React.useState<"qrcode" | null>(null);
  const [referrals, setReferrals] = React.useState<TReferral[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [count, setCount] = React.useState<number>(0);

  const { onCopy, isCopied } = useCopied();
  const { impactOccurred, openTelegramLink, initData } = useWebApp();

  const user = useAppSelector((state) => state.app.user);
  const sessionId = useAppSelector((state) => state.app.sessionId);
  const balance = useAppSelector((state) => state.app.balance);

  React.useEffect(() => {
    onGetReferrals();
  }, []);

  const onGetReferrals = async (): Promise<void> => {
    if (!initData || !sessionId) {
      return;
    }

    setLoading(true);

    const response = await getReferrals(initData, 0, sessionId);

    if (response) {
      setReferrals(response.List);
      setCount(response.count);
    }

    setLoading(false);
  };

  const onCopyText = (): void => {
    if (!user) {
      return;
    }

    impactOccurred("heavy");
    onCopy(user.referral_link);
  };

  const onShowQrCode = (): void => {
    impactOccurred("heavy");
    setActiveSheet("qrcode");
  };

  const onCloseSheet = (): void => {
    setActiveSheet(null);
  };

  const onInvite = (): void => {
    if (!user) {
      return;
    }

    impactOccurred("heavy");
    openTelegramLink(
      `https://t.me/share/url?url=${encodeURIComponent(
        user.referral_link
      )}&text=Join me in Cryptorush—the ultimate crypto adventure! Let's team up and earn big rewards.`
    );
  };

  return (
    <Wrapper withNavigation withProfile>
      <Styles.Container>
        <Styles.Cover>
          <Styles.CoinsRow>
            <Styles.CoinsIcon src="/assets/cr-coin.png" alt="coin" />
            <Styles.CoinsValue>
              {formatPoints(balance?.invite_balance || 0)}
            </Styles.CoinsValue>
            <Styles.CoinsSymbol>$CP</Styles.CoinsSymbol>
          </Styles.CoinsRow>
          <Styles.Description>
            <Styles.DescriptionExpandedBold>
              Invite a Friend
            </Styles.DescriptionExpandedBold>
            {"\n"}
            <Styles.DescriptionBold>Earn:</Styles.DescriptionBold> 10 000
            Cryptopoints{"\n"}
            <Styles.DescriptionBold>Bonus:</Styles.DescriptionBold> 20% of
            friend's points{"\n"}
            <Styles.DescriptionBold>Condition:</Styles.DescriptionBold> friend
            completes first level
          </Styles.Description>
        </Styles.Cover>
        <Styles.Bottom>
          {isLoading ? (
            <Styles.SpinnerRow>
              <Spinner size={40} />
            </Styles.SpinnerRow>
          ) : null}
          {!isLoading && !referrals.length ? (
            <Styles.NotFoundText>
              Your friends list is empty, invite a friend and get points
            </Styles.NotFoundText>
          ) : null}
          {!isLoading && referrals.length ? (
            <>
              <Styles.BottomHeading>
                <Styles.BottomHeadingTitle>
                  Friend list
                </Styles.BottomHeadingTitle>
                <Styles.BottomHeadingTitle>
                  {count} {count > 1 ? "Friends" : "Friend"}
                </Styles.BottomHeadingTitle>
              </Styles.BottomHeading>
              <Styles.List>
                {referrals.map((referral) => (
                  <ReferalCard
                    key={`${referral.chat_id}/${referral.user_name}`}
                    referral={referral}
                  />
                ))}
              </Styles.List>
            </>
          ) : null}
        </Styles.Bottom>
        <Styles.Actions>
          <Button title="Invite a friend" onClick={onInvite} />
          <Styles.Button
            onClick={onCopyText}
            className={cn({
              copied: isCopied,
            })}
          >
            <Styles.ButtonIcon
              src={
                isCopied ? "/assets/icons/copied.svg" : "/assets/icons/copy.svg"
              }
              alt="icon"
            />
          </Styles.Button>
          <Styles.Button onClick={onShowQrCode}>
            <Styles.ButtonIcon src="/assets/icons/qrcode.svg" alt="icon" />
          </Styles.Button>
        </Styles.Actions>
      </Styles.Container>
      {user ? (
        <QrcodeSheet
          isOpen={activeSheet === "qrcode"}
          onClose={onCloseSheet}
          referralLink={user.referral_link}
        />
      ) : null}
    </Wrapper>
  );
};

export default ReferalPage;
