import * as React from "react";
import { Swiper, SwiperSlide, type SwiperClass } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import cn from "classnames";
import { fromNano } from "@ton/core";

// Utils
import { abbreviateNumber, formatPoints } from "@utils/format";
import type { TShopItem } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  onViewInfo: () => void;
  item: TShopItem;
}

const ShopSpecialCard: React.FC<Props> = (props) => {
  const {
    onViewInfo,
    item: { title, discount, image_url, items, price, label, price_ton },
  } = props;

  const [slideIndex, setSlideIndex] = React.useState<number>(0);

  const [firstTitleWord] = title.split(" ");
  const restTitle = title.split(`${firstTitleWord} `)[1];

  const onRealIndexChange = (swiper: SwiperClass): void => {
    setSlideIndex(Math.ceil(swiper.realIndex / (Number(items.length) / 3)));
  };

  return (
    <Styles.Container>
      <Styles.Heading>
        {label.length ? (
          <Styles.Badge>
            <Styles.BadgeValue>{label}</Styles.BadgeValue>
          </Styles.Badge>
        ) : null}
        <Styles.Title>
          {firstTitleWord}
          {"\n"}
          {restTitle}
        </Styles.Title>
      </Styles.Heading>
      <Styles.Carousel>
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          allowTouchMove={false}
          onRealIndexChange={onRealIndexChange}
          loop={items.length > 3}
        >
          {items.map((item) => {
            return (
              <SwiperSlide key={`${item.type}/${item.name}`}>
                <Styles.CarouselItem>
                  <Styles.CarouselItemHeading>
                    <Styles.CarouselItemHeadingTitle>
                      {item.type === "skin"
                        ? item.name
                        : abbreviateNumber(item.value, 0)}
                    </Styles.CarouselItemHeadingTitle>
                  </Styles.CarouselItemHeading>
                  <Styles.CarouselItemOverlay />
                  <Styles.CarouselItemBody>
                    <Styles.CarouselItemImage
                      src={item.image_url}
                      alt="image"
                    />
                  </Styles.CarouselItemBody>
                </Styles.CarouselItem>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Styles.Carousel>
      {items.length > 3 ? (
        <Styles.Dots>
          {Array(Math.ceil(items.length / 3))
            .fill("dot")
            .map((i, index) => (
              <Styles.Dot
                key={`${i}/${index}`}
                className={cn({
                  active: index === slideIndex,
                })}
              />
            ))}
        </Styles.Dots>
      ) : null}
      <Styles.ButtonRow>
        <Styles.ButtonArea onClick={onViewInfo}>
          <Styles.BuyLabel>Buy</Styles.BuyLabel>
          <Styles.Prices>
            <Styles.PriceRow>
              <Styles.Price>{formatPoints(price)}</Styles.Price>
              <Styles.PriceIcon
                src="/assets/icons/stars-white.svg"
                alt="stars"
              />
            </Styles.PriceRow>
            <Styles.PriceRow>
              <Styles.Price>{fromNano(price_ton)}</Styles.Price>
              <Styles.PriceIcon src="/assets/icons/ton-white.svg" alt="stars" />
            </Styles.PriceRow>
          </Styles.Prices>
        </Styles.ButtonArea>
      </Styles.ButtonRow>
      <Styles.Image src={image_url} alt="image" />
      <Styles.SaleRow>
        <Styles.Sale>- {discount}%</Styles.Sale>
      </Styles.SaleRow>
    </Styles.Container>
  );
};

export default ShopSpecialCard;
