import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Utils
import type {
  TMiningInfo,
  TBalance,
  TCharacter,
  TDailyReward,
  TUser,
} from "@utils/api/types";

export interface AppState {
  dayStreak: number;
  user: TUser | null;
  characters: TCharacter[];
  balance: TBalance | null;
  lives: number;
  sessionId: string;
  dailyRewards: TDailyReward[];
  mining: TMiningInfo | null;
  isFirstVisit: boolean;
}

const initialState: AppState = {
  dayStreak: 0,
  user: null,
  characters: [],
  balance: null,
  lives: 0,
  sessionId: "",
  dailyRewards: [],
  mining: null,
  isFirstVisit: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TUser | null>) => {
      state.user = action.payload;
    },
    setCharacters: (state, action: PayloadAction<TCharacter[]>) => {
      state.characters = action.payload;
    },
    setDayStreak: (state, action: PayloadAction<number>) => {
      state.dayStreak = action.payload;
    },
    addNewCharacter: (state, action: PayloadAction<TCharacter>) => {
      state.characters = [action.payload, ...state.characters];
    },
    setBalance: (state, action: PayloadAction<TBalance | null>) => {
      state.balance = action.payload;
    },
    setLives: (state, action: PayloadAction<number>) => {
      state.lives = action.payload;
    },
    setActiveCharacter: (state, action: PayloadAction<number>) => {
      let findCharacter = state.characters.find(
        (character) => character.id === action.payload
      );

      const removeCharacter = state.characters.filter(
        (character) => character.id !== action.payload
      );

      for (const character of removeCharacter) {
        character.is_active = false;
      }

      if (findCharacter) {
        findCharacter = {
          ...findCharacter,
          is_active: true,
        };

        state.characters = [findCharacter, ...removeCharacter];
      }
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setDailyRewards: (state, action: PayloadAction<TDailyReward[]>) => {
      state.dailyRewards = action.payload;
    },
    setMining: (state, action: PayloadAction<TMiningInfo | null>) => {
      state.mining = action.payload;
    },
    setFirstVisit: (state, action: PayloadAction<boolean>) => {
      state.isFirstVisit = action.payload;
    },
  },
});

export const {
  setUser,
  setCharacters,
  setDayStreak,
  addNewCharacter,
  setBalance,
  setLives,
  setActiveCharacter,
  setSessionId,
  setDailyRewards,
  setMining,
  setFirstVisit,
} = appSlice.actions;

export default appSlice.reducer;
