import * as React from "react";
import { Sprite } from "@pixi/react";

// Utils
import type { TCoordinates } from "@utils/game";

interface Props {
  width: number;
  height: number;
  playerCoordinates: TCoordinates;
  playerAngle: number;
  moveCoordinates: TCoordinates[];
  thumbnail: string;
}

const getMoveAngle = (playerAngle: number): number => {
  if (playerAngle === 0) {
    return 90;
  } else if (playerAngle === 90) {
    return 180;
  } else if (playerAngle === 180) {
    return 270;
  }

  return 0;
};

const Player: React.FC<Props> = (props) => {
  const {
    width,
    height,
    playerCoordinates,
    playerAngle,
    moveCoordinates,
    thumbnail,
  } = props;

  return (
    <>
      <Sprite
        image={thumbnail}
        width={width}
        height={height}
        x={playerCoordinates.x}
        y={playerCoordinates.y}
        angle={playerAngle}
        anchor={0.5}
        scale={1}
      />
      {moveCoordinates.map(({ x, y }, index) => (
        <Sprite
          key={`${x}/${y}/${index}`}
          image="/assets/game/move.png"
          width={width}
          height={height}
          x={x}
          y={y}
          angle={getMoveAngle(playerAngle)}
          anchor={0.5}
        />
      ))}
    </>
  );
};

export default Player;
