import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Utils
import type { TShopTag, TShopItem } from "@utils/api/types";

// Config
import { API_URL } from "@config/consts";

type TGetItemsParams = {
  initData: string;
  tag: string;
  sessionId: string;
};

type TGetTagsParams = {
  initData: string;
  sessionId: string;
};

export const shopApi = createApi({
  reducerPath: "shopApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getTags: builder.query<TShopTag[], TGetTagsParams>({
      query(params) {
        return {
          url: "shops/tags",
          headers: {
            "x-auth": params.initData,
            "x-session-id": params.sessionId,
          },
        };
      },
    }),
    getItems: builder.query<TShopItem[], TGetItemsParams>({
      query(params) {
        return {
          url: `shops/${params.tag}`,
          headers: {
            "x-auth": params.initData,
            "x-session-id": params.sessionId,
          },
        };
      },
    }),
  }),
});

export const { useGetTagsQuery, useGetItemsQuery } = shopApi;
