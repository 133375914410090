import * as React from "react";
import cn from "classnames";

// Config
import { DEFAULT_LIVES } from "@config/consts";

// Styles
import Styles from "./styles";

interface Props {
  title?: string;
  score: number;
  level: number;
  lives: number;
  isActive: boolean;
  totalCoins: number;
}

const GameUserInterface: React.FC<Props> = (props) => {
  const { title, score, level, lives, isActive, totalCoins } = props;

  const [progressLineWidth, setProgressLineWidth] = React.useState<number>(0);

  React.useEffect(() => {
    setProgressLineWidth((score / totalCoins) * 100);
  }, [score, totalCoins]);

  return (
    <Styles.Container
      className={cn({
        active: isActive,
      })}
    >
      <Styles.Title>{title || `Level ${level}`}</Styles.Title>
      <Styles.Progress>
        <Styles.ProgressCoin src="/assets/icons/game-coin.svg" alt="coin" />
        <Styles.ProgressRow>
          <Styles.ProgressRowBorder />
          <Styles.ProgressLineRow>
            <Styles.ProgressLine
              style={{ width: `${progressLineWidth}%` }}
              className={cn({
                active: isActive,
              })}
            />
          </Styles.ProgressLineRow>
          <Styles.ProgressRowArrowIcon
            src="/assets/game-progress-bar-arrow.svg"
            alt="arrow"
          />
          <Styles.ProgressRowBorder />
        </Styles.ProgressRow>
      </Styles.Progress>

      <Styles.LivesRow>
        <Styles.LivesIcons>
          {Array(DEFAULT_LIVES)
            .fill("live")
            .map((i, index) => lives > index)
            .sort((a, b) => Number(a) - Number(b))
            .map((i, index) => (
              <Styles.LivesIcon
                key={`${i}/${index}`}
                src={
                  i
                    ? "/assets/icons/lives-active.svg"
                    : "/assets/icons/lives-disabled.svg"
                }
                alt="live"
              />
            ))}
        </Styles.LivesIcons>
        <Styles.Lives>X{lives}</Styles.Lives>
      </Styles.LivesRow>
    </Styles.Container>
  );
};

export default GameUserInterface;
