import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;

const Arrow = styled.img`
  width: 10px;
  height: 18px;

  &:last-child {
    transform: rotate(180deg);
  }
`;

const Dots = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Dot = styled.div`
  width: 14px;
  height: 14px;
  background: url("/assets/icons/paginate-dot.svg") no-repeat;

  &.active {
    background: url("/assets/icons/paginate-dot-active.svg") no-repeat;
  }
`;

const Styles = {
  Container,
  Arrow,
  Dots,
  Dot,
};

export default Styles;
