const slides = [
  {
    title: "Pick a character",
    description: `Choose the one that best suits your journey in Cyber City.`,
    video: "assets/onboarding/1.mp4",
    buttonTitle: "Play To Earn",
  },
  {
    title: "Complete 5 daily quests",
    description:
      "Earn cryptopoints for every level you complete to progress toward your goal.",
    video: "assets/onboarding/2.mp4",
    buttonTitle: "Play For Airdrop",
  },
  {
    title: "Increase your airdrop",
    description: "Earn cryptopoints now for bigger rewards in Q1 2025",
    video: "assets/onboarding/3.mp4",
    buttonTitle: "Start Earning",
  },
];

export default slides;
