import * as React from "react";
import { useWindowSize } from "usehooks-ts";
import Confetti from "react-confetti";
import { fromNano } from "@ton/core";

// Components
import Button from "@components/Button";

// Utils
import { formatPoints } from "@utils/format";
import type { TRouletteGift } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  gift: TRouletteGift;
  onClose: () => void;
}

const RoulettePrize: React.FC<Props> = (props) => {
  const {
    gift: { amount, type },
    onClose,
  } = props;

  const [isConfettiVisible, setConfettiVisible] = React.useState<boolean>(true);

  const { width, height } = useWindowSize();

  React.useEffect(() => {
    setTimeout(() => {
      setConfettiVisible(false);
    }, 3000);
  }, []);

  return (
    <Styles.Container>
      {isConfettiVisible ? <Confetti width={width} height={height} /> : null}
      <Styles.Title>{type === "jackpot" ? "jackpot" : "winner"}</Styles.Title>
      <Styles.RewardRow>
        <Styles.RewardPlus>+</Styles.RewardPlus>
        {type === "ton" ? (
          <Styles.RewardIcon src="/assets/icons/ton-prize.png" alt="ton" />
        ) : type === "tickets" ? (
          <Styles.RewardIcon src="/assets/icons/ticket.png" alt="ticket" />
        ) : (
          <Styles.RewardIcon src="/assets/cr-coin.png" alt="coin" />
        )}

        <Styles.Reward>
          {type === "ton" ? fromNano(amount) : formatPoints(amount)}
        </Styles.Reward>
        <Styles.RewardSymbol>
          {type === "ton" ? "TON" : type === "tickets" ? "tickets" : "$CP"}
        </Styles.RewardSymbol>
      </Styles.RewardRow>
      <Styles.PrizeRow>
        <Styles.Glow src="/assets/price-glow.png" alt="glow" />
        <Styles.PrizeImage
          src={`/assets/wheel-prizes/${type}.png`}
          alt="prize"
        />
      </Styles.PrizeRow>
      <Styles.ButtonRow>
        <Button title="Continue" onClick={onClose} />
      </Styles.ButtonRow>
    </Styles.Container>
  );
};

export default RoulettePrize;
