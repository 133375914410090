import styled from "styled-components";

const Container = styled.div`
  padding: 0 20px 24px 20px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 3;
`;

const Card = styled.div`
  width: 100%;
  height: 120px;
  border: 2px solid #038cbb;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const AvatarRow = styled.div`
  width: 92px;
  height: 115px;
  background: url("/assets/tutorial-avatar.png") no-repeat;
  background-size: cover;
  position: relative;

  .corner-top {
    right: -2px;
    top: -2px;
    z-index: 2;
  }

  .corner-bottom-right {
    bottom: -2px;
    right: -2px;
    transform: rotate(90deg);
    z-index: 2;
  }

  .corner-bottom-left {
    bottom: -2px;
    left: -2px;
    transform: rotate(180deg);
    z-index: 2;
  }
`;

const DarkCorner = styled.div`
  width: 24px;
  height: 24px;
  background: url("/assets/icons/card-dark-corner.svg") no-repeat;
  position: absolute;

  &:first-child {
    top: -2px;
    left: -2px;
  }

  &:last-child {
    bottom: -2px;
    right: -2px;
    transform: rotate(180deg);
  }
`;

const Grid = styled.div`
  flex: 1;
  height: 100%;
  background: url("/assets/icons/card-grid.svg");
  background-size: 20px;
  padding: 6px 6px 6px 10px;
  position: relative;
  background-color: #000000;

  .corner-top-left {
    left: -2px;
    top: -2px;
    transform: rotate(270deg);
  }

  .corner-top-right {
    right: -2px;
    top: -2px;
  }

  .corner-bottom-left {
    bottom: -2px;
    left: -2px;
    transform: rotate(180deg);
  }

  span {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-line;
  }
`;

const Button = styled.button`
  width: 66px;
  height: 27px;
  background: url("/assets/icons/card-button.svg") no-repeat;
  border: none;
  outline: none;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 11px;
  color: #ffffff;
  font-family: "Stapel-Light";
  font-size: 14px;
  font-weight: 300;
  z-index: 2;
`;

const ButtonIcon = styled.img`
  width: 10px;
  height: 12px;
`;

const DividerLine = styled.div`
  width: 2px;
  height: 100%;
  background-color: #038cbb;
`;

const Corner = styled.div`
  width: 8px;
  height: 8px;
  background: url("/assets/icons/card-corner.svg") no-repeat;
  background-size: cover;
  position: absolute;
`;

const NameRow = styled.div`
  width: 92px;
  height: 18px;
  background: url("/assets/tutorial-name-bg.svg") no-repeat;
  position: absolute;
  bottom: -1px;
  left: 0px;
  background-size: cover;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.p`
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
`;

const Styles = {
  Container,
  Card,
  AvatarRow,
  DarkCorner,
  Grid,
  Button,
  ButtonIcon,
  DividerLine,
  Corner,
  NameRow,
  Name,
};

export default Styles;
