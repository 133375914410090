import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide, type SwiperClass } from "swiper/react";
import "swiper/css";
import { ReactSVG } from "react-svg";
import { useLocalStorage } from "usehooks-ts";
import cn from "classnames";

// Components
import Box from "./GameCharacterBox";
import Booster from "./GameCharacterBooster";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import type { TCharacter, TShopItem, TUser } from "@utils/api/types";

// Styles
import Styles from "./styles";
import { getFreeOffers } from "@utils/api";

interface Props {
  characters: TCharacter[];
  onOpenDayStreakDrawer: () => void;
  slideIndex: number;
  setSlideIndex: (index: number) => void;
  user: TUser;
  onClickOffer: () => void;
  onPlay: () => void;
  sessionId: string;
}

const GameCharacter: React.FC<Props> = (props) => {
  const {
    characters,
    onOpenDayStreakDrawer,
    slideIndex,
    setSlideIndex,
    user: { user_coefficient },
    onClickOffer,
    onPlay,
    sessionId,
  } = props;

  const [swiper, setSwiper] = React.useState<SwiperClass | null>(null);
  const [freeOffers, setFreeOffers] = React.useState<TShopItem[]>([]);

  const navigate = useNavigate();
  const { impactOccurred, initData } = useWebApp();
  const [isAnimateActive, setAnimateActive] = useLocalStorage<boolean>(
    "game-character-animate",
    true
  );

  React.useEffect(() => {
    onGetFreeOffers();
  }, []);

  const onGetFreeOffers = async (): Promise<void> => {
    setFreeOffers(await getFreeOffers(initData, sessionId));
  };

  const onPrev = (): void => {
    swiper?.slidePrev();
  };

  const onNext = (): void => {
    swiper?.slideNext();
  };

  const openPage = (to: string) => (): void => {
    navigate(to);
  };

  const onRealIndexChange = (swiper: SwiperClass): void => {
    setSlideIndex(swiper.realIndex);
    impactOccurred("heavy");
  };

  const onClickStats = (): void => {
    onOpenDayStreakDrawer();

    if (isAnimateActive) {
      setAnimateActive(false);
    }
  };

  const onViewFreePack = (): void => {
    if (!freeOffers.length) {
      return;
    }

    navigate("/shop-offer", {
      state: {
        item: freeOffers[0],
      },
    });
  };

  return (
    <Styles.Container>
      <Styles.Stats onClick={onClickStats}>
        <Styles.UpIcon src="/assets/icons/up.svg" alt="icon" />
        <Styles.StatsValue>
          {+Number(Number(user_coefficient * 100)).toFixed(0)}%
        </Styles.StatsValue>
        <ReactSVG
          src="/assets/icons/question2.svg"
          className={cn("question", {
            active: isAnimateActive,
          })}
        />
      </Styles.Stats>
      {/* <Styles.BoxesList>
        <Box name="Armor" value={0} image="/assets/items/armor.png" disabled />
        <Box
          name="Speed-up"
          value={0}
          image="/assets/items/speedup.png"
          disabled
        />
        <Box
          name="Relive"
          value={0}
          image="/assets/items/relive.png"
          disabled
        />
      </Styles.BoxesList> */}
      {/* <Box
        name="Lootbox"
        value={0}
        className="bottom-left"
        image="/assets/items/lootbox.png"
        disabled
      /> */}
      <Styles.Slider>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          loop={characters.length > 1}
          onRealIndexChange={onRealIndexChange}
          observer
          observeParents
          parallax
        >
          {characters.map((character, characterIndex) => {
            const { name, image_url } = character.skin;

            return (
              <SwiperSlide key={`${name}/${characterIndex}`}>
                <Styles.Image src={image_url} alt="character" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Styles.Slider>
      {characters.length ? (
        <Styles.Paginate>
          {characters.length > 1 ? (
            <Styles.PaginateArrow
              src="/assets/icons/paginate-arrow.svg"
              alt="arrow"
              onClick={onPrev}
            />
          ) : null}
          <Styles.PaginateValue>
            {characters[slideIndex].skin.name}
          </Styles.PaginateValue>
          {characters.length > 1 ? (
            <Styles.PaginateArrow
              src="/assets/icons/paginate-arrow.svg"
              alt="arrow"
              onClick={onNext}
            />
          ) : null}
        </Styles.Paginate>
      ) : null}
      <Styles.AirdropIcon
        src="/assets/icons/airdrop-flying-drone.svg"
        alt="airdrop"
        onClick={openPage("/airdrop")}
      />
      <Styles.PlayBooster
        src="/assets/boosters/play-booster.png"
        alt="play"
        onClick={onPlay}
      />
      {/* <Styles.OfferBooster
        src="/assets/boosters/offer-booster.png"
        alt="offer"
        onClick={onClickOffer}
      />
      <Styles.PlayBooster
        src="/assets/boosters/play-booster.png"
        alt="play"
        onClick={onPlay}
      /> */}
      {/* <Styles.NewYearRow onClick={openPage("/shop")}>
        <Styles.TreeImage src="/assets/christmas-tree.png" alt="tree" />
        <Styles.Glow src="/assets/offer-glow.png" alt="glow" />
      </Styles.NewYearRow> */}

      <Styles.Boosters>
        {freeOffers.length ? (
          <Booster
            onClick={onViewFreePack}
            image="/assets/free-pack.png"
            withGlow
            background="/assets/boosters/free-pack-booster.png"
          />
        ) : null}
        <Booster
          onClick={onClickOffer}
          image="/assets/boosters/offer-booster.png"
        />
        <Booster
          onClick={openPage("/shop")}
          image="/assets/christmas-tree.png"
          withGlow
          background="/assets/boosters/new-year-booster.png"
        />
      </Styles.Boosters>
    </Styles.Container>
  );
};

export default GameCharacter;
