import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Package from "./BoostRatingPackage";
import Spinner from "@components/Spinner";

// Store
import { useAppSelector } from "@store/index";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { getRouletteTickets } from "@utils/api";
import type { TRouletteTicket } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccessPaid: () => void;
  onFailedPaid: () => void;
}

const BuyTicketsSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, onSuccessPaid, onFailedPaid } = props;

  const [tickets, setTickets] = React.useState<TRouletteTicket[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { initData, openInvoice } = useWebApp();

  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    onGetPackages();
  }, []);

  const onGetPackages = async (): Promise<void> => {
    setLoading(true);
    setTickets(await getRouletteTickets(initData, sessionId));
    setLoading(false);
  };

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose} snapPoints={[-50]}>
      <Styles.Title>
        buy <Styles.TitleYellow>tickets</Styles.TitleYellow>
      </Styles.Title>
      {isLoading ? (
        <Styles.SpinnerRow>
          <Spinner size={40} />
        </Styles.SpinnerRow>
      ) : null}
      {!isLoading && tickets.length && sessionId ? (
        <Styles.PackagesList>
          {tickets.map((ticket) => (
            <Package
              key={`${ticket.id}/${ticket.title}`}
              data={ticket}
              initData={initData}
              sessionId={sessionId}
              openInvoice={openInvoice}
              onSuccessPaid={onSuccessPaid}
              onFailedPaid={onFailedPaid}
            />
          ))}
        </Styles.PackagesList>
      ) : null}
    </SheetWrapper>
  );
};

export default BuyTicketsSheet;
