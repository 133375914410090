const tabs = [
  { title: "Game", icon: "game.svg", path: "/game" },
  { title: "Shop", icon: "shop-cart.svg", path: "/shop" },
  { title: "Spinner", icon: "spin.svg", path: "/spinner" },
  { title: "Earn", icon: "earn.svg", path: "/tasks" },
  { title: "Leader", icon: "leaderboard.svg", path: "/leaderboard" },
  { title: "Referral", icon: "referal.svg", path: "/referal" },
];

export default tabs;
