import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: url("/assets/main-background.png") no-repeat;
  background-size: cover;
  background-position: center -100px;
`;

const Styles = {
  Container,
};

export default Styles;
