import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const NewsSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  const onOpenTelegram = (): void => {
    //
  };

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>News</Styles.Title>
      <Styles.Description>
        Come to our telegram channel, we have prepared news for you
      </Styles.Description>
      <Styles.Actions>
        <Button title="Go to Telegram" onClick={onOpenTelegram} />
        <Button title="Close" variant="notfilled" onClick={onClose} />
      </Styles.Actions>
    </SheetWrapper>
  );
};

export default NewsSheet;
