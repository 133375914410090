import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import telegramAnalytics from "@telegram-apps/analytics";

// Components
import SplashScreen from "@components/SplashScreen";
import BottomNavigation from "@components/BottomNavigation";
import ErrorBoundary from "@components/ErrorBoundary";

// Config
import { IS_DEV } from "@config/consts";
import tabs from "@config/tabs";

const Root: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!IS_DEV) {
      navigate("/", { replace: true });
    }

    bootstrap();
  }, []);

  const bootstrap = (): void => {
    dayjs.extend(utc);

    if (!IS_DEV) {
      telegramAnalytics.init({
        token:
          "eyJhcHBfbmFtZSI6ImNyeXB0b3J1c2hmdW5fYm90IiwiYXBwX3VybCI6Imh0dHBzOi8vdC5tZS9jcnlwdG9ydXNoZnVuX2JvdCIsImFwcF9kb21haW4iOiJodHRwczovL3dlYmFwcC5jcnlwdG9ydXNoLmZ1bi8ifQ==!nZ5ubBRg0HiMP5ZGyudN0uJIjHpjV1bpTdl7TXe85zM=",
        appName: "cryptorushfun_bot",
      });
    }
  };

  return (
    <SplashScreen>
      <ErrorBoundary>
        <Outlet />
        {tabs.find((tab) => tab.path === pathname) ? (
          <BottomNavigation
            variant={pathname === "/game" ? "black" : undefined}
          />
        ) : null}
      </ErrorBoundary>
    </SplashScreen>
  );
};

export default Root;
