import styled, { keyframes } from "styled-components";

const tiltShaking = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background: url("/assets/background.png") no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 100%;
  overflow-x: hidden;
`;

const CryptoBox = styled.img`
  height: 150px;
  margin: 28px 0 0 0;
`;

const Bottom = styled.div`
  position: fixed;
  border-radius: 30px 30px 0 0;
  background-color: #1c1d1f;
  width: 100%;
  bottom: 0;
  padding: 30px 20px;
  padding-bottom: calc(30px + calc(100vh - var(--tg-viewport-height, 100vh)));
`;

const Lootbox = styled.img`
  height: 300px;
  margin: 0 auto;

  &.shaking {
    animation: ${tiltShaking} 0.25s linear infinite;
  }
`;

const RouletteRow = styled.div``;

const Styles = {
  Container,
  CryptoBox,
  Bottom,
  Lootbox,
  RouletteRow,
};

export default Styles;
