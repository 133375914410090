import * as React from "react";

// Utils
import type { Direction } from "@utils/game";

// Styles
import Styles from "./styles";

interface Props {
  direction: Direction;
}

const TutorialMoveHand: React.FC<Props> = (props) => {
  const { direction } = props;

  return (
    <Styles.Container className={direction}>
      <Styles.Arrow
        src="/assets/icons/tutorial-arrow.svg"
        alt="arrow"
        className="arrow"
      />
      <Styles.Hand
        src="/assets/icons/cursor-hand.svg"
        alt="hand"
        className="hand"
      />
    </Styles.Container>
  );
};

export default TutorialMoveHand;
