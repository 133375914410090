import styled from "styled-components";

const Title = styled.p`
  color: #fff;
  font-family: "Stapel_Bold";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  color: #c7c7cc;
  font-size: 20px;
  font-weight: 300;
`;

const Actions = styled.div`
  margin: 30px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Button = styled.button`
  width: 100%;
  height: 60px;
  border: 2px solid #ffffff;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const ButtonPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonPriceLabel = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
`;

const ButtonPrice = styled.span`
  &.blue {
    color: #08c;
  }

  &.yellow {
    color: #fc0;
  }
`;

const ButtonCurrencyIcon = styled.img`
  width: 22px;
  height: 20px;
`;

const ButtonCurrency = styled.p`
  color: #e5e5ea;
  text-align: center;
  font-family: "Stapel-Light";
  font-size: 12px;
  font-weight: 300;
`;

const Styles = {
  Title,
  Description,
  Actions,
  Button,
  ButtonPriceRow,
  ButtonPriceLabel,
  ButtonPrice,
  ButtonCurrencyIcon,
  ButtonCurrency,
};

export default Styles;
