import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onPlay: () => void;
}

const LivesRestoredSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, onPlay } = props;

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>Your lives restored</Styles.Title>
      <Styles.Description>
        Your lives have been restored, you can continue the game.
      </Styles.Description>
      <Styles.Icon src="/assets/loving.png" alt="loving" />
      <Styles.Actions>
        <Button title="Play" onClick={onPlay} icon="/assets/icons/play.svg" />
        <Button title="Back" onClick={onClose} variant="notfilled" />
      </Styles.Actions>
    </SheetWrapper>
  );
};

export default LivesRestoredSheet;
