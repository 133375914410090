import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Spinner from "@components/Spinner";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  prices: {
    ton: number;
    stars: number;
  };
  loadingCurrency: "ton" | "stars" | null;
  onConfirmBuy: (currency: "ton" | "stars") => void;
}

const BuySheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, prices, loadingCurrency, onConfirmBuy } = props;

  const onClickButton = (currency: "ton" | "stars") => (): void => {
    onConfirmBuy(currency);
  };

  return (
    <SheetWrapper
      isOpen={isOpen}
      onClose={onClose}
      disableDrag={loadingCurrency !== null}
    >
      <Styles.Title>Payment</Styles.Title>
      <Styles.Description>
        Choose a convenient payment method
      </Styles.Description>
      <Styles.Actions>
        <Styles.Button
          onClick={onClickButton("ton")}
          disabled={loadingCurrency !== null}
        >
          {loadingCurrency === "ton" ? (
            <Spinner size={25} />
          ) : (
            <>
              <Styles.ButtonPriceRow>
                <Styles.ButtonPriceLabel>
                  Buy{" "}
                  <Styles.ButtonPrice className="blue">
                    {prices.ton}
                  </Styles.ButtonPrice>
                </Styles.ButtonPriceLabel>
                <Styles.ButtonCurrencyIcon
                  src="/assets/icons/ton.svg"
                  alt="ton"
                />
              </Styles.ButtonPriceRow>
              <Styles.ButtonCurrency>(TON)</Styles.ButtonCurrency>
            </>
          )}
        </Styles.Button>
        <Styles.Button
          onClick={onClickButton("stars")}
          disabled={loadingCurrency !== null}
        >
          {loadingCurrency === "stars" ? (
            <Spinner size={25} />
          ) : (
            <>
              <Styles.ButtonPriceRow>
                <Styles.ButtonPriceLabel>
                  Buy{" "}
                  <Styles.ButtonPrice className="yellow">
                    {prices.stars}
                  </Styles.ButtonPrice>
                </Styles.ButtonPriceLabel>
                <Styles.ButtonCurrencyIcon
                  src="/assets/icons/stars.svg"
                  alt="stars"
                />
              </Styles.ButtonPriceRow>
              <Styles.ButtonCurrency>(telegram stars)</Styles.ButtonCurrency>
            </>
          )}
        </Styles.Button>
      </Styles.Actions>
    </SheetWrapper>
  );
};

export default BuySheet;
