import * as React from "react";

// Components
import Spinner from "@components/Spinner";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Styles
import Styles from "./styles";

interface Props {
  title: string;
  onClick: () => void;
  variant?: "primary" | "outline" | "notfilled";
  icon?: string;
  isLoading?: boolean;
  disabled?: boolean;
  price?: number;
  yellowTitle?: string;
  rightIcon?: string;
  yellowFirst?: boolean;
}

const Button: React.FC<Props> = (props) => {
  const {
    title,
    onClick,
    variant = "primary",
    icon,
    isLoading,
    disabled,
    price,
    yellowTitle,
    rightIcon,
    yellowFirst,
  } = props;

  const { impactOccurred } = useWebApp();

  const handleClick = (): void => {
    impactOccurred("heavy");

    onClick();
  };

  return (
    <Styles.Container
      onClick={handleClick}
      className={variant}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <Spinner size={30} />
      ) : (
        <>
          {yellowTitle && yellowFirst ? (
            <Styles.YellowTitle>{yellowTitle}</Styles.YellowTitle>
          ) : null}
          {icon ? <Styles.Icon src={icon} alt="icon" /> : null}
          {title}
          {price ? (
            <Styles.PriceRow>
              <Styles.Price>{price}</Styles.Price>
              <Styles.PriceIcon
                src="/assets/icons/stars-yellow.svg"
                alt="stars"
              />
            </Styles.PriceRow>
          ) : null}
          {yellowTitle && !yellowFirst ? (
            <Styles.YellowTitle>{yellowTitle}</Styles.YellowTitle>
          ) : null}
          {rightIcon ? <Styles.Icon src={rightIcon} alt="icon" /> : null}
        </>
      )}
    </Styles.Container>
  );
};

export default Button;
