import * as React from "react";

// Store
import { useAppSelector } from "@store/index";

// Utils
import { abbreviateNumber } from "@utils/format";
import type { TCharacter } from "@utils/api/types";

// Styles
import Styles from "./styles";

const Profile: React.FC = () => {
  const [activeCharacted, setActiveCharacter] =
    React.useState<TCharacter | null>(null);

  const { balance, lives, user } = useAppSelector((state) => state.app);
  const characters = useAppSelector((state) => state.app.characters);

  React.useEffect(() => {
    onGetActiveCharacter();
  }, [characters]);

  const onGetActiveCharacter = (): void => {
    const getActiveCharacter = characters.find(
      (character) => character.is_active
    );

    setActiveCharacter(getActiveCharacter || null);
  };

  return (
    <Styles.Container>
      <Styles.Avatar>
        {activeCharacted ? (
          <Styles.AvatarImage
            src={activeCharacted.skin.avatar_url}
            alt="avatar"
          />
        ) : null}
      </Styles.Avatar>
      <Styles.Profile>
        <Styles.Username>{user?.user_name || user?.first_name}</Styles.Username>
        <Styles.ProfileLabel>Profile</Styles.ProfileLabel>
      </Styles.Profile>
      <Styles.Stats>
        <Styles.CoinIcon src="/assets/cr-coin.png" alt="coin" />
        <Styles.Balance>
          {abbreviateNumber(balance?.balance || 0)}{" "}
          <Styles.BalanceSymbol>$CP</Styles.BalanceSymbol>
        </Styles.Balance>
        <Styles.LiveIcon src="/assets/icons/lives-active.svg" alt="live" />
        <Styles.Lives>X{lives}</Styles.Lives>
      </Styles.Stats>
    </Styles.Container>
  );
};

export default Profile;
