import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  text-transform: uppercase;
`;

const QrCodeRow = styled.p`
  margin: 60px 0;
  display: flex;
  justify-content: center;
`;

const ButtonRow = styled.div`
  padding-bottom: calc(10px + calc(100vh - var(--tg-viewport-height, 100vh)));
`;

const Styles = {
  Title,
  QrCodeRow,
  ButtonRow,
};

export default Styles;
