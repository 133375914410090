import WebApp from "@twa-dev/sdk";

// Config
import { IS_DEV } from "@config/consts";

const MOCK_INIT_DATA = `query_id=AAEGQ2QEAAAAAAZDZATV0SAP&user=%7B%22id%22%3A73679622%2C%22first_name%22%3A%22Alexandr%22%2C%22last_name%22%3A%22Voroncov%22%2C%22username%22%3A%22voroncov%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FjUrtua7Rf8DRGEbkbsvv4QYE52P-lq5nbPgN0HLOJYY.svg%22%7D&auth_date=1737963456&signature=Lv7KeUiCOOJd1LYLL0h2gI9iSVZRfPv7KKC8dkSsbvFpgSsQGTavVXubhOzQFHARyXASoe6I2LAfPbDdJBjqAw&hash=6cdfda82ce8ad2e38ca93b59e508a11f8199785ce7f4d45641c07720cee38972`;

const useWebApp = () => {
  const impactOccurred = (
    style: "light" | "medium" | "heavy" | "rigid" | "soft"
  ): void => {
    if (!IS_DEV) {
      WebApp.HapticFeedback.impactOccurred(style);
    }
  };

  const initWebApp = (): void => {
    if (!IS_DEV) {
      WebApp.expand();
      WebApp.disableVerticalSwipes();
      WebApp.setBottomBarColor("#000000");
      WebApp.setHeaderColor("#000000");
      WebApp.ready();
    }
  };

  return {
    impactOccurred,
    initData: IS_DEV ? MOCK_INIT_DATA : WebApp.initData,
    expand: WebApp.expand,
    initWebApp,
    openInvoice: WebApp.openInvoice,
    openTelegramLink: WebApp.openTelegramLink,
    openLink: WebApp.openLink,
  };
};

export default useWebApp;
