import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  overflow: hidden;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 6vw;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre-line;
  text-align: center;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const IconRow = styled.div`
  margin: 32px 0;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Icon = styled.img`
  width: 150px;
  height: 150px;
  z-index: 2;
`;

const Glow = styled.img`
  width: 356px;
  height: 356px;
  top: -100px;
  position: absolute;
  animation: ${rotating} 5s infinite linear;
`;

const Description = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Medium";
  font-size: 20px;
  font-weight: 500;
`;

const Rewards = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &.disabled {
    opacity: 0.5;
  }
`;

const Reward = styled.div`
  flex: 1;
  border-radius: 10px;
  background: rgba(255, 204, 0, 0.15);
  backdrop-filter: blur(5px);
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonRow = styled.div`
  margin: 30px 0 20px 0;
`;

const RewardIcon = styled.img`
  width: 62px;
  height: 62px;
`;

const RewardRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const RewardValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 5vw;
  font-weight: 700;
  line-height: 85%;
  text-transform: uppercase;
`;

const RewardLabel = styled.p`
  color: #c7c7cc;
  font-size: 3vw;
  font-weight: 300;
  line-height: 85%;
  text-transform: uppercase;
`;

const SpinnerRow = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;
`;

const TitleBlue = styled.span`
  color: #04bbf9;
`;

const TitleRed = styled.span`
  color: #ff3b30;
`;

const Styles = {
  Container,
  Title,
  TitleYellow,
  IconRow,
  Icon,
  Glow,
  Description,
  Rewards,
  Reward,
  ButtonRow,
  RewardIcon,
  RewardRow,
  RewardValue,
  RewardLabel,
  SpinnerRow,
  TitleBlue,
  TitleRed,
};

export default Styles;
