import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 18px 10px
    calc(66px + 10px + calc(100vh - var(--tg-viewport-height, 100vh))) 10px;
  background-color: #000000;
  border-radius: 30px 30px 0 0;
  background-image: url("/assets/mining-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`;

const Row = styled.div`
  /* border: 0.5px solid #fc0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 16px 10px 28px 10px;
  margin: 0 0 25px 0;
`;

const PercentProgress = styled.p`
  background-color: #000000;
  padding: 0 10px;
  color: #fc0;
  font-family: "Stapel_Bold";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  position: absolute;
  top: -10px;
`;

const ProgressLineRow = styled.div`
  width: 100%;
  height: 10px;
  background-color: rgb(113 81 0 / 25%);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProgressLinePattern = styled.div`
  height: 10px;

  &.side {
    width: 20px;
  }

  &:not(.side) {
    flex: 1;
  }

  &.active {
    background: url("/assets/auto-farm-progress-pattern.svg");
  }
`;

const ProgressLineBlockRow = styled.div`
  width: 20px;
  height: 10px;
  display: flex;
  justify-content: center;
`;

const ProgressLineBlock = styled.div`
  width: 10px;
  height: 10px;
  border: 1px solid #fc0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressLineBlockDot = styled.div`
  width: 2px;
  height: 2px;
  background-color: #fc0;
`;

const Levels = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Level = styled.div`
  flex: 1;
  height: 80px;
  border-radius: 2px;
  border: 1px solid #fc0;
  display: flex;
  flex-direction: column;

  &:not(.active) {
    transform: scale(0.85);
    opacity: 0.5;
  }
`;

const LevelHeading = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #fc0;
`;

const LevelTitle = styled.p`
  text-align: center;
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 2.5vw;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  flex: 1;
`;

const LevelDayRow = styled.div`
  width: 24px;
  height: 24px;
  border-left: 1px solid #fc0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LevelDay = styled.div`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 3vw;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
`;

const LevelBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: absolute;
  bottom: -25px;
  padding: 0 14px;
  /* background-color: #000000; */
  width: calc(100% - 20px);
  z-index: 2;

  button {
    height: 50px;

    &:first-child {
      flex: 1;
    }
  }
`;

const FarmButton = styled.button`
  width: 90px;
  border-radius: 8px;
  border: 1px solid #fc0;
  background-color: #fc0;
  color: #000000;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Stapel_Medium";
  letter-spacing: 2.1px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active:not(:disabled) {
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const LevelRewardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const LevelReward = styled.p`
  color: #fc0;
  font-family: "DS-Digital", sans-serif;
  font-size: 3.5vw;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;

  &.blue {
    color: #04bbf9;
  }
`;

const LevelRewardLabel = styled.p`
  color: #c7c7cc;
  font-family: "DS-Digital", sans-serif;
  font-size: 3.5vw;
  font-weight: 400;
  line-height: 16px;
`;

const CompleteRow = styled.div`
  width: 100%;
  margin: 26px 0;
  height: 60px;
  border-radius: 1.5px;
  border: 0.75px solid #fc0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CompleteText = styled.p`
  text-align: center;
  white-space: pre-line;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
`;

const CompleteTextTimer = styled.span`
  font-family: "Stapel_Bold";
`;

const LevelCheckIcon = styled.img`
  width: 10px;
  height: 10px;
`;

const CountdownRow = styled.div`
  flex: 1;
  border-radius: 8px;
  border: 1px solid #fc0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

const MiningRow = styled.div`
  flex: 1;
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(90deg, #0992fd 0%, #fc35de 92.53%);
  position: relative;
  overflow: hidden;

  span {
    color: #ebebeb;
    font-family: "Stapel_Medium";
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const MiningRowOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  opacity: 0.5;
  border: 3px solid #bd52fd;
  border-radius: 10px;
`;

const MiningProgress = styled.div`
  border-radius: 10px 0px 0px 10px;
  background: linear-gradient(90deg, #0992fd 0%, #fc35de 92.53%);
  height: 100%;
  z-index: 2;
  position: relative;
  /* min-width: 150px; */
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

const MiningProgressIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const MiningProgressValue = styled.p`
  color: #fc0;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const MiningProgressSymbol = styled.p`
  color: #c7c7cc;
  font-family: "Stapel-Light";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const MiningIconRow = styled.div`
  width: 225px;
  height: 225px;
  margin: -82px 0 -30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiningIcon = styled.img`
  width: 225px;
  height: 225px;
  z-index: 2;
`;

const Glow = styled.img`
  width: 100%;
  position: absolute;
  animation: ${rotating} 5s infinite linear;
`;

const Styles = {
  Container,
  Row,
  PercentProgress,
  ProgressLinePattern,
  ProgressLineRow,
  ProgressLineBlockRow,
  ProgressLineBlock,
  ProgressLineBlockDot,
  Levels,
  Level,
  LevelHeading,
  LevelTitle,
  LevelDayRow,
  LevelDay,
  LevelBody,
  Actions,
  FarmButton,
  LevelRewardRow,
  LevelReward,
  LevelRewardLabel,
  CompleteRow,
  CompleteText,
  CompleteTextTimer,
  LevelCheckIcon,
  CountdownRow,
  MiningRow,
  MiningRowOverlay,
  MiningProgress,
  MiningProgressIcon,
  MiningProgressValue,
  MiningProgressSymbol,
  MiningIconRow,
  MiningIcon,
  Glow,
};

export default Styles;
