import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  border-radius: 25px 25px 0px 0px;
  background-color: #1c1d1f;
  padding: 30px 20px 0 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(-66px - calc(100vh - var(--tg-viewport-height, 100vh)));
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #c7c7cc;
  font-size: 20px;
  font-weight: 300;
`;

const DescriptionBold = styled.span`
  font-family: "Stapel_Bold";
  font-weight: 700;
`;

const Tabs = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  overflow-x: scroll;
  width: calc(100vw - 40px);
`;

const Tab = styled.button`
  flex: 1;
  padding: 0 15px;
  border-radius: 6px;
  border: none;
  background: linear-gradient(90deg, #0992fd -39.22%, #fc35de 77.81%);
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Expanded_Bold";
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;

  &:not(.active) {
    opacity: 0.5;
    background: transparent;
    border: 1px solid #ffffff;
  }
`;

const SpinnerRow = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
`;

const Groups = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  flex: 1;
  padding-bottom: calc(
    66px + 20px + calc(100vh - var(--tg-viewport-height, 100vh))
  );
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GroupTitle = styled.p`
  color: #d1d1d6;
  font-family: "Stapel_Medium";
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Title,
  Description,
  DescriptionBold,
  Tabs,
  Tab,
  SpinnerRow,
  Groups,
  Group,
  GroupTitle,
};

export default Styles;
