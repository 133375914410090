import * as React from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

// Components
import Wrapper from "@components/Wrapper";
import ShopItem from "@components/ShopItem";
import Spinner from "@components/Spinner";
import ShopSelectedCard from "@components/ShopSelectedCard";
import ShopSpecialCard from "@components/ShopSpecialCard";

// Sheets
import PaymentApprovedSheet from "@sheets/PaymentApproved";
import PaymentNotApprovedSheet from "@sheets/PaymentNotApproved";
import SkinInfoSheet from "@sheets/SkinInfo";

// Store
import { useGetTagsQuery, useGetItemsQuery } from "@store/services/shopApi";
import { useAppDispatch, useAppSelector } from "@store/index";
import {
  addNewCharacter,
  setActiveCharacter,
  setBalance,
  setCharacters,
  setUser,
} from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import {
  buyItem,
  getBalance,
  getCharacters,
  getUserInfo,
  setCharacter,
  updateActiveCharacter,
} from "@utils/api";
import type { TShopItem, TSkin } from "@utils/api/types";

// Styles
import Styles from "./styles";

const ShopPage: React.FC = () => {
  const [activeSheet, setActiveSheet] = React.useState<
    "approved" | "not-approved" | "skin-info" | null
  >(null);
  const [activeTag, setActiveTag] = React.useState<string | null>(null);
  const [selectedItem, setSelectedItem] = React.useState<TShopItem | null>(
    null
  );
  const [isBuyLoading, setBuyLoading] = React.useState<boolean>(false);

  const { impactOccurred, openInvoice, initData } = useWebApp();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const characters = useAppSelector((state) => state.app.characters);
  const sessionId = useAppSelector((state) => state.app.sessionId);

  const { data: tags } = useGetTagsQuery({ initData, sessionId });
  const {
    data: items,
    isFetching: isLoading,
    refetch,
  } = useGetItemsQuery(
    { initData, tag: activeTag as string, sessionId },
    { skip: activeTag === null }
  );

  const [isAnimateActive, setAnimateActive] = useLocalStorage<boolean>(
    "shop-animate",
    true
  );

  React.useEffect(() => {
    if (tags?.length && !activeTag) {
      setActiveTag(tags[0].tag);
    }
  }, [tags, activeTag]);

  React.useEffect(() => {
    if (items?.length) {
      setSelectedItem(items[0]);
    }
  }, [items]);

  const onClickTag = (tag: string) => (): void => {
    setActiveTag(tag);
  };

  const onCloseSheet = (): void => {
    setActiveSheet(null);
  };

  const updateItemPaid = async (characterId: number): Promise<void> => {
    if (!sessionId) {
      return;
    }

    refetch();

    if (selectedItem) {
      setSelectedItem((prev) => {
        if (prev) {
          return {
            ...prev,
            isPaid: true,
          };
        }
        return prev;
      });
    }

    dispatch(setCharacters(await getCharacters(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
    dispatch(setUser(await getUserInfo(initData)));
    dispatch(setActiveCharacter(characterId));

    await updateActiveCharacter(initData, characterId, sessionId);
  };

  const onBuy = async (): Promise<void> => {
    if (!initData || !selectedItem || !sessionId || !selectedItem?.skin) {
      return;
    }

    impactOccurred("heavy");
    setBuyLoading(true);

    if (!Number(selectedItem.price) || selectedItem.is_paid) {
      const findCharacter = characters.find(
        (character) => character.skin.id === selectedItem.skin?.id
      );

      if (!findCharacter) {
        const newCharacter = await setCharacter(
          initData,
          selectedItem.skin.id,
          sessionId
        );

        if (newCharacter) {
          dispatch(addNewCharacter(newCharacter));
        }
      }

      if (findCharacter && !findCharacter.is_active) {
        dispatch(setActiveCharacter(findCharacter.id));

        await updateActiveCharacter(initData, findCharacter.id, sessionId);
      }

      navigate("/game");
    } else {
      const invoiceUrl = await buyItem(initData, selectedItem.id, sessionId);

      if (invoiceUrl) {
        openInvoice(invoiceUrl, (status) => {
          if (status === "paid") {
            setActiveSheet("approved");
            updateItemPaid(selectedItem.id);
          } else if (status === "failed") {
            setActiveSheet("not-approved");
          }
        });
      }
    }

    setBuyLoading(false);
  };

  const onClickItem = (item: TShopItem) => (): void => {
    setSelectedItem(item);
  };

  const onShowInfoSheet = (): void => {
    if (selectedItem && Number(selectedItem.price)) {
      setActiveSheet("skin-info");

      if (isAnimateActive) {
        setAnimateActive(false);
      }
    }
  };

  const onViewInfo = (item: TShopItem) => (): void => {
    navigate("/shop-offer", {
      state: {
        item: item,
      },
    });
  };

  return (
    <Wrapper withProfile withNavigation>
      <Styles.Container>
        {activeTag !== "special_offers" && selectedItem?.skin ? (
          <Styles.Cover>
            {!isLoading && selectedItem ? (
              <>
                <Styles.ItemImage
                  src={selectedItem.skin.image_url}
                  alt="item"
                />
                <ShopSelectedCard
                  selectedItem={selectedItem}
                  onBuy={onBuy}
                  isBuyLoading={isBuyLoading}
                  onShowSheet={onShowInfoSheet}
                  isAnimateActive={isAnimateActive}
                />
              </>
            ) : null}
          </Styles.Cover>
        ) : null}
        <Styles.Bottom
          className={cn({
            full: activeTag === "special_offers",
          })}
        >
          {tags?.length ? (
            <Styles.Categories>
              {tags
                .filter((tag) => tag.tag !== "lives")
                .map((item) => {
                  const { title, tag, isActive } = item;

                  return (
                    <Styles.Category
                      key={title}
                      className={cn(tag, {
                        active: tag === activeTag,
                      })}
                      onClick={onClickTag(tag)}
                      disabled={!isActive}
                    >
                      {title}
                    </Styles.Category>
                  );
                })}
            </Styles.Categories>
          ) : null}
          {isLoading || typeof items === "undefined" ? (
            <Styles.SpinnerRow>
              <Spinner size={40} />
            </Styles.SpinnerRow>
          ) : null}
          {!isLoading && items?.length && activeTag !== "special_offers" ? (
            <Styles.List>
              {items?.map((item) => {
                return (
                  <Styles.ListItem key={`${item.title}/${item.id}`}>
                    <ShopItem
                      title={(item.skin as TSkin).name}
                      image={(item.skin as TSkin).image_url}
                      isActive={item.id === selectedItem?.id}
                      onClick={onClickItem(item)}
                      price={item.price}
                      isPaid={item.is_paid}
                    />
                  </Styles.ListItem>
                );
              })}
            </Styles.List>
          ) : null}
          {activeTag === "special_offers" && !isLoading && items?.length ? (
            <Styles.SpecialList>
              {items.map((item) => (
                <ShopSpecialCard
                  key={`${item.title}/${item.id}`}
                  onViewInfo={onViewInfo(item)}
                  item={item}
                />
              ))}
            </Styles.SpecialList>
          ) : null}
        </Styles.Bottom>
      </Styles.Container>
      <PaymentApprovedSheet
        isOpen={activeSheet === "approved"}
        onClose={onCloseSheet}
      />
      <PaymentNotApprovedSheet
        isOpen={activeSheet === "not-approved"}
        onClose={onCloseSheet}
      />
      {selectedItem ? (
        <SkinInfoSheet
          isOpen={activeSheet === "skin-info"}
          onClose={onCloseSheet}
          selectedItem={selectedItem}
        />
      ) : null}
    </Wrapper>
  );
};

export default ShopPage;
