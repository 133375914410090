import * as React from "react";
import cn from "classnames";

// Styles
import Styles from "./styles";

interface Props {
  count: number;
  currentIndex: number;
  onPrev: () => void;
  onNext: () => void;
}

const Pagination: React.FC<Props> = (props) => {
  const { count, currentIndex, onPrev, onNext } = props;

  return (
    <Styles.Container>
      <Styles.Arrow
        src="/assets/icons/paginate-arrow.svg"
        alt="arrow"
        onClick={onPrev}
      />
      <Styles.Dots>
        {Array(count)
          .fill("dot")
          .map((i, index) => (
            <Styles.Dot
              key={`${i}/${index}`}
              className={cn({
                active: index === currentIndex,
              })}
            />
          ))}
      </Styles.Dots>
      <Styles.Arrow
        src="/assets/icons/paginate-arrow.svg"
        alt="arrow"
        onClick={onNext}
      />
    </Styles.Container>
  );
};

export default Pagination;
