import styled from "styled-components";

const Button = styled.button`
  position: absolute;
  width: 40px;
  height: 35px;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(9, 146, 253, 0.75) 0.75%,
    rgba(252, 53, 222, 0.75) 60.28%
  );
  border-radius: 0 100% 100% 0;
  bottom: 80px;
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
`;

const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const Styles = {
  Button,
  ButtonIcon,
};

export default Styles;
