import styled from "styled-components";

const Container = styled.div`
  padding: 0 20px 0 13px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: #000;
  gap: 22px;
`;

const Icon = styled.img`
  width: 35px;
  height: 35px;
`;

const Row = styled.div`
  flex: 1;
  margin: 0 8px 0 0;
  position: relative;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 500;
`;

const RewardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5px;
`;

const CoinIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const Reward = styled.p`
  color: #bd52fd;
  font-family: "DS-Digital", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
`;

const MaxValue = styled.span`
  color: #ffffff;
`;

const Symbol = styled.p`
  color: #c7c7cc;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 65px;
  height: 30px;
  background-color: #000000;
  border-radius: 6px;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 11px;

  &.active {
    border: 1px solid #34c759;
    background-color: #34c759;
  }

  .check {
    width: 18px;
    height: 18px;

    > div > svg {
      width: 18px;
      height: 18px;

      path {
        fill: #ffffff;
        stroke: #ffffff;
      }
    }
  }
`;

const Styles = {
  Container,
  Icon,
  Row,
  Title,
  RewardRow,
  CoinIcon,
  Reward,
  MaxValue,
  Symbol,
  Button,
};

export default Styles;
