import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const PackagesList = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Package = styled.div`
  width: 100%;
  height: 60px;
  background-color: #000000;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px 0 12px;
  gap: 12px;
`;

const PackageIcon = styled.img`
  width: 60px;
  height: 60px;
`;

const PackageRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PackageTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 500;
`;

const PackageRewardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const PackageRewardIcon = styled.img`
  width: 10px;
  height: 14px;
`;

const PackageReward = styled.p`
  color: #fc0;
  font-family: "DS-Digital", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
`;

const PackageRewardSymbol = styled.p`
  margin: 0 0 0 -2.5px;
  color: #c7c7cc;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const PricesRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
`;

const ActualPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Price = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 15px;
  font-weight: 500;
`;

const PriceIcon = styled.img`
  width: 14px;
  height: 14px;
`;

const OldPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  p {
    opacity: 0.5;
    font-size: 12px;
  }

  img {
    opacity: 0.5;
    width: 11px;
    height: 11px;
  }
`;

const PriceLine = styled.div`
  width: 36px;
  height: 2px;
  background-color: #3f3f3f;
  position: absolute;
`;

const SpinnerRow = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
`;

const Styles = {
  Title,
  TitleYellow,
  PackagesList,
  Package,
  PackageIcon,
  PackageRow,
  PackageTitle,
  PackageRewardRow,
  PackageRewardIcon,
  PackageReward,
  PackageRewardSymbol,
  PricesRow,
  ActualPriceRow,
  Price,
  PriceIcon,
  OldPrice,
  PriceLine,
  SpinnerRow,
};

export default Styles;
