import * as React from "react";
import cn from "classnames";
import { fromNano } from "@ton/core";

// Utils
import { getQuantity, getRotationDegrees, makeClassKey } from "./utils";
import { abbreviateNumber, formatPoints } from "@utils/format";
import type { TRouletteGift } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  gifts: TRouletteGift[];
  isSpinning: boolean;
  prizeNumber: number;
  onStopSpinning: () => void;
}

const START_SPINNING_TIME = 2600;
const CONTINUE_SPINNING_TIME = 750;
const STOP_SPINNING_TIME = 8000;
const DEFAULT_SPIN_DURATION = 1.0;

const STARTED_SPINNING = "started-spinning";

const RouletteWheel: React.FC<Props> = (props) => {
  const { gifts, isSpinning, prizeNumber, onStopSpinning } = props;

  const [isCurrentlySpinning, setIsCurrentlySpinning] =
    React.useState<boolean>(false);
  const [prizeMap, setPrizeMap] = React.useState<number[][]>([[0]]);
  const [finalRotationDegrees, setFinalRotationDegrees] =
    React.useState<number>(0);
  const [hasStoppedSpinning, setHasStoppedSpinning] =
    React.useState<boolean>(false);
  const [startRotationDegrees, setStartRotationDegrees] =
    React.useState<number>(0);
  const [hasStartedSpinning, setHasStartedSpinning] =
    React.useState<boolean>(false);
  const [isDataUpdated, setIsDataUpdated] = React.useState<boolean>(false);
  const mustStopSpinning = React.useRef<boolean>(false);

  const normalizedSpinDuration = Math.max(0.01, DEFAULT_SPIN_DURATION);
  const startSpinningTime = START_SPINNING_TIME * normalizedSpinDuration;
  const continueSpinningTime = CONTINUE_SPINNING_TIME * normalizedSpinDuration;
  const stopSpinningTime = STOP_SPINNING_TIME * normalizedSpinDuration;
  const totalSpinningTime =
    startSpinningTime + continueSpinningTime + stopSpinningTime;

  React.useEffect(() => {
    let initialMapNum = 0;
    const auxPrizeMap: number[][] = [];
    const dataLength = gifts?.length || 0;

    for (let i = 0; i < dataLength; i++) {
      auxPrizeMap.push([]);

      for (let j = 0; j < 1; j++) {
        auxPrizeMap[i][j] = initialMapNum++;
      }
    }

    setPrizeMap(auxPrizeMap);
    setIsDataUpdated(true);
  }, [gifts]);

  React.useEffect(() => {
    if (isSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();
      const selectedPrize =
        prizeMap[prizeNumber][
          Math.floor(Math.random() * prizeMap[prizeNumber]?.length)
        ];
      const finalRotationDegreesCalculated = getRotationDegrees(
        selectedPrize,
        getQuantity(prizeMap)
      );
      setFinalRotationDegrees(finalRotationDegreesCalculated);
    }
  }, [isSpinning]);

  React.useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false);
      setStartRotationDegrees(finalRotationDegrees);
    }
  }, [hasStoppedSpinning]);

  const startSpinning = () => {
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;

    setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false;
        setHasStartedSpinning(false);
        setHasStoppedSpinning(true);
        onStopSpinning();
      }
    }, totalSpinningTime);
  };

  const getPointsValue = (amount: number): string => {
    if (amount > 10_000) {
      return "points-3";
    } else if (amount > 2_000) {
      return "points-2";
    }
    return "points-1";
  };

  if (!gifts.length || !isDataUpdated) {
    return null;
  }

  return (
    <Styles.Container>
      <Styles.Point />
      <Styles.CenterDot />
      <Styles.Spinner
        className={cn("wheel", {
          [STARTED_SPINNING]: hasStartedSpinning,
        })}
        $classKey={makeClassKey(5)}
        $startSpinningTime={startSpinningTime}
        $continueSpinningTime={continueSpinningTime}
        $stopSpinningTime={stopSpinningTime}
        $startRotationDegrees={startRotationDegrees}
        $finalRotationDegrees={finalRotationDegrees}
        $disableInitialAnimation={false}
      >
        <Styles.Sections>
          {gifts.map((gift, giftIndex) => {
            const { id, type, amount } = gift;

            return (
              <React.Fragment key={`${id}/${type}`}>
                <Styles.SectionLine
                  style={{
                    rotate: `calc(360deg / ${gifts.length} * ${giftIndex} - 18deg)`,
                  }}
                />
                <Styles.Section
                  className={cn(type, {
                    even: giftIndex % 2 === 0,
                  })}
                  style={{
                    aspectRatio: `1 / calc(2 * tan(180deg / ${gifts.length}))`,
                    rotate: `calc(360deg / ${gifts.length} * ${giftIndex})`,
                  }}
                >
                  {type === "jackpot" ? (
                    <Styles.JackpotRow>
                      <Styles.JackpotLabel>jackpot</Styles.JackpotLabel>
                      <Styles.JackpotValue>
                        {formatPoints(amount)}{" "}
                        <Styles.JackpotValueSymbol>
                          $CP
                        </Styles.JackpotValueSymbol>
                      </Styles.JackpotValue>
                    </Styles.JackpotRow>
                  ) : (
                    <Styles.SectionValue
                      style={{
                        rotate: "-90deg",
                      }}
                    >
                      {type === "points"
                        ? Number(amount) > 100_000
                          ? abbreviateNumber(amount, 0)
                          : formatPoints(amount)
                        : null}
                      {type === "tickets" ? `X${amount}` : null}
                      {type === "ton" ? fromNano(amount) : null}
                    </Styles.SectionValue>
                  )}

                  {type === "points" || type === "tickets" || type === "ton" ? (
                    <Styles.SectionIcon
                      src={`/assets/wheel/${
                        type === "points" ? getPointsValue(amount) : type
                      }.png`}
                      alt="icon"
                    />
                  ) : null}
                </Styles.Section>
              </React.Fragment>
            );
          })}
        </Styles.Sections>
      </Styles.Spinner>
    </Styles.Container>
  );
};

export default RouletteWheel;
