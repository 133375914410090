import styled, { keyframes } from "styled-components";

const lineAnimated = keyframes`
  0% { width: 0px; }
  100% { width: 224px; }
`;

const Container = styled.div`
  width: 230px;
  height: 18px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    width: 9px;
    height: 18px;
    position: absolute;
    background: url("/assets/icons/progress-bar-arrow.svg") no-repeat;
    z-index: 3;
  }

  &::after {
    right: 0;
    transform: scale(-1, 1);
  }
`;

const BorderLine = styled.div`
  width: 212px;
  height: 3px;
  background-color: #ffffff;
  position: absolute;
  left: 9px;

  &:first-child {
    top: 0;
  }

  &:last-child {
    bottom: 0;
  }
`;

const Line = styled.div`
  width: 100px;
  height: 12px;
  position: absolute;
  background-color: #bd52fd;
  left: 3px;
  animation: ${lineAnimated} 2.5s forwards;
  z-index: 2;
`;

const Styles = {
  Container,
  BorderLine,
  Line,
};

export default Styles;
