import * as React from "react";

// Styles
import Styles from "./styles";

interface Props {
  name: string;
  image: string;
}

const Character: React.FC<Props> = (props) => {
  const { name, image } = props;

  return (
    <Styles.Container>
      <Styles.Name>{name}</Styles.Name>
      <Styles.Grid>
        <Styles.Image src={image} alt="image" />
      </Styles.Grid>
    </Styles.Container>
  );
};

export default Character;
