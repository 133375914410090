import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  flex: 1;
`;

const Title = styled.p`
  color: #ffffff;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Stapel_Expanded_Bold";
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  color: #aeaeb2;
  font-size: 20px;
  font-weight: 400;
  font-family: "Stapel-Light";
`;

const Slider = styled.div`
  margin: 30px -20px 20px -20px;
  width: 100vw;

  .swiper-slide {
    width: 192px;
    height: 368px;
    transition: transform 0.5s;
    background: url("/assets/character-background.svg") no-repeat;
    background-size: cover;

    &:not(.swiper-slide-active) {
      transform: scale(0.9);
    }
  }
`;

const Styles = {
  Container,
  Row,
  Title,
  Description,
  Slider,
};

export default Styles;
