import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: pre-line;
`;

const Icon = styled.img`
  width: 100%;
  height: 225px;
`;

const ButtonRow = styled.div`
  padding: 20px 0;
  width: 100%;
`;

const Styles = {
  Container,
  Title,
  Icon,
  ButtonRow,
};

export default Styles;
