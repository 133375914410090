import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin: 50px 0 0 0;
    width: calc(100% - 177px);
  }
`;

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Medium";
  font-size: 32px;
  font-weight: 400;
  white-space: pre-line;
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
`;

const Styles = {
  Container,
  Title,
  Description,
};

export default Styles;
