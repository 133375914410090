import styled, { keyframes } from "styled-components";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: fixed;
  height: 100%;
  background: #000000;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  margin: 80px 0 0 0;
  color: #fc0;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
`;

const RewardRow = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const RewardPlus = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 25px;
  font-weight: 500;
  line-height: 31px;
  text-transform: uppercase;
`;

const RewardIcon = styled.img`
  height: 28px;
`;

const Reward = styled.p`
  color: #fc0;
  font-family: "Stapel_Medium";
  font-size: 25px;
  font-weight: 500;
  line-height: 31px;
  text-transform: uppercase;
`;

const RewardSymbol = styled.p`
  margin: 0 0 0 -2.5px;
  color: #c7c7cc;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
`;

const PrizeRow = styled.div`
  flex: 1;
  aspect-ratio: 1 / 1;
  margin-bottom: 170px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Glow = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  animation: ${rotating} 5s infinite linear;
`;

const PrizeImage = styled.img`
  width: 75%;
  height: 75%;
  position: absolute;
  z-index: 2;
`;

const ButtonRow = styled.div`
  width: 100%;
  position: absolute;
  bottom: 50px;
  padding: 0 42px 30px 42px;
`;

const Styles = {
  Container,
  Title,
  RewardRow,
  RewardPlus,
  RewardIcon,
  Reward,
  RewardSymbol,
  PrizeRow,
  Glow,
  PrizeImage,
  ButtonRow,
};

export default Styles;
