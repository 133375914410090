import * as React from "react";
import { AppProvider, Stage } from "@pixi/react";
import { Application, Color } from "pixi.js";

interface Props {
  children: React.ReactNode;
}

const PixiProvider: React.FC<Props> = (props) => {
  const { children } = props;

  const appRef = React.useRef<Application | null>(null);

  if (!appRef.current) {
    appRef.current = new Application();
  }

  const onUnmount = (app: Application) => {
    if ("gl" in app.renderer) {
      (app.renderer.gl as WebGLRenderingContext)
        .getExtension("WEBGL_lose_context")
        ?.loseContext();
    }
  };

  return (
    <AppProvider value={appRef.current}>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        options={{
          background: new Color("#000000"),
        }}
        onUnmount={onUnmount}
      >
        {children}
      </Stage>
    </AppProvider>
  );
};

export default PixiProvider;
