import styled from "styled-components";

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  position: fixed;
  width: 100%;
  gap: 10px;
`;

const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 20px;
  border: 1px solid #04bbf9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/assets/avatar-bg.png") no-repeat;
  background-size: contain;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 30px;
  height: 30px;
`;

const Profile = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Username = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
`;

const ProfileLabel = styled.p`
  color: #aeaeae;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.3px;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const CoinIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const Balance = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
`;

const BalanceSymbol = styled.span`
  color: #c7c7cc;
`;

const LiveIcon = styled.img`
  width: 22px;
  height: 20px;
`;

const Lives = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Avatar,
  AvatarImage,
  Profile,
  Username,
  ProfileLabel,
  Stats,
  CoinIcon,
  Balance,
  BalanceSymbol,
  LiveIcon,
  Lives,
};

export default Styles;
