import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Heading = styled.div`
  width: 100%;
  height: 30px;
  background: url("/assets/shop-header.svg") no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 0 0 0 12px;
  background-size: cover;
`;

const HeadingFigure = styled.div`
  width: 12px;
  height: 13px;
  background: url("/assets/shop-item-header-figure.svg") no-repeat;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.65px;
  text-transform: uppercase;
`;

const Union = styled.div`
  width: 13px;
  height: 13px;
  background: url("/assets/shop-item-union.svg") no-repeat;
  position: absolute;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: rotate(90deg);
  }
`;

const Body = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  background: url("/assets/character-grid.png") no-repeat;
  background-size: cover;
  background-position: center;
  border-bottom: none;

  &.active {
    border-color: #bd52fd;
  }
`;

const Image = styled(LazyLoadImage)`
  height: 90%;
`;

const Bottom = styled.div`
  height: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &.active > svg {
    path:nth-child(2),
    path:nth-child(3) {
      fill: #bd52fd;
    }
  }
`;

const Price = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  z-index: 2;
`;

const StarsIcon = styled.img`
  width: 12px;
  height: 12px;
  z-index: 2;
`;

const CheckIcon = styled.img`
  width: 12px;
  height: 12px;
  z-index: 2;
`;

const Styles = {
  Container,
  Heading,
  HeadingFigure,
  Title,
  Union,
  Body,
  Image,
  Bottom,
  Price,
  StarsIcon,
  CheckIcon,
};

export default Styles;
