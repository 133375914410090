import * as React from "react";
import cn from "classnames";
import { ReactSVG } from "react-svg";
import { useInterval } from "usehooks-ts";

// Components
import Spinner from "@components/Spinner";

// Utils
import type { TTask, TTaskStatus } from "@utils/api/types";
import { getTask } from "@utils/api";

// Styles
import Styles from "./styles";

interface Props {
  task: TTask;
  onStart: () => void;
  onClaim: () => void;
  initData: string;
  sessionId: string;
  resetTask: () => void;
}

const TaskCard: React.FC<Props> = (props) => {
  const {
    task: { status, icon_url, name, amount_reward, id },
    onStart,
    onClaim,
    initData,
    sessionId,
    resetTask,
  } = props;

  const [taskStatus, setTaskStatus] = React.useState<TTaskStatus | null>(null);

  useInterval(
    () => {
      onGetTaskInfo();
    },
    taskStatus !== "Validated" && status === "Processing" ? 5000 : null
  );

  const onGetTaskInfo = async (): Promise<void> => {
    const task = await getTask(initData, id, sessionId);

    if (task?.status === "Validated") {
      setTaskStatus("Validated");
    } else if (task?.status === "None") {
      resetTask();
      setTaskStatus(null);
    }
  };

  const onClickButton = (): void => {
    if (status === "None") {
      onStart();
    } else if (taskStatus === "Validated" || status === "Validated") {
      onClaim();
    }
  };

  return (
    <Styles.Container>
      <Styles.IconRow>
        <Styles.Icon src={icon_url} alt="icon" />
      </Styles.IconRow>
      <Styles.Row>
        <Styles.Name>{name}</Styles.Name>
        <Styles.RewardRow>
          <Styles.RewardIcon src="/assets/cr-coin.png" alt="reward" />
          <Styles.Reward>{amount_reward}</Styles.Reward>
          <Styles.RewardSymbol>$CP</Styles.RewardSymbol>
        </Styles.RewardRow>
      </Styles.Row>

      <Styles.Button
        onClick={onClickButton}
        disabled={status === "Done"}
        className={cn({
          active: status !== "None",
        })}
      >
        {(taskStatus === "Validated" && status !== "Done") ||
        status === "Validated" ? (
          "Claim"
        ) : (
          <>
            {status === "Done" ? (
              <ReactSVG src="/assets/icons/check.svg" className="check" />
            ) : null}
            {status === "None" ? "Start" : null}
            {status === "Processing" ? <Spinner size={16} /> : null}
          </>
        )}
      </Styles.Button>
    </Styles.Container>
  );
};

export default TaskCard;
