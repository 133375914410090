import * as React from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

// Components
import Wrapper from "@components/Wrapper";
import PixiProvider from "@components/PixiProvider";
import TutorialCard from "@components/TutorialCard";
import UserInterface from "@components/Game/UserInterface";
import PlayerScreen from "@components/Game/PlayerScreen";
import LevelMap from "@components/Game/LevelMap";
import Player from "@components/Game/Player";
import Spinner from "@components/Spinner";
import TutorialMoveHand from "@components/TutorialMoveHand";

// Modals
import SkipTutorialModal from "@modals/SkipTutorial";

import LevelCompletedModal from "@modals/LevelCompleted";

// Sheets
import GameOverSheet from "@sheets/GameOver";

// Store
import { useAppSelector } from "@store/index";

// Hooks
import useGame from "@hooks/useGame";

// Utils
import type { TSkin } from "@utils/api/types";
import type { Direction } from "@utils/game";

// Config
import tutorialLevel from "@config/tutorial-level";
import stepsCoords from "./stepsCoords";
import { DEFAULT_LIVES } from "@config/consts";

// Styles
import Styles from "./styles";

const TutorialPage: React.FC = () => {
  const [step, setStep] = React.useState<number>(0);
  const [extraModal, setExtraModal] = React.useState<"skip-tutorial" | null>(
    null
  );
  const [skin, setSkin] = React.useState<TSkin | null>(null);
  const [allowedDirection, setAllowedDirection] =
    React.useState<Direction>("LEFT");
  const [moveProgress, setMoveProgress] = React.useState<number>(0);
  const [isMoveDisabled, setMoveDisabled] = React.useState<boolean>(true);
  const [lives, setLives] = React.useState<number>(DEFAULT_LIVES);

  const onPlayerMove = (): void => {
    if (step !== 1) {
      return;
    }

    if (moveProgress === 0) {
      setAllowedDirection("UP");
    } else if (moveProgress === 1) {
      setAllowedDirection("RIGHT");
    } else if (moveProgress === 2) {
      setAllowedDirection("UP");
    } else if (moveProgress === 3) {
      setAllowedDirection("LEFT");
    }

    setMoveProgress((prev) => prev + 1);
  };

  const removeLive = (): void => {
    setLives((prev) => prev - 1);
  };

  const {
    containerRef,
    levelMap,
    isLevelMapLoading,
    gridWidth,
    gridHeight,
    cellSize,
    playerCoordinates,
    playerAngle,
    score,
    level,
    isLevelCompleted,
    screenAnimation,
    moveCoordinates,
    sprites,
    totalCoins,
    activeModal,
    updateEnemyPosition,
    onDamage,
    onCloseModal,
    onResetLevel,
  } = useGame({
    lives,
    skin,
    level: tutorialLevel,
    levelStyle: 2,
    isMoveDisabled,
    allowedDirection: step === 1 ? allowedDirection : undefined,
    onPlayerMove,
    removeLive,
  });

  const navigate = useNavigate();
  const characters = useAppSelector((state) => state.app.characters);

  React.useEffect(() => {
    if (!skin) {
      onGetSkin();
    }
  }, [skin, characters]);

  React.useEffect(() => {
    if (step === 1 && isMoveDisabled) {
      setMoveDisabled(false);
    } else if (step !== 1 && !isMoveDisabled) {
      setMoveDisabled(true);
    }
  }, [step]);

  React.useEffect(() => {
    const findCoords = stepsCoords.find(
      (i) =>
        i.x === playerCoordinates.x &&
        i.y === playerCoordinates.y &&
        i.step === step
    );

    if (findCoords) {
      setStep(findCoords.step + 1);
    }
  }, [playerCoordinates, step]);

  React.useEffect(() => {
    if (isLevelCompleted && step !== 7) {
      setStep(7);
    }
  }, [isLevelCompleted, step]);

  const onGetSkin = (): void => {
    const findActiveCharacter = characters.find(
      (character) => character.is_active
    );

    if (findActiveCharacter) {
      setSkin(findActiveCharacter.skin);
    }
  };

  const onClickCardButton = (): void => {
    if (step === 0) {
      setStep(1);
    } else {
      setMoveDisabled(false);
    }
  };

  const onCloseExtraModal = (): void => {
    setExtraModal(null);
  };

  const onConfirmSkip = (): void => {
    setExtraModal(null);

    navigate("/game", {
      replace: true,
    });
  };

  const onSkip = (): void => {
    setExtraModal("skip-tutorial");
  };

  return (
    <Wrapper>
      <Styles.Container
        ref={containerRef}
        className={cn({
          shake: screenAnimation,
        })}
      >
        {isLevelMapLoading || !sprites || !skin ? (
          <Styles.SpinnerRow>
            <Spinner size={40} />
          </Styles.SpinnerRow>
        ) : (
          <>
            <UserInterface
              title="Tutorial"
              score={score}
              level={level}
              lives={lives}
              isActive={activeModal === "level-completed"}
              totalCoins={totalCoins}
            />
            {step >= 2 && step !== 7 ? (
              <Styles.SkipButton onClick={onSkip}>
                Skip tutorial
              </Styles.SkipButton>
            ) : null}

            <Styles.Overlay
              className={cn({
                active: isMoveDisabled,
              })}
            >
              <PixiProvider>
                <PlayerScreen
                  rowPlayerPos={playerCoordinates.y / cellSize}
                  colPlayerPos={playerCoordinates.x / cellSize}
                  gridWidth={gridWidth}
                  gridHeight={gridHeight}
                  cellSize={cellSize}
                >
                  <LevelMap
                    levelMap={levelMap}
                    cellSize={cellSize}
                    updateEnemyPosition={updateEnemyPosition}
                    sprites={sprites}
                    playerCoordinates={playerCoordinates}
                    onDamage={onDamage}
                  />
                  <Player
                    width={cellSize}
                    height={cellSize}
                    playerCoordinates={playerCoordinates}
                    playerAngle={playerAngle}
                    moveCoordinates={moveCoordinates}
                    thumbnail={skin.thumbnail_url}
                  />
                </PlayerScreen>
              </PixiProvider>
            </Styles.Overlay>
            {step === 1 ? (
              <TutorialMoveHand direction={allowedDirection} />
            ) : null}
            {step === 2 ? (
              <Styles.PointsHandRow>
                <Styles.PointsHand
                  src="/assets/icons/cursor-hand.svg"
                  alt="hand"
                />
              </Styles.PointsHandRow>
            ) : null}
            {step === 3 ? (
              <Styles.LivesHand
                src="/assets/icons/cursor-hand.svg"
                alt="hand"
              />
            ) : null}
          </>
        )}
      </Styles.Container>
      <TutorialCard
        step={step}
        onClickButton={onClickCardButton}
        isActive={activeModal === "level-completed"}
      />
      <SkipTutorialModal
        isOpen={extraModal === "skip-tutorial"}
        onClose={onCloseExtraModal}
        onConfirmSkip={onConfirmSkip}
      />
      <GameOverSheet
        isOpen={activeModal === "game-over"}
        onClose={onCloseModal}
        lives={lives}
        onResetLevel={onResetLevel}
      />
      <LevelCompletedModal
        isOpen={activeModal === "level-completed"}
        onClose={onCloseModal}
        score={score}
        level={level}
        totalCoins={totalCoins}
        amount={5000}
        isTutorial
      />
    </Wrapper>
  );
};

export default TutorialPage;
