import styled from "styled-components";

type TSpinnerProps = {
  $classKey: string;
  $startSpinningTime: number;
  $continueSpinningTime: number;
  $stopSpinningTime: number;
  $startRotationDegrees: number;
  $finalRotationDegrees: number;
  $disableInitialAnimation: boolean;
};

const Container = styled.div`
  flex: 1;
  margin: 30px 0 0 0;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  backdrop-filter: blur(7.5px);
  background: url("/assets/roulette-wheel-bg.svg") no-repeat;
  background-size: cover;
  position: relative;
`;

const Point = styled.div`
  width: 43px;
  height: 60px;
  background: url("/assets/roulette-wheel-point.svg") no-repeat;
  background-size: cover;
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const CenterDot = styled.div`
  width: 25%;
  height: 25%;
  border-radius: 100%;
  background: linear-gradient(135deg, #0992fd 6.47%, #fc35de 93.02%), #04bbf9;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
`;

const Spinner = styled.div<TSpinnerProps>`
  all: unset;
  aspect-ratio: 1 / 1;
  container-type: inline-size;
  direction: ltr;
  display: grid;
  position: relative;
  width: 100%;
  transform: rotate(${(props) => props.$startRotationDegrees}deg);

  &.started-spinning {
    animation: spin-${({ $classKey }) => $classKey} ${({
          $startSpinningTime,
        }) => $startSpinningTime / 1000}s cubic-bezier(
          0.71,
          ${(props) => (props.$disableInitialAnimation ? 0 : -0.29)},
          0.96,
          0.9
        ) 0s 1 normal forwards running,
      continueSpin-${({ $classKey }) => $classKey} ${({
          $continueSpinningTime,
        }) => $continueSpinningTime / 1000}s linear ${({
          $startSpinningTime,
        }) => $startSpinningTime / 1000}s 1 normal forwards running,
      stopSpin-${({ $classKey }) => $classKey} ${({ $stopSpinningTime }) =>
          $stopSpinningTime / 1000}s cubic-bezier(0, 0, 0.35, 1.02) ${({
          $startSpinningTime,
          $continueSpinningTime,
        }) => ($startSpinningTime + $continueSpinningTime) / 1000}s 1 normal forwards
        running;
  }

  @keyframes spin-${({ $classKey }) => $classKey} {
    from {
      transform: rotate(${(props) => props.$startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.$startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin-${({ $classKey }) => $classKey} {
    from {
      transform: rotate(${(props) => props.$startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.$startRotationDegrees + 360}deg);
    }
  }
  @keyframes stopSpin-${({ $classKey }) => $classKey} {
    from {
      transform: rotate(${(props) => props.$startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => 1440 + props.$finalRotationDegrees}deg);
    }
  }
`;

const Sections = styled.div`
  all: unset;
  clip-path: inset(0 0 0 0 round 50%);
  display: grid;
  inset: 0;
  place-content: center start;
`;

const Section = styled.div`
  align-content: center;
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  display: grid;
  grid-area: 1 / -1;
  transform-origin: center right;
  user-select: none;
  width: 50cqi;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.65ch;
  background-color: rgba(24, 50, 86, 0.7);

  &.jackpot {
    background-color: #fc0;
  }
`;

const SectionValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 3.5cqi;
  font-weight: 700;
  text-transform: uppercase;
  width: 6ch;
  margin-left: -1ch;
  text-align: center;
`;

const SectionIcon = styled.img`
  width: 5ch;
  transform: rotate(270deg);
  margin-left: -0.5ch;
`;

const JackpotRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const JackpotLabel = styled.p`
  color: #000000;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 4.5cqi;
  font-weight: 700;
  letter-spacing: 0.816px;
  text-transform: uppercase;
`;

const JackpotValue = styled.p`
  color: #000000;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 3.5cqi;
  font-weight: 700;
  text-transform: uppercase;
`;

const JackpotValueSymbol = styled.span`
  color: #000000;
  font-family: "Stapel_Medium";
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;
`;

const SectionLine = styled.div`
  align-content: center;
  grid-area: 1 / -1;
  transform-origin: center right;
  user-select: none;
  width: 100%;
  height: 2px;
  background-color: #bd52fd;
  margin-top: 15.5cqi;
  z-index: 2;
`;

const Styles = {
  Container,
  Point,
  CenterDot,
  Spinner,
  Sections,
  Section,
  SectionValue,
  SectionIcon,
  JackpotRow,
  JackpotLabel,
  JackpotValue,
  JackpotValueSymbol,
  SectionLine,
};

export default Styles;
