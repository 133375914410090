import * as React from "react";

// Utils
import type { Direction } from "@utils/game";

const useTouch = <TElement extends HTMLElement>(
  ref: React.MutableRefObject<TElement | null>,
  onTouch: (direction: Direction) => void
) => {
  const [startPoint, setStartPoint] = React.useState<Touch | null>(null);
  const [endPoint, setEndPoint] = React.useState<Touch | null>(null);
  const [direction, setDirection] = React.useState<Direction | null>(null);

  React.useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      setStartPoint(e.touches[0]);
      setEndPoint(null);
    };
    const handleTouchMove = (e: TouchEvent) => {
      setEndPoint(e.touches[0]);
    };

    const target = ref?.current;
    if (!target) return;
    target.addEventListener("touchstart", handleTouchStart);
    target.addEventListener("touchmove", handleTouchMove);

    return () => {
      target.removeEventListener("touchstart", handleTouchStart);
      target.removeEventListener("touchmove", handleTouchMove);
    };
  }, [ref]);

  React.useEffect(() => {
    if (startPoint && endPoint) {
      const diffX = startPoint.clientX - endPoint.clientX;
      const diffY = startPoint.clientY - endPoint.clientY;

      let dir: Direction;
      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          dir = "LEFT";
        } else {
          dir = "RIGHT";
        }
      } else {
        if (diffY > 0) {
          dir = "UP";
        } else {
          dir = "DOWN";
        }
      }

      setDirection(dir);
    } else {
      setDirection(null);
    }
  }, [startPoint, endPoint]);

  React.useEffect(() => {
    if (direction) {
      onTouch(direction);
    }
  }, [direction]);
};

export default useTouch;
