import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Container = styled.div`
  flex: 1;
  background-color: #1c1d1f;
`;

const Cover = styled.div`
  height: 270px;
  background: url("/assets/shop-background.png") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px 0 20px;
  gap: 20px;
`;

const Bottom = styled.div`
  border-radius: 25px 25px 0px 0px;
  background-color: #1c1d1f;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 300px);

  &.full {
    height: calc(100vh - 50px);
  }
`;

const ItemImage = styled(LazyLoadImage)`
  height: 230px;
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
  white-space: nowrap;
`;

const Category = styled.button`
  padding: 0 18px;
  background-color: transparent;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 8px;
  border: 1px solid #ffffff;
  height: 40px;
  flex: 1;

  &:disabled {
    opacity: 0.5;
  }

  &.active {
    background: linear-gradient(90deg, #0992fd -39.22%, #fc35de 77.81%);
    border: none;

    &.special {
      background: #fc0;
      border: 1px solid #fc0;
      color: #000;
    }
  }

  &.special:not(.active) {
    background-color: transparent;
    border: 1px solid #fc0;
    color: #fc0;
  }
`;

const List = styled.div`
  margin: 10px -7.5px -10px -7.5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: calc(100vh - 436px - calc(100vh - var(--tg-viewport-height, 100vh)));
`;

const ListItem = styled.div`
  padding: 10px 7.5px;
  width: 33.33%;
`;

const SpinnerRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
`;

const SpecialList = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 206px - calc(100vh - var(--tg-viewport-height, 100vh)));
  padding-bottom: 30px;
  overflow-y: scroll;
`;

const Styles = {
  Container,
  Cover,
  Bottom,
  ItemImage,
  Categories,
  Category,
  List,
  ListItem,
  SpinnerRow,
  SpecialList,
};

export default Styles;
