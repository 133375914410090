import * as React from "react";
import cn from "classnames";
import { ReactSVG } from "react-svg";

// Styles
import Styles from "./styles";

interface Props {
  title: string;
  image: string;
  isActive: boolean;
  onClick: () => void;
  price: number;
  isPaid: boolean;
}

const ShopItem: React.FC<Props> = (props) => {
  const { title, image, isActive, onClick, price, isPaid } = props;

  return (
    <Styles.Container onClick={onClick}>
      <Styles.Heading>
        <Styles.HeadingFigure />
        <Styles.Title>{title}</Styles.Title>
      </Styles.Heading>
      <Styles.Union className="top-left" />
      <Styles.Union className="top-right" />
      <Styles.Body
        className={cn({
          active: isActive,
        })}
      >
        <Styles.Image src={image} alt="image" />
      </Styles.Body>
      <Styles.Bottom
        className={cn({
          active: isActive,
        })}
      >
        <ReactSVG src="/assets/shop-item-bottom.svg" wrapper="svg" />
        <Styles.Price>
          {isPaid ? "Bought" : Number(price) === 0 ? "Free" : price}
        </Styles.Price>
        {Number(price) > 0 && !isPaid ? (
          <Styles.StarsIcon src="/assets/icons/stars.svg" alt="stars" />
        ) : null}
        {isPaid ? (
          <Styles.CheckIcon src="/assets/icons/check.svg" alt="stars" />
        ) : null}
      </Styles.Bottom>
    </Styles.Container>
  );
};

export default ShopItem;
