import styled from "styled-components";
import { Sheet } from "react-modal-sheet";

const Container = styled(Sheet)`
  z-index: 999 !important;

  .react-modal-sheet-container {
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
    background-color: #1c1d1f !important;
    background-size: contain;
  }

  &.black {
    .react-modal-sheet-backdrop {
      background-color: rgba(24, 50, 86, 0.5) !important;
      filter: blur(2.5px);
    }

    .react-modal-sheet-container {
      background-color: #000000 !important;
      overflow: hidden !important;
    }
  }

  .react-modal-sheet-scroller {
    padding: 10px 20px;
  }

  &.hideBackdrop {
    .react-modal-sheet-backdrop {
      display: none !important;
    }
  }
`;

const Heading = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Handle = styled.div`
  width: 34px;
  height: 3px;
  border-radius: 9px;
  background-color: #bdbdbd;
`;

const Bottom = styled.div`
  border-radius: 30px 30px 0px 0px;
  background: linear-gradient(0deg, #3d3d3d 0%, #3d3d3d 100%),
    rgba(127, 127, 127, 0.5);
  background-blend-mode: overlay, luminosity;
  backdrop-filter: blur(7.5px);
  padding: 26px 20px;
`;

const Styles = {
  Container,
  Heading,
  Handle,
  Bottom,
};

export default Styles;
