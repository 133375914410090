import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  background-color: #1c1d1f;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  flex: 1;
  padding: 30px 20px 0 20px;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  color: #c7c7cc;
  font-size: 18px;
  font-weight: 400;
  white-space: pre-line;
`;

const DescriptionYellow = styled.span`
  color: #fc0;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 12px -8px -8px -8px;
  height: calc(100vh - 304px - calc(100vh - var(--tg-viewport-height, 100vh)));
  overflow-y: scroll;
  padding-bottom: 20px;
`;

const ListItem = styled.div`
  padding: 8px;
  width: 33.33%;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #bd52fd;
  background-color: #261937;
  background-image: url("/assets/icons/card-grid.svg");
  background-size: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Actions = styled.div`
  padding: 15px 20px calc(5px + calc(100vh - var(--tg-viewport-height, 100vh)))
    20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  background-color: rgba(28, 29, 31, 0.75);
  backdrop-filter: blur(12.5px);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
`;

const ActionButton = styled.button`
  flex: 1;
  height: 60px;
  border: 1px solid #ffffff;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    border: 1px solid #08c;
  }
`;

const CardHeading = styled.div`
  width: 100%;
  height: 34px;
  background: url("/assets/shop-carousel-heading.svg") no-repeat;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  z-index: 2;
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

const CardOverlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: radial-gradient(
    54.06% 52.3% at 50% 50%,
    rgba(16, 17, 26, 0) 60%,
    #10111a 100%
  );
  position: absolute;
`;

const CardBody = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardImage = styled.img`
  height: 170px;
`;

const ButtonPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const ButtonPriceLabel = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
`;

const ButtonPrice = styled.span`
  &.blue {
    color: #08c;
  }

  &.yellow {
    color: #fc0;
  }
`;

const ButtonCurrencyIcon = styled.img`
  width: 22px;
  height: 20px;
`;

const ButtonCurrency = styled.p`
  color: #e5e5ea;
  text-align: center;
  font-family: "Stapel-Light";
  font-size: 12px;
  font-weight: 300;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const Styles = {
  Container,
  Row,
  Title,
  Description,
  DescriptionYellow,
  List,
  ListItem,
  Card,
  Actions,
  ActionButton,
  CardHeading,
  CardTitle,
  CardOverlay,
  CardBody,
  CardImage,
  ButtonPriceRow,
  ButtonPriceLabel,
  ButtonPrice,
  ButtonCurrencyIcon,
  ButtonCurrency,
  TitleYellow,
};

export default Styles;
