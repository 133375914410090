import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/assets/avatar-bg.png") no-repeat;
  background-size: contain;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
`;

const Styles = {
  Container,
  Avatar,
};

export default Styles;
