import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Utils
import { formatPoints } from "@utils/format";
import type { TShopItem } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedItem: TShopItem;
}

const SkinInfoSheet: React.FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    selectedItem: { points, skin },
  } = props;

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Container>
        <Styles.Title>{skin?.name}</Styles.Title>
        <Styles.Groups>
          <Styles.Group>
            <Styles.GroupStats>
              <Styles.GroupStatsPlus>+</Styles.GroupStatsPlus>
              <Styles.GroupStatsIcon src="/assets/cr-coin.png" alt="icon" />
              <Styles.GroupStatsValue>
                {formatPoints(points)}
              </Styles.GroupStatsValue>
              <Styles.GroupStatsSymbol>$CP</Styles.GroupStatsSymbol>
            </Styles.GroupStats>
            <Styles.GroupDescription>
              Cryptopoints - reward for each purchased character, with no limit
              on the number of purchases
            </Styles.GroupDescription>
          </Styles.Group>
          <Styles.Group>
            <Styles.GroupStats>
              <Styles.GroupStatsPlus>+</Styles.GroupStatsPlus>
              <Styles.GroupStatsIcon src="/assets/icons/up.svg" alt="icon" />
              <Styles.GroupStatsValue>
                {Number(Number(skin?.skin_coef) * 100) - 100}%
              </Styles.GroupStatsValue>
            </Styles.GroupStats>
            <Styles.GroupDescription>
              Point booster:{"\n"}- Permanent and non-expiring{"\n"}- Can Stack
              with Multiple Point Boosters
            </Styles.GroupDescription>
          </Styles.Group>
        </Styles.Groups>
        <Styles.ButtonRow>
          <Button title="Close" onClick={onClose} variant="outline" />
        </Styles.ButtonRow>
      </Styles.Container>
    </SheetWrapper>
  );
};

export default SkinInfoSheet;
