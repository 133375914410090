import * as React from "react";
import { Sprite } from "@pixi/react";
import type { Resource, Texture } from "pixi.js";

interface Props {
  x: number;
  y: number;
  width: number;
  height: number;
  wallEmpty: Texture<Resource>;
  coinTexture: Texture<Resource>;
}

const CoinBlock: React.FC<Props> = (props) => {
  const { x, y, width, height, coinTexture, wallEmpty } = props;

  return (
    <>
      <Sprite texture={wallEmpty} x={x} y={y} width={width} height={height} />
      <Sprite texture={coinTexture} x={x} y={y} width={width} height={height} />
    </>
  );
};

export default CoinBlock;
