import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MaxLevelsCompletedSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <SheetWrapper
      isOpen={isOpen}
      onClose={onClose}
      bottom={<Button title="Back" onClick={onClose} variant="outline" />}
    >
      <Styles.Title>All levels done</Styles.Title>
      <Styles.Description>
        Return tomorrow to unlock fresh levels and continue your adventure!
      </Styles.Description>
      <Styles.Icon src="/assets/party.png" alt="party" />
    </SheetWrapper>
  );
};

export default MaxLevelsCompletedSheet;
