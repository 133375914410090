import * as React from "react";
import { Sprite } from "@pixi/react";
import { useInterval } from "usehooks-ts";
import type { Resource, Texture } from "pixi.js";

// Utils
import type { TLevelMapItem } from "@utils/api/types";
import type { Direction } from "@utils/game";

interface Props {
  x: number;
  y: number;
  name: string;
  width: number;
  height: number;
  cell: TLevelMapItem;
  map: TLevelMapItem[][];
  cellSize: number;
  updateEnemyPosition: (x: number, y: number, name: string) => void;
  wallEmpty: Texture<Resource>;
}

const isWayFreeInDirection = (cell: TLevelMapItem): boolean => {
  const { type } = cell;

  return (
    type === "empty" || type === "coin" || type === "enemy" || type === "player"
  );
};

const EnemyBlock: React.FC<Props> = (props) => {
  const {
    x,
    y,
    name,
    width,
    height,
    map,
    cellSize,
    updateEnemyPosition,
    wallEmpty,
  } = props;

  const [spriteX, setSpriteX] = React.useState<number>(x);
  const [spriteY, setSpriteY] = React.useState<number>(y);
  const [nextDirection, setNextDirection] = React.useState<Direction | null>(
    null
  );
  const [axis, setAxis] = React.useState<"x" | "y" | null>(null);

  React.useEffect(() => {
    updateEnemyPosition(spriteX, spriteY, name);
  }, [spriteX, spriteY]);

  React.useEffect(() => {
    if (!axis) {
      onCheckAxis();
    } else if (!nextDirection) {
      onGetNextDirection();
    }
  }, [axis, nextDirection]);

  useInterval(
    () => {
      const nextWallPosition =
        nextDirection === "UP" || nextDirection === "LEFT" ? -1 : 1;
      let nextWall;

      if (axis === "y") {
        nextWall = map[spriteY / cellSize + nextWallPosition][x / cellSize];
      } else {
        nextWall = map[y / cellSize][spriteX / cellSize + nextWallPosition];
      }

      if (isWayFreeInDirection(nextWall)) {
        if (axis === "y") {
          setSpriteY((prev) => prev + nextWallPosition * cellSize);
        } else {
          setSpriteX((prev) => prev + nextWallPosition * cellSize);
        }
      } else {
        setNextDirection((prev) => {
          if (axis === "y") {
            return prev === "DOWN" ? "UP" : "DOWN";
          }

          return prev === "RIGHT" ? "LEFT" : "RIGHT";
        });

        if (axis === "y") {
          setSpriteY((prev) => prev - nextWallPosition * cellSize);
        } else {
          setSpriteX((prev) => prev - nextWallPosition * cellSize);
        }
      }
    },
    nextDirection ? 500 : null
  );

  const onCheckAxis = (): void => {
    const isYAxisFree =
      isWayFreeInDirection(map[y / cellSize - 1][x / cellSize]) ||
      isWayFreeInDirection(map[y / cellSize + 1][x / cellSize]);

    setAxis(isYAxisFree ? "y" : "x");
  };

  const onGetNextDirection = (): void => {
    if (axis === "y") {
      const nextWall = map[y / cellSize + 1][x / cellSize];
      setNextDirection(nextWall.type === "empty" ? "DOWN" : "UP");
    } else {
      const nextWall = map[y / cellSize][x / cellSize + 1];
      setNextDirection(nextWall.type === "empty" ? "RIGHT" : "LEFT");
    }
  };

  return (
    <>
      <Sprite texture={wallEmpty} x={x} y={y} width={width} height={height} />
      <Sprite
        image="/assets/game/enemy_100.gif"
        x={spriteX}
        y={spriteY}
        width={width}
        height={height}
      />
    </>
  );
};

export default EnemyBlock;
