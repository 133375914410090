import * as React from "react";

// Styles
import Styles from "./styles";

interface Props {
  value: number;
}

const ProgressBar: React.FC<Props> = (props) => (
  <Styles.Container>
    <Styles.BorderLine />
    <Styles.Line />
    <Styles.BorderLine />
  </Styles.Container>
);

export default ProgressBar;
