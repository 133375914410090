import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";

// Reducers
import appReducer from "./reducers/app";
import gameReducer from "./reducers/game";

// Services
import { shopApi } from "./services/shopApi";
import { tasksApi } from "./services/tasksApi";

export const store = configureStore({
  reducer: {
    app: appReducer,
    game: gameReducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(shopApi.middleware)
      .concat(tasksApi.middleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
