import * as React from "react";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>
        Withdrawal is available from{" "}
        <Styles.TitleYellow>4 TON</Styles.TitleYellow>
      </Styles.Title>
      <Styles.ButtonRow>
        <Button title="Close" onClick={onClose} variant="outline" />
      </Styles.ButtonRow>
    </SheetWrapper>
  );
};

export default WithdrawSheet;
