import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Utils
import type { TMapDay } from "@utils/api/types";

export interface GameState {
  levelMap: TMapDay[];
  characterIndex: number;
}

const initialState: GameState = {
  levelMap: [],
  characterIndex: 0,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setLevelMap: (state, action: PayloadAction<TMapDay[]>) => {
      state.levelMap = action.payload;
    },
    setCharacterIndex: (state, action: PayloadAction<number>) => {
      state.characterIndex = action.payload;
    },
  },
});

export const { setLevelMap, setCharacterIndex } = gameSlice.actions;

export default gameSlice.reducer;
