import * as React from "react";
import Countdown, { type CountdownRenderProps } from "react-countdown";
import dayjs from "dayjs";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Utils
import { buyItem, getLivesShopId } from "@utils/api";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Config
import { DEFAULT_LIVES } from "@config/consts";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccessBuyLives: () => void;
  sessionId: string;
}

const NoLivesSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, onSuccessBuyLives, sessionId } = props;

  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { initData, openInvoice } = useWebApp();

  const onBuy = async (): Promise<void> => {
    setLoading(true);

    const livesShopId = await getLivesShopId(initData, sessionId);

    if (livesShopId) {
      const invoiceUrl = await buyItem(initData, livesShopId, sessionId);

      if (invoiceUrl) {
        openInvoice(invoiceUrl, (status) => {
          if (status === "paid") {
            onSuccessBuyLives();
          } else if (status === "failed" || status === "cancelled") {
            setLoading(false);
          }
        });
      }
    } else {
      setLoading(false);
    }
  };

  const renderCountdown = ({
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    return completed ? null : (
      <span className="timer">
        {hours} hours {minutes} minuted {seconds} seconds
      </span>
    );
  };

  return (
    <SheetWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>You don't have enough lives</Styles.Title>
      <Styles.Description>
        New lives will be available in{" "}
        <Countdown
          renderer={renderCountdown}
          date={dayjs().utc().endOf("day").toDate()}
          onComplete={onClose}
        />
        .
      </Styles.Description>
      <Styles.Icon src="/assets/crying.png" alt="crying" />
      <Styles.Actions>
        <Button
          title={`Buy ${DEFAULT_LIVES} lives`}
          onClick={onBuy}
          icon="/assets/icons/stars.svg"
          isLoading={isLoading}
        />
        <Button title="Back" onClick={onClose} variant="notfilled" />
      </Styles.Actions>
    </SheetWrapper>
  );
};

export default NoLivesSheet;
