import * as React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Wrapper from "@components/Wrapper";
import Button from "@components/Button";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Config
import slides from "./slides";

// Styles
import Styles from "./styles";

const OnboardingPage: React.FC = () => {
  const [slideIndex, setSlideIndex] = React.useState<number>(0);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const navigate = useNavigate();
  const { impactOccurred } = useWebApp();

  const onNext = (): void => {
    if (slideIndex !== slides.length - 1) {
      setSlideIndex((prev) => prev + 1);
      videoRef.current?.play();
    } else {
      navigate("/select-character");
    }

    impactOccurred("heavy");
  };

  return (
    <Wrapper>
      <Styles.Container>
        <Styles.Video
          src={slides[slideIndex].video}
          autoPlay
          loop
          muted
          playsInline
          ref={videoRef}
        />
        <Styles.Bottom>
          <Styles.Title>{slides[slideIndex].title}</Styles.Title>
          <Styles.Description>
            {slides[slideIndex].description}
          </Styles.Description>
          <Button title={slides[slideIndex].buttonTitle} onClick={onNext} />
          <Styles.Dots>
            {Array(slides.length)
              .fill("dot")
              .map((i, index) => (
                <Styles.Dot
                  key={`${i}/${index}`}
                  src={
                    index === slideIndex
                      ? "/assets/icons/paginate-dot-active.svg"
                      : "/assets/icons/paginate-dot.svg"
                  }
                  alt="dot"
                />
              ))}
          </Styles.Dots>
        </Styles.Bottom>
      </Styles.Container>
    </Wrapper>
  );
};

export default OnboardingPage;
