import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  50% {
    transform: translate3d(0px, -4px, 0);
  }
  100% {
    transform: translate3d(0px, 0px, 0);
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* background: url("/assets/background.png") no-repeat;
  background-size: cover;
  background-position: center -100px; */
  background-color: #000000;
  position: relative;
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
`;

const CoverSpace = styled.img`
  position: absolute;
  width: 100%;
  height: 400px;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
  filter: blur(1px);
  z-index: 1;
`;

const Coins = styled.img`
  position: absolute;
  width: 120vw;
  left: -10vw;
  top: -10vh;
  z-index: 1;
  animation: ${bounce} 1s infinite;
`;

const Row = styled.div`
  padding: 22px 20px 0 20px;
  z-index: 2;
`;

const Logo = styled.img`
  width: 100%;
  height: 30px;
  object-fit: contain;
`;

const TopUsers = styled.div`
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
`;

const Actions = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;

  button {
    height: 50px;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: rgba(39, 39, 39, 0.75);
  background-blend-mode: luminosity;
  backdrop-filter: blur(12.5px);
  position: fixed;
  bottom: calc(66px + calc(100vh - var(--tg-viewport-height, 100vh)));
  width: 100%;
  padding: 5px 26px;
  z-index: 2;

  button {
    height: 50px;
  }
`;

const CardUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
`;

const CardStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const CardStatsIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const CardStatsValue = styled.p`
  color: #ffffff;
  font-family: "DS-Digital", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const CardStatsSymbol = styled.p`
  margin: 0 0 0 -2.5px;
  color: #c7c7cc;
  font-family: "DS-Digital", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

const CardValueRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1px;
`;

const CardValue = styled.p`
  color: #ffffff;
  font-family: "DS-Digital", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
`;

const BoostLabel = styled.p`
  color: #bd52fd;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const List = styled.div`
  margin: 20px 0 0 0;
  border-radius: 30px 30px 0 0;
  background-color: #1c1d1f;
  padding: 30px 20px;
  padding-bottom: calc(
    100vh - 600px + calc(100vh - var(--tg-viewport-height, 100vh))
  );
  flex: 1;
  z-index: 2;
`;

const SpinnerRow = styled.div`
  display: flex;
  justify-content: center;
`;

const ListHeading = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ListHeadingTitle = styled.p`
  color: #c7c7cc;
  font-size: 12px;
  font-weight: 400;
`;

const UsersList = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: column;
`;

const Styles = {
  Container,
  CoverSpace,
  Overlay,
  Coins,
  Row,
  Logo,
  TopUsers,
  Actions,
  Card,
  CardUser,
  CardRow,
  CardTitle,
  CardStats,
  CardStatsIcon,
  CardStatsValue,
  CardStatsSymbol,
  CardValueRow,
  CardValue,
  BoostLabel,
  List,
  SpinnerRow,
  ListHeading,
  ListHeadingTitle,
  UsersList,
};

export default Styles;
