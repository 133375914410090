import * as React from "react";
import {
  useTonConnectUI,
  useTonAddress,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import { beginCell, Cell, fromNano } from "@ton/core";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Spinner from "@components/Spinner";
import Card from "./MiningCard";
import Button from "@components/Button";

// Sheets
import BuySheet from "@sheets/Buy";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setMining } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { sleep } from "@utils/index";
import {
  getMiningProducts,
  getMiningProductInvoice,
  getMiningTx,
  getMiningInfo,
  checkMiningTx,
  getMaps,
  getBalance,
  startFreeMining,
} from "@utils/api";
import type { TMiningInfo, TMiningProduct } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MiningSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  const [activeSheet, setActiveSheet] = React.useState<"buy" | null>(null);
  const [products, setProducts] = React.useState<TMiningProduct[]>([]);
  const [selectedProduct, setSelectedProduct] =
    React.useState<TMiningProduct | null>(null);
  const [loadingCurrency, setLoadingCurrency] = React.useState<
    "ton" | "stars" | null
  >(null);
  const [isClaimLoading, setClaimLoading] = React.useState<boolean>(false);

  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const {
    open: openModal,
    state: { status: modalStatus },
  } = useTonConnectModal();

  const { initData, openInvoice } = useWebApp();

  const sessionId = useAppSelector((state) => state.app.sessionId);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (isOpen) {
      onGetProducts();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (loadingCurrency === "stars") {
      onBuyForStars();
    } else if (loadingCurrency === "ton") {
      onBuyForTon();
    }
  }, [loadingCurrency]);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (modalStatus === "closed" && loadingCurrency === "ton" && address) {
      onSendTon();
    }
  }, [modalStatus, address]);

  React.useEffect(() => {
    if (isOpen && tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
  }, [isOpen]);

  const onBuyForStars = async (): Promise<void> => {
    if (!selectedProduct) {
      return;
    }

    const invoiceUrl = await getMiningProductInvoice(
      initData,
      selectedProduct.shop_id,
      sessionId
    );

    if (!invoiceUrl) {
      return setLoadingCurrency(null);
    }

    openInvoice(invoiceUrl, (status) => {
      if (status === "paid") {
        onSuccessBuy();
      } else if (status === "failed" || status === "cancelled") {
        setLoadingCurrency(null);
      }
    });
  };

  const onSuccessBuy = async (): Promise<void> => {
    dispatch(setMining(await getMiningInfo(initData, sessionId)));

    setSelectedProduct(null);
    setActiveSheet(null);
    setLoadingCurrency(null);
    setClaimLoading(false);
    onClose();
  };

  const onGetProducts = async (): Promise<void> => {
    setProducts([]);
    setProducts(await getMiningProducts(initData, sessionId));
  };

  const onBuy = (product: TMiningProduct) => (): void => {
    setSelectedProduct(product);
  };

  const onCloseBuySheet = (): void => {
    setActiveSheet(null);
  };

  const onBuyForTon = (): void => {
    if (!address) {
      openModal();
    } else {
      onSendTon();
    }
  };

  const onSendTon = async (): Promise<void> => {
    if (!selectedProduct) {
      return;
    }

    if (loadingCurrency !== "ton") {
      setLoadingCurrency("ton");
    }

    const tx = await getMiningTx(initData, selectedProduct.shop_id, sessionId);

    if (!tx) {
      return setLoadingCurrency(null);
    }

    const {
      valid_until: validUntil,
      messages: [message],
    } = tx;

    const payload = beginCell()
      .storeUint(0, 32)
      .storeStringTail(message.payload)
      .endCell()
      .toBoc()
      .toString("base64");

    tonConnectUI
      .sendTransaction(
        {
          validUntil,
          messages: [
            {
              address: message.address,
              amount: message.amount,
              payload,
            },
          ],
        },
        {
          notifications: [],
        }
      )
      .then(async (response) => {
        const txId = Cell.fromBase64(response.boc).hash().toString("hex");

        await checkTx(txId);

        onSuccessBuy();
      })
      .catch(() => {
        setLoadingCurrency(null);
      });
  };

  const checkTx = async (txId: string): Promise<TMiningInfo> => {
    await sleep(5000);

    const result = await checkMiningTx(
      initData,
      encodeURIComponent(txId),
      sessionId
    );

    if (result) {
      return result;
    }

    return checkTx(txId);
  };

  const onConfirmBuy = (currency: "ton" | "stars"): void => {
    setLoadingCurrency(currency);
  };

  const onClaim = async (): Promise<void> => {
    if (selectedProduct?.discount_percent === 100) {
      setClaimLoading(true);

      const response = await startFreeMining(initData, sessionId);

      if (response) {
        onSuccessBuy();
      } else {
        setClaimLoading(false);
      }
    } else {
      setActiveSheet("buy");
    }
  };

  return (
    <>
      <SheetWrapper
        isOpen={isOpen}
        onClose={onClose}
        backgroundImage={`url("/assets/auto-farm-sheet-bg.png")`}
        isBlack
        disableDrag={loadingCurrency !== null}
        snapPoints={[-50]}
      >
        <Styles.IconRow>
          <Styles.Icon src="/assets/mining.gif" alt="mining" />
        </Styles.IconRow>
        <Styles.Heading>
          <Styles.Title>⛏️ Mining</Styles.Title>
          <Styles.CoinIcon src="/assets/cr-coin.png" alt="coin" />
          <Styles.Symbol>$CP</Styles.Symbol>
        </Styles.Heading>

        <Styles.ListText>
          <Styles.ListTextItem>
            • Earn{" "}
            <Styles.ListTextItemYellow>300k $CP</Styles.ListTextItemYellow>{" "}
            Every Day
          </Styles.ListTextItem>
          <Styles.ListTextItem>
            • Enjoy{" "}
            <Styles.ListTextItemYellow>3 000%</Styles.ListTextItemYellow> Faster
            Rewards
          </Styles.ListTextItem>
          <Styles.ListTextItem>
            • Receive $CP for Every Activity:{"\n"}
            <Styles.ListTextItemYellow>
              Spins, Levels, Tasks, and More!
            </Styles.ListTextItemYellow>
          </Styles.ListTextItem>
        </Styles.ListText>
        <Styles.List>
          {products.length ? (
            <>
              {products.map((product) => {
                const {
                  period,
                  label,
                  price_stars,
                  price_ton,
                  discount_percent,
                  shop_id,
                  max_claim,
                  is_used,
                } = product;

                return (
                  <Card
                    key={product.shop_id}
                    title={period}
                    salePercent={discount_percent}
                    badge={label}
                    prices={{ stars: price_stars, ton: +fromNano(price_ton) }}
                    onBuy={onBuy(product)}
                    isActive={shop_id === selectedProduct?.shop_id}
                    max_claim={max_claim}
                    is_used={is_used}
                  />
                );
              })}
            </>
          ) : (
            <Spinner size={40} />
          )}
        </Styles.List>

        <Styles.Stats>
          Over <Styles.StatsYellow>1 000</Styles.StatsYellow> players are
          already Mining
        </Styles.Stats>
        <Styles.ButtonRow>
          <Button
            title="YOUR OFFER NOW"
            yellowTitle="CLAIM"
            yellowFirst
            disabled={selectedProduct === null}
            onClick={onClaim}
            isLoading={isClaimLoading}
          />
        </Styles.ButtonRow>
      </SheetWrapper>
      {selectedProduct && activeSheet === "buy" ? (
        <BuySheet
          isOpen
          onClose={onCloseBuySheet}
          prices={{
            stars: selectedProduct.price_stars,
            ton: +fromNano(selectedProduct.price_ton),
          }}
          loadingCurrency={loadingCurrency}
          onConfirmBuy={onConfirmBuy}
        />
      ) : null}
    </>
  );
};

export default MiningSheet;
