import * as React from "react";
import { useNavigate } from "react-router-dom";
import WebApp from "@twa-dev/sdk";
import CountUp from "react-countup";

// Components
import ModalWrapper from "@components/ModalWrapper";
import Button from "@components/Button";
import Spinner from "@components/Spinner";

// Store
import { setLevelMap } from "@store/reducers/game";
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import {
  completeLevel,
  completeTutorial,
  getBalance,
  getMaps,
  getNextLevel,
} from "@utils/api";
import type { TMapDay } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  score: number;
  level: number;
  levelId?: number;
  setNextLevel?: (nextLevel: TMapDay) => void;
  totalCoins: number;
  amount?: number;
  isTutorial?: boolean;
}

const LevelCompletedModal: React.FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    score,
    level,
    levelId,
    setNextLevel,
    totalCoins,
    isTutorial,
  } = props;

  const [amount, setAmount] = React.useState<number | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isDayComplete, setDayComplete] = React.useState<boolean>(false);

  const { initData } = useWebApp();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const sessionId = useAppSelector((state) => state.app.sessionId);
  const levelMap = useAppSelector((state) => state.game.levelMap);
  const characters = useAppSelector((state) => state.app.characters);
  const characterIndex = useAppSelector((state) => state.game.characterIndex);

  React.useEffect(() => {
    if (isOpen) {
      onCompleteLevel();
    }
  }, [isOpen]);

  React.useEffect(() => {
    setDayComplete(
      levelMap.map((i) => i.is_completed).filter((i) => i).length === 5
    );
  }, [levelMap.length]);

  const onCompleteLevel = async (): Promise<void> => {
    if (props.amount && sessionId) {
      setAmount(props.amount);

      await completeTutorial(initData, sessionId);

      dispatch(setBalance(await getBalance(initData, sessionId)));

      return;
    }

    if (!initData || !levelId || !sessionId) {
      return;
    }

    const amount = +Number(score / totalCoins).toFixed(2);
    const getTotalValue = await completeLevel(
      initData,
      levelId,
      amount,
      sessionId
    );

    if (getTotalValue === null) {
      onClose();
      return navigate("/game", {
        replace: true,
        state: null,
      });
    } else {
      setAmount(getTotalValue.points);
    }

    dispatch(setLevelMap(await getMaps(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
  };

  const onGoToMain = (): void => {
    if (isTutorial && !isDayComplete) {
      const mapDay = levelMap.filter((level) => !level.is_completed)[0];

      navigate("/gameplay", {
        state: {
          character: characters[characterIndex],
          mapDay,
        },
      });

      return;
    }

    navigate("/game", {
      replace: true,
      state: {
        activeSheet: isTutorial
          ? "day-streak"
          : level === 5
          ? "max-levels-completed"
          : null,
      },
    });
    WebApp.BackButton.hide();
  };

  const onLoadNextLevel = async (): Promise<void> => {
    if (!sessionId) {
      return;
    }

    setLoading(true);

    const nextLevel = await getNextLevel(initData, sessionId);

    if (nextLevel && setNextLevel) {
      setNextLevel(nextLevel);
    }

    setLoading(false);
    onClose();
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
    >
      <Styles.Title>{isTutorial ? "Tutorial" : `Level ${level}`}</Styles.Title>
      <Styles.SubTitle>Completed</Styles.SubTitle>
      <Styles.RewardRow>
        <Styles.Glow>
          <Styles.RewardIcon src="/assets/cr-coin.png" alt="icon" />
        </Styles.Glow>
        {amount ? (
          <>
            <CountUp start={0} end={amount} duration={2} className="reward" />
            <Styles.Symbol>$CP</Styles.Symbol>
          </>
        ) : (
          <Spinner size={40} />
        )}
      </Styles.RewardRow>
      <Styles.Actions>
        {level === 5 || isTutorial ? (
          <Button
            title={isTutorial && !isDayComplete ? "Play game" : "Go to main"}
            onClick={onGoToMain}
            disabled={isLoading}
          />
        ) : (
          <>
            <Button
              title="Next level"
              onClick={onLoadNextLevel}
              isLoading={isLoading}
            />
            <Button
              title="Go to main"
              variant="notfilled"
              onClick={onGoToMain}
              disabled={isLoading}
            />
          </>
        )}
      </Styles.Actions>
    </ModalWrapper>
  );
};

export default LevelCompletedModal;
