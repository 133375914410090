import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useTonConnectUI,
  useTonAddress,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import { beginCell, Cell, fromNano } from "@ton/core";
import WebApp from "@twa-dev/sdk";

// Components
import Wrapper from "@components/Wrapper";
import BackButton from "@components/BackButton";
import ShopOfferButton from "./ShopOfferButton";
import Button from "@components/Button";

// Sheets
import PaymentApprovedSheet from "@sheets/PaymentApproved";
import PaymentNotApprovedSheet from "@sheets/PaymentNotApproved";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance, setCharacters } from "@store/reducers/app";
import { shopApi } from "@store/services/shopApi";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import { abbreviateNumber, formatPoints } from "@utils/format";
import {
  buyItem,
  checkShopTx,
  getBalance,
  getCharacters,
  getShopTx,
  takeFreeOffer,
} from "@utils/api";
import { sleep } from "@utils/index";
import type { TShopItem } from "@utils/api/types";

// Styles
import Styles from "./styles";

type TLocationState = {
  item: TShopItem;
};

type TItemsValues = {
  points: number;
  tickets: number;
  skins: number;
};

const ShopOfferPage: React.FC = () => {
  const [loadingCurrency, setLoadingCurrency] = React.useState<
    "ton" | "stars" | null
  >(null);
  const [activeSheet, setActiveSheet] = React.useState<
    "approved" | "not-approved" | "skin-info" | null
  >(null);
  const [itemsValues, setItemsValues] = React.useState<TItemsValues>({
    points: 0,
    tickets: 0,
    skins: 0,
  });
  const [isClaimLoading, setClaimLoading] = React.useState<boolean>(false);

  const {
    state: {
      item: { title, items, id, price, price_ton },
    },
  }: {
    state: TLocationState;
  } = useLocation();
  const isFreePack = price === 0 && price_ton === 0;

  const { impactOccurred, openInvoice, initData } = useWebApp();

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.app.sessionId);

  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const {
    open: openModal,
    state: { status: modalStatus },
  } = useTonConnectModal();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (loadingCurrency === "stars") {
      onBuyForStars();
    } else if (loadingCurrency === "ton") {
      onBuyForTon();
    }
  }, [loadingCurrency]);

  React.useEffect(() => {
    if (modalStatus === "closed" && loadingCurrency === "ton" && address) {
      onSendTon();
    }
  }, [modalStatus, address]);

  React.useEffect(() => {
    if (isFreePack) {
      onGetItemsValues();
    }
  }, [isFreePack]);

  const onGetItemsValues = (): void => {
    const findPoints = items.find((item) => item.type === "points");
    const findTickets = items.find((item) => item.type === "tickets");
    const findSkins = items.filter((item) => item.type === "skin");

    setItemsValues({
      points: findPoints?.value || 0,
      tickets: findTickets?.value || 0,
      skins: findSkins.length,
    });
  };

  const refetchBalance = async (): Promise<void> => {
    const findSkinsItems = items.filter((item) => item.type === "skin");
    const findTicketsOrPointsItems = items.filter(
      (item) => item.type === "points" || item.type === "tickets"
    );

    if (findSkinsItems.length) {
      dispatch(setCharacters(await getCharacters(initData, sessionId)));
      dispatch(shopApi.util.resetApiState());
    }

    if (findTicketsOrPointsItems.length) {
      dispatch(setBalance(await getBalance(initData, sessionId)));
    }
  };

  const onBuy = (currency: "stars" | "ton") => (): void => {
    impactOccurred("medium");
    setLoadingCurrency(currency);
  };

  const onBuyForStars = async (): Promise<void> => {
    const invoiceUrl = await buyItem(initData, id, sessionId);

    if (invoiceUrl) {
      openInvoice(invoiceUrl, (status) => {
        if (
          status === "cancelled" ||
          status === "failed" ||
          status === "paid"
        ) {
          setLoadingCurrency(null);
        }
        if (status === "paid") {
          setActiveSheet("approved");
          refetchBalance();
        } else if (status === "failed") {
          setActiveSheet("not-approved");
        }
      });
    }
  };

  const onBuyForTon = (): void => {
    if (!address) {
      openModal();
    } else {
      onSendTon();
    }
  };

  const onSendTon = async (): Promise<void> => {
    if (loadingCurrency !== "ton") {
      setLoadingCurrency("ton");
    }

    const tx = await getShopTx(initData, id, sessionId);

    if (!tx) {
      return setLoadingCurrency(null);
    }

    const {
      valid_until: validUntil,
      messages: [message],
    } = tx;

    const payload = beginCell()
      .storeUint(0, 32)
      .storeStringTail(message.payload)
      .endCell()
      .toBoc()
      .toString("base64");

    tonConnectUI
      .sendTransaction(
        {
          validUntil,
          messages: [
            {
              address: message.address,
              amount: message.amount,
              payload,
            },
          ],
        },
        {
          notifications: [],
        }
      )
      .then(async (response) => {
        const txId = Cell.fromBase64(response.boc).hash().toString("hex");

        await checkTx(txId);

        setActiveSheet("approved");
        setLoadingCurrency(null);
        refetchBalance();
      })
      .catch(() => {
        setLoadingCurrency(null);
        setActiveSheet("not-approved");
      });
  };

  const checkTx = async (txId: string): Promise<TShopItem> => {
    await sleep(5000);

    const result = await checkShopTx(
      initData,
      encodeURIComponent(txId),
      sessionId
    );

    if (result) {
      return result;
    }

    return checkTx(txId);
  };

  const onCloseSheet = (): void => {
    setActiveSheet(null);
  };

  const onClaimFreePack = async (): Promise<void> => {
    setClaimLoading(true);

    await takeFreeOffer(initData, sessionId, id);

    dispatch(setCharacters(await getCharacters(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
    dispatch(shopApi.util.resetApiState());

    setActiveSheet("approved");
  };

  const onCloseApprovedSheet = (): void => {
    if (isFreePack) {
      navigate("/game", {
        replace: true,
        state: null,
      });

      WebApp.BackButton.hide();
    }

    onCloseSheet();
  };

  return (
    <Wrapper withProfile>
      <BackButton withBottomBack />
      <Styles.Container>
        <Styles.Row>
          {isFreePack ? (
            <>
              <Styles.Title>
                <Styles.TitleYellow>Free</Styles.TitleYellow> Offer Pack
              </Styles.Title>
              <Styles.Description>
                Your Adventure Begins Here!{"\n"}
                Claim{" "}
                <Styles.DescriptionYellow>
                  {abbreviateNumber(itemsValues.points, 0)}
                </Styles.DescriptionYellow>{" "}
                $СP,{" "}
                <Styles.DescriptionYellow>
                  {itemsValues.tickets}
                </Styles.DescriptionYellow>{" "}
                Tickets, and{" "}
                <Styles.DescriptionYellow>
                  {itemsValues.skins}
                </Styles.DescriptionYellow>{" "}
                Heroes Now!
              </Styles.Description>
            </>
          ) : (
            <>
              <Styles.Title>{title}</Styles.Title>
              <Styles.Description>
                If You Already Have a Skin in the Pack, We Automatically Give
                You 100K $CP Instead!
              </Styles.Description>
            </>
          )}

          <Styles.List>
            {items.map((item) => (
              <Styles.ListItem key={`${item.type}/${item.name}`}>
                <Styles.Card>
                  <Styles.CardHeading>
                    <Styles.CardTitle>
                      {item.type === "skin"
                        ? item.name
                        : abbreviateNumber(item.value, 0)}
                    </Styles.CardTitle>
                  </Styles.CardHeading>
                  <Styles.CardOverlay />
                  <Styles.CardBody>
                    <Styles.CardImage src={item.image_url} alt="image" />
                  </Styles.CardBody>
                </Styles.Card>
              </Styles.ListItem>
            ))}
          </Styles.List>
        </Styles.Row>
        <Styles.Actions>
          {isFreePack ? (
            <Button
              title="Claim for"
              yellowTitle="free"
              onClick={onClaimFreePack}
              isLoading={isClaimLoading}
            />
          ) : (
            <>
              <ShopOfferButton
                isLoading={loadingCurrency === "stars"}
                price={price}
                currency="stars"
                onClick={onBuy("stars")}
                disabled={loadingCurrency !== null}
              />
              <ShopOfferButton
                isLoading={loadingCurrency === "ton"}
                price={+fromNano(price_ton)}
                currency="ton"
                onClick={onBuy("ton")}
                disabled={loadingCurrency !== null}
              />
            </>
          )}
        </Styles.Actions>
      </Styles.Container>
      <PaymentApprovedSheet
        isOpen={activeSheet === "approved"}
        onClose={onCloseApprovedSheet}
      />
      <PaymentNotApprovedSheet
        isOpen={activeSheet === "not-approved"}
        onClose={onCloseSheet}
      />
    </Wrapper>
  );
};

export default ShopOfferPage;
