import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  height: 100%;
`;

const CoverSpace = styled.img`
  position: absolute;
  width: 100%;
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
  filter: blur(0.5px);
`;

const Row = styled.div`
  z-index: 2;
  padding: 20px 20px 32px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
`;

const TitleYellow = styled.span`
  color: #fc0;
`;

const Description = styled.p`
  margin: 5px 15px 0 15px;
  color: #e5e5ea;
  text-align: center;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.45px;
`;

const Countdown = styled.div`
  margin: 16px 0 0 0;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 204, 0, 0.25);
  backdrop-filter: blur(0.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("/assets/airdrop-countdown.svg");
  background-repeat: repeat-x;
`;

const CountdownArrow = styled.div`
  width: 17px;
  height: 100%;
  position: absolute;
  background: url("/assets/airdrop-countdown-arrow.svg") no-repeat;

  &:first-child {
    left: 0;
  }

  &:last-child {
    right: 0;
    transform: rotate(180deg);
  }
`;

const CountdownValue = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "DS-Digital", sans-serif;
  font-size: 32px;
  font-weight: 700;
  text-transform: lowercase;
`;

const CountdownSpan = styled.span`
  color: #c7c7cc;
`;

const CountdownDate = styled.p`
  color: #e5e5ea;
  font-family: "Stapel_Medium";
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Stats = styled.p`
  margin: 40px 0 0 0;
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.48px;
`;

const StatsBlue = styled.span`
  color: #04bbf9;
`;

const Bottom = styled.div`
  border-radius: 25px 25px 0px 0px;
  background-color: #1c1d1f;
  flex: 1;
  width: 100%;
  padding: 30px 20px;
  z-index: 2;
`;

const BottomHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BottomTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.6px;
`;

const BottomHeadingStats = styled.p`
  color: #bd52fd;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.6px;
`;

const SpinnerRow = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
`;

const BottomList = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BottomValue = styled.p`
  margin: 30px 0;
  white-space: pre-line;
  text-align: center;
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.6px;
`;

const BottomValuePurple = styled.span`
  color: #bd52fd;
`;

const List = styled.div`
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ListItemIconRow = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #fc0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItemIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ListItemTitle = styled.p`
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  font-family: "Stapel_Medium";
`;

const ListItemPosition = styled.p`
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  font-family: "Stapel_Medium";
`;

const FollowSteps = styled.p`
  margin: 40px 0 0 0;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
`;

const Styles = {
  Container,
  CoverSpace,
  Overlay,
  Row,
  Title,
  TitleYellow,
  Description,
  Countdown,
  CountdownArrow,
  CountdownValue,
  CountdownSpan,
  CountdownDate,
  Stats,
  StatsBlue,
  Bottom,
  BottomHeading,
  BottomTitle,
  BottomHeadingStats,
  SpinnerRow,
  BottomList,
  BottomValue,
  BottomValuePurple,
  List,
  ListItem,
  ListItemIconRow,
  ListItemTitle,
  ListItemIcon,
  ListItemPosition,
  FollowSteps,
};

export default Styles;
