import * as React from "react";
import { Sprite } from "@pixi/react";
import { useInterval } from "usehooks-ts";

// Utils
import type { Direction, TCoordinates } from "@utils/game";
import type { TLevelMapItem } from "@utils/api/types";

interface Props {
  x: number;
  y: number;
  cellSize: number;
  name: string;
  map: TLevelMapItem[][];
  playerCoordinates: TCoordinates;
  onDamage: () => void;
}

const SHOOT_VECTOR: Record<Direction, TCoordinates> = {
  UP: { x: 0, y: +1 },
  RIGHT: { x: -1, y: 0 },
  DOWN: { x: 0, y: -1 },
  LEFT: { x: 1, y: 0 },
};

const CANNONS_DIRECTIONS: Record<string, Direction> = {
  "102": "UP",
  "103": "RIGHT",
  "104": "DOWN",
  "105": "LEFT",
};

const isWayFreeInDirection = (cell: TLevelMapItem): boolean => {
  const { type } = cell;

  return type === "empty" || type === "coin" || type === "player";
};

const CannonBlock: React.FC<Props> = (props) => {
  const { x, y, cellSize, name, map, playerCoordinates, onDamage } = props;

  const [rocketCoordinates, setRocketCoordinates] =
    React.useState<TCoordinates | null>(null);
  const [direction, setDirection] = React.useState<Direction | null>(null);

  React.useEffect(() => {
    onCheckDamage();
  }, [playerCoordinates, rocketCoordinates]);

  React.useEffect(() => {
    if (!direction) {
      setDirection(CANNONS_DIRECTIONS[name]);
    }
  }, [direction]);

  useInterval(
    () => {
      if (!direction) {
        return;
      }

      const { x: vectorX, y: vectorY } = SHOOT_VECTOR[direction];

      if (!rocketCoordinates) {
        setRocketCoordinates({
          x: x + vectorX * cellSize,
          y: y + vectorY * cellSize,
        });
      } else {
        const { x: rocketX, y: rocketY } = rocketCoordinates;
        const nextWall =
          map[rocketY / cellSize + vectorY][rocketX / cellSize + vectorX];

        if (isWayFreeInDirection(nextWall)) {
          setRocketCoordinates({
            x: rocketX + vectorX * cellSize,
            y: rocketY + vectorY * cellSize,
          });
        } else {
          setRocketCoordinates(null);
        }
      }
    },
    direction ? 500 : null
  );

  const onCheckDamage = (): void => {
    if (!rocketCoordinates || !direction) {
      return;
    }

    const { x: playerX, y: playerY } = playerCoordinates;
    const { x: rocketX, y: rocketY } = rocketCoordinates;
    const d = cellSize / 2;

    if (playerX - d === rocketX && playerY - d === rocketY) {
      onDamage();
    }
  };

  if (!direction) {
    return null;
  }

  return (
    <>
      <Sprite
        image={`/assets/game/cannons/cannon-${direction}${
          rocketCoordinates ? "-active" : ""
        }.png`}
        x={x}
        y={y}
        width={cellSize}
        height={cellSize}
      />
      {rocketCoordinates ? (
        <Sprite
          image={`/assets/game/cannons/cannon-${direction}-rocket.png`}
          x={rocketCoordinates.x}
          y={rocketCoordinates.y}
          width={cellSize}
          height={cellSize}
        />
      ) : null}
    </>
  );
};

export default CannonBlock;
