import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  /* background: url("/assets/day-streak-bg.png") no-repeat bottom; */
  /* background-size: cover; */
  position: fixed;
  bottom: 0;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  background-color: #000000;

  canvas {
    width: 100%;
    height: 100%;
  }

  button {
    height: 50px;
  }
`;

const Top = styled.div`
  padding: 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  text-align: center;
  font-size: calc(100vh - 530px);
  font-weight: 500;
  height: calc(100vh - 530px);
`;

const SubTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 40px;
  font-weight: 400;
  margin: 16px 0 0 0;
`;

const Description = styled.p`
  margin: 15px 0 0 0;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: calc(15px + calc(100vh - var(--tg-viewport-height, 100vh)));
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BottomText = styled.p`
  color: #e9ebef;
  text-align: center;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const Rewards = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const RewardCard = styled.div`
  flex: 1;
  border-radius: 25px;
  border: 2px solid #fc0;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(7.5px);
  height: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Glow = styled.div`
  width: 80%;
  height: 80%;
  background: url("/assets/glow.png") no-repeat;
  position: absolute;
  top: -10px;
  background-size: cover;
`;

const RewardIcon = styled.img`
  margin: 16px 0 0 0;
  width: 85px;
  height: 85px;
  object-fit: cover;
`;

const RewardValueRow = styled.div`
  position: absolute;
  bottom: 10px;
`;

const Reward = styled.p`
  color: #fc0;
  text-align: center;
  font-family: "Stapel_Bold";
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  &.blue {
    color: #04bbf9;
  }
`;

const RewardLabel = styled.p`
  color: #c7c7cc;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Top,
  Title,
  SubTitle,
  Description,
  Bottom,
  BottomText,
  Rewards,
  RewardCard,
  Glow,
  RewardIcon,
  RewardValueRow,
  Reward,
  RewardLabel,
};

export default Styles;
