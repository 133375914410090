import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  50% {
    transform: translate3d(0px, -4px, 0);
  }
  100% {
    transform: translate3d(0px, 0px, 0);
  }
`;

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const whiteColor = keyframes`
  0% { fill: #04BBF9; }
  100% { fill: #ffffff; }
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 336px - calc(100vh - var(--tg-viewport-height, 100vh)));
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Box = styled.div`
  width: 18vw;
  aspect-ratio: 1.2 / 1;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &.bottom-left {
    bottom: 30px;
    left: 20px;
    position: absolute;
  }
`;

const BoxRow = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/assets/booster-bg.png") no-repeat;
  background-size: cover;

  &.disabled {
    opacity: 0.25;
  }
`;

const BoxName = styled.p`
  transform: rotate(90deg);
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-weight: 400;
  position: absolute;
  text-transform: uppercase;
  width: 0;
  white-space: nowrap;
  left: 9%;
  top: 20%;
  font-size: 6px;
`;

const BoxValueRow = styled.div`
  width: 14px;
  height: 14px;
  background: url("/assets/booster-value-bg.svg") no-repeat;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 5px;
`;

const BoxValue = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
`;

const BoxImage = styled.img`
  width: 90%;
  height: 90%;
`;

const BoxesList = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Stats = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  align-self: center;

  .question {
    width: 16px;
    height: 16px;

    &.active {
      svg > rect {
        animation: ${whiteColor} 0.5s infinite;
      }
    }
  }
`;

const UpIcon = styled.img`
  width: 22px;
  height: 26px;
`;

const StatsValue = styled.p`
  text-align: center;
  text-shadow: 0px 2.667px 0px rgba(255, 255, 255, 0.25);
  font-size: 24px;
  font-style: normal;
  font-family: "Stapel_Expanded_Bold";
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  background: linear-gradient(180deg, #fff 15.28%, #04bbf9 90.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AskIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const SoonRow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;
`;

const LockIcon = styled.img`
  width: 28px;
  height: 28px;
`;

const SoonLabel = styled.p`
  color: #04bbf9;
  font-size: 10px;
  font-weight: 400;
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
`;

const PaginateArrow = styled.img`
  width: 12px;
  height: 26px;

  &:last-child {
    transform: rotate(180deg);
  }
`;

const PaginateValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const AirdropIcon = styled.img`
  width: 72px;
  height: 90px;
  position: absolute;
  left: 20px;
  top: 32px;
  animation: ${bounce} 1s infinite;
  z-index: 2;
`;

const Image = styled.img`
  height: calc(100% - 30px);
`;

const Slider = styled.div`
  width: 100vw;

  .swiper {
    .swiper-slide {
      height: calc(
        100vh - 410px - calc(100vh - var(--tg-viewport-height, 100vh))
      );
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ShopBooster = styled.img`
  position: absolute;
  width: 8vh;
  aspect-ratio: 0.83 / 1;
  right: 20px;
  bottom: 30px;
  z-index: 2;
`;

const OfferBooster = styled.img`
  position: absolute;
  width: 8vh;
  aspect-ratio: 0.83 / 1;
  right: 20px;
  bottom: 30px;
  z-index: 2;
`;

const PlayBooster = styled.img`
  position: absolute;
  width: 8vh;
  aspect-ratio: 0.83 / 1;
  left: 20px;
  bottom: 30px;
  z-index: 2;
`;

const GlowBoosterRow = styled.div`
  aspect-ratio: 0.83 / 1;
  width: 8vh;
  background-size: cover;
`;

const TreeImage = styled.img`
  width: 100%;
  margin: 2px 0 0 0;
  aspect-ratio: 1 / 1;
  position: relative;
  z-index: 2;
`;

const Glow = styled.img`
  width: 100%;
  margin: 2px 0 0 0;
  aspect-ratio: 1 / 1;
  position: absolute;
  left: 0;
  animation: ${rotating} 5s infinite linear;
`;

const Boosters = styled.div`
  position: absolute;
  right: 20px;
  bottom: 30px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5vw;
`;

const Booster = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BoosterImage = styled.img`
  width: 8vh;
  aspect-ratio: 0.83 / 1;
`;

const BoosterTimerRow = styled.div`
  width: 100%;
  height: 17px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoosterTimerArrow = styled.img`
  position: absolute;

  &:nth-child(1) {
    top: 0;
    left: 0;
    transform: rotate(180deg);
  }

  &:nth-child(2) {
    top: 0;
    right: 0;
    transform: rotate(270deg);
  }

  &:nth-child(3) {
    bottom: 0;
    left: 0;
    transform: rotate(90deg);
  }

  &:nth-child(4) {
    bottom: 0;
    right: 0;
  }
`;

const BoosterTimer = styled.p`
  color: #fc0;
  font-family: "Stapel_Bold";
  font-size: 2vw;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-transform: uppercase;
`;

const Styles = {
  Container,
  Box,
  BoxRow,
  BoxName,
  BoxValueRow,
  BoxValue,
  BoxImage,
  BoxesList,
  Stats,
  UpIcon,
  StatsValue,
  AskIcon,
  SoonRow,
  LockIcon,
  SoonLabel,
  Paginate,
  PaginateArrow,
  PaginateValue,
  AirdropIcon,
  Image,
  Slider,
  ShopBooster,
  OfferBooster,
  PlayBooster,
  GlowBoosterRow,
  TreeImage,
  Glow,
  Boosters,
  Booster,
  BoosterImage,
  BoosterTimerRow,
  BoosterTimer,
  BoosterTimerArrow,
};

export default Styles;
