import * as React from "react";
import { Spritesheet, Texture, utils, type ISpritesheetData } from "pixi.js";

// Store
import { useAppSelector } from "@store/index";
import { setLives } from "@store/reducers/app";

// Hooks
import useMapSize from "@hooks/useMapSize";
import useTouch from "@hooks/useTouch";
import useKeyboardControls from "@hooks/useKeyboardControls";
import useWebApp from "@hooks/useWebApp";

// Utils
import { type TSkin, type TLevelMapItem, TMapDay } from "@utils/api/types";
import {
  getInitialCoordinates,
  DIRECTION_TO_VECTOR,
  checkMove,
  angleByDirection,
  type Direction,
  type TCoordinates,
} from "@utils/game";
import formatLevelMap from "@utils/formatLevelMap";
import { getMap, subtractLive } from "@utils/api";

// Config
import getSpritesData from "@config/spritesData";
import { DEFAULT_LIVES } from "@config/consts";

type TEnemy = TCoordinates & {
  name: string;
};

type TUseGameParams = {
  lives: number;
  skin: TSkin | null;
  mapDay?: TMapDay;
  level?: TLevelMapItem[][];
  levelStyle?: number;
  isMoveDisabled?: boolean;
  allowedDirection?: Direction;
  onPlayerMove?: () => void;
  withReplay?: boolean;
  removeLive: () => void;
};

const useGame = (params: TUseGameParams) => {
  const {
    lives,
    skin,
    level,
    levelStyle,
    isMoveDisabled,
    allowedDirection,
    onPlayerMove,
    withReplay,
    removeLive,
  } = params;

  const [levelMap, setLevelMap] = React.useState<TLevelMapItem[][]>([]);
  const [isLevelMapLoading, setLevelMapLoading] =
    React.useState<boolean>(false);
  const [playerCoordinates, setPlayerCoordinates] =
    React.useState<TCoordinates>({
      x: 0,
      y: 0,
    });
  const [isPlayerMoving, setPlayerMoving] = React.useState<boolean>(false);
  const [nextDirection, setNextDirection] = React.useState<Direction | null>(
    null
  );
  const [playerAngle, setPlayerAngle] = React.useState(0);
  const [score, setScore] = React.useState<number>(0);
  const [mapDay, setMapDay] = React.useState<TMapDay | null>(
    params.mapDay || null
  );
  const [isLevelCompleted, setLevelCompleted] = React.useState<boolean>(false);
  const [activeModal, setActiveModal] = React.useState<
    "game-over" | "level-completed" | null
  >(null);
  const [screenAnimation, setScreenAnimation] = React.useState<"shake" | null>(
    null
  );
  const [enemies, setEnemies] = React.useState<TEnemy[]>([]);
  const [moveCoordinates, setMoveCoordinates] = React.useState<TCoordinates[]>(
    []
  );
  const [sprites, setSprites] =
    React.useState<Spritesheet<ISpritesheetData> | null>(null);
  const [totalCoins, setTotalCoins] = React.useState<number>(0);
  const [isDamaged, setDamaged] = React.useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { gridWidth, gridHeight, cellSize } = useMapSize();
  const { initData, impactOccurred } = useWebApp();

  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    if (level && levelStyle) {
      onParseLevel();
    }
  }, [level, levelStyle]);

  React.useEffect(() => {
    if (screenAnimation) {
      setTimeout(() => {
        setScreenAnimation(null);
      }, 500);
    }
  }, [screenAnimation]);

  React.useEffect(() => {
    if (mapDay) {
      onGetLevelMap();
      utils.clearTextureCache();
      onParseSprites();
    }
  }, [mapDay]);

  React.useEffect(() => {
    if (nextDirection && !isPlayerMoving) {
      onMovePlayer();
    }
  }, [nextDirection, isPlayerMoving]);

  React.useEffect(() => {
    if (isLevelCompleted) {
      setActiveModal("level-completed");
    }
  }, [isLevelCompleted]);

  React.useEffect(() => {
    if (lives === 0) {
      setActiveModal("game-over");
    }
  }, [lives]);

  React.useEffect(() => {
    onCheckEnemies();
  }, [enemies, playerCoordinates]);

  const onParseLevel = async (): Promise<void> => {
    if (!level || !levelStyle) {
      return;
    }

    setActiveModal(null);
    setLevelMapLoading(true);
    setLives(DEFAULT_LIVES);

    utils.clearTextureCache();

    const spritesData = getSpritesData(levelStyle);

    const sheet = new Spritesheet(
      Texture.from(spritesData.meta.image as string),
      spritesData
    );

    await sheet.parse();

    setSprites(sheet);
    setLevelMap(formatLevelMap(level));
    setPlayerCoordinates(getInitialCoordinates(level, cellSize));
    setLevelMapLoading(false);
    setPlayerAngle(0);
    setEnemies([]);
    setScore(0);
    setLevelCompleted(false);

    let total = 0;

    for (const row of level) {
      for (const cell of row) {
        if (cell.type === "coin") {
          total += 1;
        }
      }
    }

    setTotalCoins(total);
  };

  const onParseSprites = async (): Promise<void> => {
    if (!mapDay) {
      return;
    }

    const spritesData = getSpritesData(mapDay.level);

    const sheet = new Spritesheet(
      Texture.from(spritesData.meta.image as string),
      spritesData
    );

    await sheet.parse();

    setSprites(sheet);
  };

  const onCheckEnemies = (): void => {
    if (isLevelCompleted) {
      return;
    }

    const d = cellSize / 2;
    const findEnemy = enemies.find(
      (i) => i.x === playerCoordinates.x - d && i.y === playerCoordinates.y - d
    );

    if (findEnemy) {
      onDamage();
    }
  };

  const getDirectionDistance = (direction: Direction): number => {
    let distance = 0;

    const directionToVector = DIRECTION_TO_VECTOR[direction];
    const { x, y } = playerCoordinates;

    const d = cellSize / 2;
    let currentPositionY = (y - d) / cellSize + directionToVector.y;
    let currentPositionX = (x - d) / cellSize + directionToVector.x;

    while (checkMove(levelMap[currentPositionY][currentPositionX])) {
      distance += 1;

      currentPositionY += directionToVector.y;
      currentPositionX += directionToVector.x;
    }

    return distance;
  };

  const onCollectCoin = (x: number, y: number): void => {
    setLevelMap((prevMap) =>
      prevMap.map((rowArray, rowIndex) =>
        rowArray.map((cell, colIndex) => {
          if (colIndex === x && rowIndex === y) {
            impactOccurred("medium");
            setScore((prev) => prev + 1);

            return {
              type: "empty",
            };
          }

          return cell;
        })
      )
    );
  };

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onMovePlayer = async (): Promise<void> => {
    if (!nextDirection || isPlayerMoving || isLevelCompleted || !skin) {
      return;
    }

    setPlayerMoving(true);

    const directionToVector = DIRECTION_TO_VECTOR[nextDirection];
    const axis = directionToVector.x === 0 ? "y" : "x";
    const { x, y } = playerCoordinates;

    const d = cellSize / 2;
    let currentPositionX = (x - d) / cellSize + directionToVector.x;
    let currentPositionY = (y - d) / cellSize + directionToVector.y;

    while (checkMove(levelMap[currentPositionY][currentPositionX])) {
      await sleep(skin.default_speed / 3);
      const currentWall = levelMap[currentPositionY][currentPositionX];

      if (currentWall.type === "coin") {
        onCollectCoin(currentPositionX, currentPositionY);
      }

      const findEnemy = enemies.find(
        (i) =>
          i.x === currentPositionX * cellSize &&
          i.y === currentPositionY * cellSize
      );

      if (findEnemy) {
        onDamage();
      }

      if (currentWall.type === "finish") {
        impactOccurred("medium");
        setLevelCompleted(true);
      }

      setPlayerCoordinates((prev) => ({
        ...prev,
        [axis]: prev[axis] + directionToVector[axis] * cellSize,
      }));

      currentPositionX += directionToVector.x;
      currentPositionY += directionToVector.y;

      const nextWall = levelMap[currentPositionY][currentPositionX];

      if (nextWall.type === "laser") {
        onDamage();
      }
    }

    setPlayerMoving(false);
    setNextDirection(null);
    onPlayerMove?.();
  };

  const updateMoveCoordinates = async (
    direction: Direction,
    distance: number
  ): Promise<void> => {
    if (distance < 2 || isPlayerMoving || !skin) {
      return;
    }

    const directionToVector = DIRECTION_TO_VECTOR[direction];
    const { x, y } = playerCoordinates;
    const axis = directionToVector.x === 0 ? "y" : "x";

    for (const [index] of Array(distance - 1)
      .fill("i")
      .entries()) {
      const nextMove = {
        x,
        y,
      };

      nextMove[axis] =
        playerCoordinates[axis] +
        (directionToVector[axis] < 0
          ? directionToVector[axis] - index
          : directionToVector[axis] + index) *
          cellSize;

      setMoveCoordinates((prev) => [...prev, nextMove]);

      await sleep(skin.default_speed / 1.5);
    }

    setMoveCoordinates([]);
  };

  const handleDirection = (direction: Direction): void => {
    if (
      (allowedDirection && allowedDirection !== direction) ||
      activeModal !== null
    ) {
      return;
    }

    const distance = getDirectionDistance(direction);

    if (distance && !isMoveDisabled) {
      setPlayerAngle(angleByDirection[direction]);
      setNextDirection(direction);
      updateMoveCoordinates(direction, distance);
    }
  };

  useTouch(containerRef, handleDirection);
  useKeyboardControls(handleDirection);

  const onDamage = async (): Promise<void> => {
    if (
      isLevelCompleted ||
      !lives ||
      isDamaged ||
      activeModal === "game-over"
    ) {
      return;
    }

    setDamaged(true);

    setScreenAnimation("shake");
    impactOccurred("medium");

    if (lives >= 1) {
      removeLive();

      if (withReplay) {
        setActiveModal("game-over");
      }
    }

    if (sessionId) {
      await subtractLive(initData, sessionId);
    }

    setTimeout(() => {
      setDamaged(false);
    }, 1000);
  };

  const onGetLevelMap = async (): Promise<void> => {
    if (!mapDay) {
      return;
    }

    setLevelMapLoading(true);
    setActiveModal(null);

    const levelMapData = await getMap(mapDay.map_id);

    setLevelMap(formatLevelMap(levelMapData));
    setPlayerCoordinates(getInitialCoordinates(levelMapData, cellSize));
    setLevelMapLoading(false);
    setPlayerAngle(0);
    setEnemies([]);
    setScore(0);
    setLevelCompleted(false);

    let total = 0;

    for (const row of levelMapData) {
      for (const cell of row) {
        if (cell.type === "coin") {
          total += 1;
        }
      }
    }

    setTotalCoins(total);
  };

  const onCloseModal = (): void => {
    setActiveModal(null);
  };

  const updateEnemyPosition = (x: number, y: number, name: string): void => {
    if (isLevelCompleted) {
      return;
    }

    setEnemies((prev) => {
      const findEnemy = prev.find((enemy) => enemy.name === name);

      if (findEnemy) {
        return prev.map((enemy) => {
          if (enemy.name === name) {
            return {
              x,
              y,
              name,
            };
          }
          return enemy;
        });
      }
      return [...prev, { x, y, name }];
    });
  };

  const onResetLevel = (): void => {
    if (level && levelStyle) {
      onParseLevel();
    } else {
      onGetLevelMap();
    }
  };

  return {
    containerRef,
    levelMap,
    isLevelMapLoading,
    gridWidth,
    gridHeight,
    cellSize,
    playerCoordinates,
    playerAngle,
    score,
    level: mapDay?.level || 0,
    levelId: mapDay?.id || 0,
    isLevelCompleted,
    activeModal,
    screenAnimation,
    moveCoordinates,
    sprites,
    totalCoins,
    setNextLevel: setMapDay,
    onCloseModal,
    updateEnemyPosition,
    onResetLevel,
    onDamage,
  };
};

export default useGame;
