import * as React from "react";
import { ReactSVG } from "react-svg";

import cn from "classnames";

// Components
import Spinner from "@components/Spinner";

// Utils
import { formatPoints } from "@utils/format";
import type { TShopItem } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  selectedItem: TShopItem;
  onBuy: () => void;
  isBuyLoading: boolean;
  onShowSheet: () => void;
  isAnimateActive: boolean;
}

const ShopSelectedCard: React.FC<Props> = (props) => {
  const {
    selectedItem: { skin, price, is_paid, points, description },
    onBuy,
    isBuyLoading,
    onShowSheet,
    isAnimateActive,
  } = props;

  return (
    <Styles.Container>
      <Styles.Title>{skin?.name}</Styles.Title>
      {Number(price) ? (
        <>
          <ReactSVG
            src="/assets/icons/question2.svg"
            className={cn("question", {
              active: isAnimateActive,
            })}
            onClick={onShowSheet}
          />
          <Styles.Stats>
            <Styles.StatsItem>
              <Styles.StatsItemPlus>+</Styles.StatsItemPlus>
              <Styles.StatsItemIcon src="/assets/cr-coin.png" alt="icon" />
              <Styles.StatsItemValue>
                {formatPoints(points)}
              </Styles.StatsItemValue>
              <Styles.StatsItemSymbol>$CP</Styles.StatsItemSymbol>
            </Styles.StatsItem>
            <Styles.StatsItem>
              <Styles.StatsItemPlus>+</Styles.StatsItemPlus>
              <Styles.StatsItemIcon src="/assets/icons/up.svg" alt="icon" />
              <Styles.StatsItemValue>
                {Number(Number(skin?.skin_coef) * 100) - 100}%
              </Styles.StatsItemValue>
            </Styles.StatsItem>
          </Styles.Stats>
        </>
      ) : (
        <Styles.Description>{description}</Styles.Description>
      )}

      <Styles.Boosters>
        {Array(3)
          .fill("booster")
          .map((i, index) => (
            <Styles.Booster
              key={`${i}/${index}`}
              src={`/assets/icons/shop-booster-${index + 1}.png`}
              alt="booster"
            />
          ))}
      </Styles.Boosters>
      <Styles.BuyButton onClick={onBuy} disabled={isBuyLoading}>
        {isBuyLoading ? (
          <Spinner size={20} />
        ) : (
          <>
            {Number(price) > 0 && !is_paid ? (
              <>
                Buy {price}{" "}
                <Styles.BuyButtonIcon
                  src="/assets/icons/stars.svg"
                  alt="stars"
                />
              </>
            ) : (
              <>Choose and play</>
            )}
          </>
        )}
      </Styles.BuyButton>
      {/* {address ? (
        <button onClick={onSend}>Send 0.1 TON to PD address</button>
      ) : null} */}
    </Styles.Container>
  );
};

export default ShopSelectedCard;
