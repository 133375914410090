import * as React from "react";
import type { InvoiceStatuses } from "@twa-dev/types";

// Components
import Spinner from "@components/Spinner";

// Utils
import { formatPoints } from "@utils/format";
import { buyItem } from "@utils/api";
import type { TBoostPackage } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  data: TBoostPackage;
  initData: string;
  sessionId: string;
  openInvoice: (
    url: string,
    callback?: (status: InvoiceStatuses) => unknown
  ) => void;
  onSuccessPaid: () => void;
  onFailedPaid: () => void;
}

const BoostRatingPackage: React.FC<Props> = (props) => {
  const {
    data: { id, title, points, price, price_without_sale },
    initData,
    sessionId,
    openInvoice,
    onSuccessPaid,
    onFailedPaid,
  } = props;

  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onBuy = async (): Promise<void> => {
    setLoading(true);

    const invoiceUrl = await buyItem(initData, id, sessionId);

    if (invoiceUrl) {
      openInvoice(invoiceUrl, (status) => {
        if (status === "paid") {
          onSuccessPaid();
        } else if (status === "failed") {
          onFailedPaid();
        }
      });
    }

    setLoading(false);
  };

  return (
    <Styles.Package onClick={onBuy}>
      <Styles.PackageIcon
        src={`/assets/packages/${title.toLowerCase()}-package.png`}
        alt="icon"
      />
      <Styles.PackageRow>
        <Styles.PackageTitle>{title}</Styles.PackageTitle>
        <Styles.PackageRewardRow>
          <Styles.PackageRewardIcon src="/assets/cr-coin.png" alt="coin" />
          <Styles.PackageReward>+{formatPoints(points)}</Styles.PackageReward>
          <Styles.PackageRewardSymbol>$CP</Styles.PackageRewardSymbol>
        </Styles.PackageRewardRow>
      </Styles.PackageRow>
      {isLoading ? (
        <Spinner size={25} />
      ) : (
        <Styles.PricesRow>
          <Styles.ActualPriceRow>
            <Styles.Price>{price}</Styles.Price>
            <Styles.PriceIcon
              src="/assets/icons/stars-yellow.svg"
              alt="stars"
            />
          </Styles.ActualPriceRow>
          {price !== price_without_sale ? (
            <Styles.OldPrice>
              <Styles.Price>{price_without_sale}</Styles.Price>
              <Styles.PriceIcon
                src="/assets/icons/stars-yellow.svg"
                alt="stars"
              />
              <Styles.PriceLine />
            </Styles.OldPrice>
          ) : null}
        </Styles.PricesRow>
      )}
    </Styles.Package>
  );
};

export default BoostRatingPackage;
