import * as React from "react";
import { useLocation, Link } from "react-router-dom";
import cn from "classnames";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Config
import tabs from "@config/tabs";

// Styles
import Styles from "./styles";

interface Props {
  variant?: string;
}

const BottomNavigation: React.FC<Props> = (props) => {
  const { variant } = props;

  const { pathname } = useLocation();
  const { impactOccurred } = useWebApp();

  const onClickTab = (): void => {
    impactOccurred("heavy");
  };

  return (
    <Styles.Container className={cn(variant)}>
      <Styles.Row>
        {tabs.map((tab) => {
          const { title, icon, path } = tab;

          return (
            <Link to={path} key={title} onClick={onClickTab}>
              <Styles.Tab
                className={cn({
                  active: path === pathname,
                })}
              >
                <Styles.TabIcon src={`/assets/tabs/${icon}`} />
                {title}
              </Styles.Tab>
            </Link>
          );
        })}
      </Styles.Row>
    </Styles.Container>
  );
};

export default BottomNavigation;
