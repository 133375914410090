import * as React from "react";
import cn from "classnames";

// Components
import SheetWrapper from "@components/SheetWrapper";
import Button from "@components/Button";

// Store
import { useAppSelector } from "@store/index";

// Utils
import { formatPoints } from "@utils/format";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dayStreak: number;
}

const DayStreakSheet: React.FC<Props> = (props) => {
  const { isOpen, onClose, dayStreak } = props;

  const dailyRewards = useAppSelector((state) => state.app.dailyRewards);

  return (
    <SheetWrapper
      isOpen={isOpen}
      onClose={onClose}
      disableDrag
      snapPoints={[-50]}
    >
      <Styles.Title>Daily Streak!</Styles.Title>
      <Styles.Description>
        Come back tomorrow to keep your streak going. Tip: Skipping a day resets
        your streak.
      </Styles.Description>
      <Styles.Weeks>
        {Array(4)
          .fill("week")
          .map((i, index) => (
            <Styles.Week
              key={`${i}/${index}`}
              disabled={Math.ceil(dayStreak / 7) !== index + 1}
            >
              {index + 1} week
            </Styles.Week>
          ))}
      </Styles.Weeks>
      <Styles.List>
        {Array(7)
          .fill("card")
          .map((i, index) => {
            const startDay = (Math.ceil(dayStreak / 7) - 1) * 7;
            const isCompleted = dayStreak > startDay + index + 1;
            const getReward = dailyRewards.find(
              (i) => i.day === startDay + index + 1
            );

            if (!getReward) {
              return null;
            }

            return (
              <Styles.ListItem key={`${i}/${index}`}>
                <Styles.Card
                  className={cn({
                    active: startDay + index + 1 === dayStreak,
                  })}
                >
                  <Styles.CardRow>
                    <Styles.CardDayRow>
                      <Styles.CardTitle>
                        Day {startDay + index + 1}
                      </Styles.CardTitle>
                      <Styles.CardBoostRow>
                        <Styles.CardBoostIcon
                          src="/assets/icons/up.svg"
                          alt="icon"
                        />
                        <Styles.CardBoostValue>
                          {getReward.percent}%
                        </Styles.CardBoostValue>
                      </Styles.CardBoostRow>
                    </Styles.CardDayRow>
                    <Styles.CardValues>
                      <Styles.CardValueRow>
                        <Styles.CardValuePlus className="blue">
                          +
                        </Styles.CardValuePlus>
                        <Styles.CardValueIcon
                          src="/assets/icons/ticket.png"
                          alt="icon"
                          className="ticket"
                        />
                        <Styles.CardValue className="blue">
                          {getReward.tickets}
                        </Styles.CardValue>
                        <Styles.CardValueRowSymbol>
                          Ticket
                        </Styles.CardValueRowSymbol>
                      </Styles.CardValueRow>
                      <Styles.CardValueRow>
                        <Styles.CardValuePlus>+</Styles.CardValuePlus>
                        <Styles.CardValueIcon
                          src="/assets/cr-coin.png"
                          alt="icon"
                          className="coin"
                        />
                        <Styles.CardValue>
                          {formatPoints(getReward.points)}
                        </Styles.CardValue>
                        <Styles.CardValueRowSymbol>
                          $CP
                        </Styles.CardValueRowSymbol>
                      </Styles.CardValueRow>
                    </Styles.CardValues>
                    <Styles.CardIcon
                      src="/assets/icons/coin-with-ticket.png"
                      alt="icon"
                    />
                  </Styles.CardRow>
                  <Styles.CardGlow
                    className={cn({
                      big: index === 6,
                    })}
                  />
                  {isCompleted ? (
                    <Styles.CardOverlay>
                      <Styles.CheckIcon
                        src="/assets/icons/check.svg"
                        alt="check"
                      />
                    </Styles.CardOverlay>
                  ) : null}
                </Styles.Card>
              </Styles.ListItem>
            );
          })}
      </Styles.List>
      <Styles.ButtonRow>
        <Button title="Go play" onClick={onClose} />
      </Styles.ButtonRow>
    </SheetWrapper>
  );
};

export default DayStreakSheet;
