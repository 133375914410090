import styled from "styled-components";

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Semi_Expanded_Bold";
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
`;

const SubTitle = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Medium";
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
`;

const RewardRow = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 70px;
  border-radius: 15px;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  overflow: hidden;
  position: relative;

  .reward {
    color: #ffffff;
    font-family: "Stapel_Semi_Expanded_Bold";
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 0 -25px;
  }
`;

const Glow = styled.div`
  width: 100px;
  height: 95px;
  background: url("/assets/glow.png") no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RewardIcon = styled.img`
  width: 45px;
  height: 45px;
`;

const RewardValue = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 32px;
  font-weight: 700;
  line-height: 49px;
  text-transform: uppercase;
`;

const Actions = styled.div`
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Symbol = styled.p`
  color: #c7c7cc;
  font-family: "Stapel_Medium";
  font-size: 24px;
  font-weight: 500;
  line-height: 49px;
  text-transform: uppercase;
`;

const Styles = {
  Title,
  SubTitle,
  RewardRow,
  RewardIcon,
  RewardValue,
  Actions,
  Symbol,
  Glow,
};

export default Styles;
