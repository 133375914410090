import * as React from "react";
import Countdown, { type CountdownRenderProps } from "react-countdown";
import dayjs from "dayjs";

// Styles
import Styles from "./styles";

interface Props {
  onClick: () => void;
  image: string;
  withGlow?: boolean;
  background?: string;
}

const GameCharacterBooster: React.FC<Props> = (props) => {
  const { onClick, image, withGlow, background } = props;

  const [timer, setTimer] = React.useState<Date | null>(null);

  React.useEffect(() => {
    onSetTimer();
  }, []);

  const onSetTimer = (): void => {
    setTimer(dayjs().utc().endOf("day").toDate());
  };

  return (
    <Styles.Booster onClick={onClick}>
      {withGlow && background ? (
        <Styles.GlowBoosterRow
          style={{
            background: `url("${background}") no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <Styles.TreeImage src={image} alt="image" />
          <Styles.Glow src="/assets/offer-glow.png" alt="glow" />
        </Styles.GlowBoosterRow>
      ) : (
        <Styles.BoosterImage src={image} alt="offer" />
      )}
      {timer ? (
        <Styles.BoosterTimerRow>
          {Array(4)
            .fill("arrow")
            .map((i, index) => (
              <Styles.BoosterTimerArrow
                key={`${i}/${index}`}
                src="/assets/timer-arrow.svg"
                alt="arrow"
              />
            ))}
          <Countdown
            renderer={({ hours, minutes, seconds }: CountdownRenderProps) => (
              <Styles.BoosterTimer>
                {hours}:{minutes > 10 ? minutes : `0${minutes}`}:
                {seconds > 10 ? seconds : `0${seconds}`}
              </Styles.BoosterTimer>
            )}
            date={timer}
            onComplete={onSetTimer}
          />
        </Styles.BoosterTimerRow>
      ) : null}
    </Styles.Booster>
  );
};

export default GameCharacterBooster;
