import * as React from "react";

// Utils
import { formatNameInitials } from "@utils/format";

// Styles
import Styles from "./styles";

interface Props {
  size: number;
  avatar?: string;
}

const Avatar: React.FC<Props> = (props) => {
  const { size, avatar } = props;

  return (
    <Styles.Container
      style={{ width: size, height: size, borderRadius: size / 6.25 }}
    >
      <Styles.Avatar src={avatar} alt="avatar" />
    </Styles.Container>
  );
};

export default Avatar;
