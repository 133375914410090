// Utils
import { type TLevelMapItem } from "@utils/api/types";

const tutorialLevel: TLevelMapItem[][] = [
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "29" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "31" },
    { type: "wall", name: "31" },
    { type: "wall", name: "13" },
    { type: "wall", name: "28" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "18" },
    { type: "wall", name: "52" },
    { type: "wall", name: "52" },
    { type: "wall", name: "17" },
    { type: "wall", name: "16" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "12" },
    { type: "wall", name: "31" },
    { type: "wall", name: "31" },
    { type: "wall", name: "28" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "29" },
    { type: "wall", name: "13" },
    { type: "wall", name: "28" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "15" },
    { type: "cannon", name: "102" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "26" },
    { type: "wall", name: "16" },
    { type: "coin" },
    { type: "empty" },
    { type: "coin" },
    { type: "wall", name: "37" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "15" },
    { type: "empty" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "46" },
    { type: "coin" },
    { type: "empty" },
    { type: "coin" },
    { type: "wall", name: "37" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "12" },
    { type: "wall", name: "14" },
    { type: "empty" },
    { type: "wall", name: "12" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "28" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "46" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "37" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "46" },
    { type: "coin" },
    { type: "wall", name: "18" },
    { type: "wall", name: "17" },
    { type: "wall", name: "27" },
    { type: "wall", name: "26" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "16" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "46" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "59" },
    { type: "wall", name: "29" },
    { type: "wall", name: "13" },
    { type: "wall", name: "28" },
    { type: "wall", name: "86" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "finish" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "46" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "86" },
    { type: "wall", name: "15" },
    { type: "empty" },
    { type: "wall", name: "11" },
    { type: "wall", name: "59" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "26" },
    { type: "wall", name: "17" },
    { type: "wall", name: "27" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "wall", name: "12" },
    { type: "wall", name: "13" },
    { type: "wall", name: "14" },
    { type: "empty" },
    { type: "wall", name: "12" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "28" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "59" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "58" },
    { type: "wall", name: "29" },
    { type: "wall", name: "13" },
    { type: "wall", name: "13" },
    { type: "wall", name: "28" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "26" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "16" },
    { type: "empty" },
    { type: "wall", name: "18" },
    { type: "wall", name: "17" },
    { type: "wall", name: "16" },
    { type: "coin" },
    { type: "wall", name: "12" },
    { type: "wall", name: "13" },
    { type: "wall", name: "14" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "enemy", name: "100" },
    { type: "wall", name: "11" },
    { type: "wall", name: "86" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "26" },
    { type: "wall", name: "17" },
    { type: "wall", name: "27" },
    { type: "wall", name: "58" },
    { type: "wall", name: "26" },
    { type: "wall", name: "17" },
    { type: "wall", name: "74" },
    { type: "wall", name: "20" },
    { type: "wall", name: "20" },
    { type: "wall", name: "21" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "coin" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "empty" },
    { type: "empty" },
    { type: "empty" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "15" },
    { type: "coin" },
    { type: "player" },
    { type: "wall", name: "84" },
    { type: "wall", name: "85" },
    { type: "wall", name: "11" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "26" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "17" },
    { type: "wall", name: "27" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
  [
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
    { type: "wall", name: "10" },
  ],
];

export default tutorialLevel;
