import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 206px;
  min-height: 206px;
  background-color: #10111a;
  border-radius: 26px 10px 10px 26px;
  background-image: url("/assets/shop-special-card-bg.svg");
  background-size: cover;
  padding: 20px 0 10px 20px;
  position: relative;
  overflow: hidden;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;

const Badge = styled.div`
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #fc0;
`;

const BadgeValue = styled.p`
  color: #000000;
  font-family: "Stapel_Bold";
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Title = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre-line;
  z-index: 2;
`;

const ButtonRow = styled.div`
  width: 145px;
  height: 56px;
  background: url("/assets/shop-special-card-button.svg") no-repeat;
  right: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 18px 0 0 22px;
`;

const Image = styled.img`
  width: 206px;
  height: 206px;
  position: absolute;
  right: 0;
  top: 0;
`;

const SaleRow = styled.div`
  width: 85px;
  height: 36px;
  background: url("/assets/shop-special-card-sale.svg") no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

const Sale = styled.p`
  color: #ffffff;
  font-family: "Stapel_Condensed_Extra_Bold";
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase;
`;

const BuyLabel = styled.p`
  color: #fc0;
  font-family: "Stapel_Bold";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

const ButtonArea = styled.div`
  width: 123px;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 28px;
`;

const Prices = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Price = styled.p`
  color: #ffffff;
  font-family: "Stapel_Medium";
  font-size: 14px;
  font-weight: 500;
`;

const PriceIcon = styled.img`
  width: 13px;
  height: 13px;
`;

const Carousel = styled.div`
  margin: 10px 0 0 0;
  width: 185px;
`;

const CarouselItem = styled.div`
  width: 55px;
  height: 100px;
  border-radius: 4px;
  border: 0.5px solid #bd52fd;
  background-color: #261937;
  backdrop-filter: blur(2.5px);
  background-image: url("/assets/icons/card-grid.svg");
  background-size: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const CarouselItemHeading = styled.div`
  width: 100%;
  height: 16px;
  min-height: 16px;
  background: url("/assets/shop-carousel-heading.svg") no-repeat;
  padding: 0 4px;
  display: flex;
  align-items: center;
  z-index: 2;
`;

const CarouselItemHeadingTitle = styled.p`
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 6px;
  font-weight: 700;
  letter-spacing: 0.321px;
  text-transform: uppercase;
`;

const CarouselItemOverlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: radial-gradient(
    54.06% 52.3% at 50% 50%,
    rgba(16, 17, 26, 0) 60%,
    #10111a 100%
  );
  position: absolute;
`;

const CarouselItemBody = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarouselItemImage = styled.img`
  height: 70px;
`;

const Dots = styled.div`
  margin: 10px 0 0 0;
  width: 185px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  border: 0.5px solid rgb(255 255 255 / 50%);

  &.active {
    border: 1px solid #bd52fd;
    background-color: #bd52fd;
  }
`;

const Styles = {
  Container,
  Heading,
  Badge,
  BadgeValue,
  Title,
  ButtonRow,
  Image,
  SaleRow,
  Sale,
  BuyLabel,
  ButtonArea,
  Prices,
  PriceRow,
  Price,
  PriceIcon,
  Carousel,
  CarouselItem,
  CarouselItemHeading,
  CarouselItemHeadingTitle,
  CarouselItemOverlay,
  CarouselItemBody,
  CarouselItemImage,
  Dots,
  Dot,
};

export default Styles;
