import styled, { keyframes } from "styled-components";

const swipeLeft = keyframes`
  50% { transform: translate(-20px) rotate(-15deg); }
  100% { transform: translate(0px) rotate(0); }
`;

const swipeRight = keyframes`
  50% { transform: translate(+20px) rotate(+15deg); }
  100% { transform: translate(0px) rotate(0); }
`;

const swipeUp = keyframes`
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const swipeDown = keyframes`
  50% { transform: translateY(+20px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  position: fixed;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.UP,
  &.DOWN {
    flex-direction: row;
    align-items: center;
  }

  &.DOWN {
    .arrow {
      transform: rotate(270deg);
    }

    .hand {
      animation: ${swipeDown} 2s infinite;
    }
  }

  &.UP {
    .arrow {
      transform: rotate(90deg);
    }

    .hand {
      animation: ${swipeUp} 2s infinite;
    }
  }

  &.LEFT {
    .hand {
      animation: ${swipeLeft} 2s infinite;
    }
  }

  &.RIGHT {
    .hand {
      animation: ${swipeRight} 2s infinite;
    }

    .arrow {
      transform: scale(-1);
    }
  }
`;

const Arrow = styled.img`
  width: 21px;
  height: 6px;
`;

const Hand = styled.img`
  width: 32px;
  height: 32px;
  transition: all 0.5s;
`;

const Styles = {
  Container,
  Arrow,
  Hand,
};

export default Styles;
