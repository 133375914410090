import * as React from "react";
import cn from "classnames";
import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";

// Components
import Wrapper from "@components/Wrapper";
import Button from "@components/Button";
import BackButton from "@components/BackButton";

// Styles
import Styles from "./styles";

type TPrize = {
  id: string;
  image: string;
  text: string;
};

const prizeList: TPrize[] = [];

const CryptoBoxPage: React.FC = () => {
  // const [isBoxShaking, setBoxShaking] = React.useState<boolean>(false);
  const [isSpinning, setSpinning] = React.useState<boolean>(false);
  const [start, setStart] = React.useState<boolean>(false);
  const [prizeIndex, setPrizeIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (!prizeIndex || start) {
      return;
    }

    setStart(true);
  }, [prizeIndex, start]);

  React.useEffect(() => {
    if (!isSpinning || !prizeList.length) {
      return;
    }

    const prepare = async () => {
      const newPrizeIndex = 1;
      setPrizeIndex(newPrizeIndex);
      setStart(false);
    };

    prepare();
  }, [isSpinning, prizeList]);

  const handlePrizeDefined = () => {
    setSpinning(false);
  };

  // React.useEffect(() => {
  //   if (isBoxShaking) {
  //     setTimeout(() => {
  //       setBoxShaking(false);
  //       setActive(true);
  //     }, 1500);
  //   }
  // }, [isBoxShaking]);

  const onStart = (): void => {
    setSpinning(true);
  };

  return (
    <Wrapper withProfile>
      <BackButton />
      <Styles.Container>
        <Styles.CryptoBox src="/assets/cryptobox.svg" alt="cryptobox" />
        <Styles.RouletteRow>
          <RoulettePro
            start={start}
            prizes={prizeList}
            prizeIndex={49}
            spinningTime={3}
            onPrizeDefined={handlePrizeDefined}
            options={{ stopInCenter: true, withoutAnimation: true }}
          />
        </Styles.RouletteRow>
        <Styles.Bottom>
          <Button title="Start" onClick={onStart} />
        </Styles.Bottom>
      </Styles.Container>
    </Wrapper>
  );
};

export default CryptoBoxPage;
