import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;

  &:not(:nth-child(2)) {
    transform: scale(0.8);
  }
`;

const AvatarRow = styled.div`
  width: 28vw;
  aspect-ratio: 1 / 0.89;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &.gold {
    background: url("/assets/gold-award.svg") no-repeat;
    background-size: cover;
  }

  &.silver {
    background: url("/assets/silver-award.svg") no-repeat;
    background-size: cover;
  }

  &.bronze {
    background: url("/assets/bronze-award.svg") no-repeat;
    background-size: cover;
  }

  > div {
    width: 70% !important;
    border-radius: 100% !important;
    position: absolute;
    top: 9%;
    aspect-ratio: 1 / 1;
    height: inherit !important;
    overflow: hidden;
    display: flex;
    align-items: flex-end;

    img {
      width: 80%;
      height: 80%;
    }
  }
`;

const Time = styled.p`
  color: #ffffff;
  font-family: "DS-Digital", sans-serif;
  font-size: 3.8vw;
  font-weight: 700;
  letter-spacing: 0.48px;
  position: absolute;
  top: -15px;
`;

const Stats = styled.div`
  margin: 6px 0 0 0;
  width: 28vw;
  aspect-ratio: 1 / 0.33;
  background: url("/assets/top-user-card.svg") no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Name = styled.p`
  margin: 1vw 0 0 0;
  color: #ffffff;
  font-family: "Stapel_Bold";
  font-size: 3.5vw;
  font-weight: 700;
  letter-spacing: 0.45px;
`;

const BalanceRow = styled.div`
  margin: -2px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const BalanceIcon = styled.img`
  width: 10px;
  height: 10px;
`;

const Balance = styled.p`
  color: #ffffff;
  font-family: "DS-Digital", sans-serif;
  font-size: 4vw;
  font-weight: 700;
  letter-spacing: 0.45px;
`;

const Styles = {
  Container,
  AvatarRow,
  Time,
  Stats,
  Name,
  BalanceRow,
  BalanceIcon,
  Balance,
};

export default Styles;
