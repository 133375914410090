import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Stapel_Expanded_Bold";
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #aeaeb2;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
`;

const LivesRow = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const LivesIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img:not(:first-child) {
    margin: 0 0 0 -15px;
  }
`;

const LivesIcon = styled.img`
  width: 38px;
  height: 36px;
`;

const Lives = styled.p`
  color: #ffffff;
  font-family: "Stapel_Expanded_Bold";
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-bottom: calc(100vh - var(--tg-viewport-height, 100vh));
`;

const Styles = {
  Container,
  Title,
  Description,
  LivesRow,
  LivesIcons,
  LivesIcon,
  Lives,
  Actions,
};

export default Styles;
