import * as React from "react";

// Components
import ModalWrapper from "@components/ModalWrapper";
import Button from "@components/Button";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirmSkip: () => void;
}

const SkipTutorialModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, onConfirmSkip } = props;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Styles.Title>Warning</Styles.Title>
      <Styles.Description>
        Skip the tutorial and lose your reward?
      </Styles.Description>
      <Styles.ValueRow>
        <Styles.Glow>
          <Styles.Coin src="/assets/cr-coin.png" alt="coin" />
        </Styles.Glow>

        <Styles.Value>5 000</Styles.Value>
        <Styles.ValueSymbol>$CP</Styles.ValueSymbol>
      </Styles.ValueRow>
      <Styles.Actions>
        <Button title="Continue Tutorial" onClick={onClose} />
        <Button
          title="Skip Tutorial"
          variant="notfilled"
          onClick={onConfirmSkip}
        />
      </Styles.Actions>
    </ModalWrapper>
  );
};

export default SkipTutorialModal;
