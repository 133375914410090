import * as React from "react";
import Modal, { type Styles as ModalStyles } from "react-modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
}

const style: ModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 40px)",
    padding: "20px 15px",
    backgroundColor: "#1C1D1F",
    borderRadius: 30,
    border: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  overlay: {
    backgroundColor: "rgba(24, 50, 86, 0.50)",
    backdropFilter: "blur(2.5px)",
  },
};

const ModalWrapper: React.FC<Props> = (props) => {
  const { isOpen, onClose, children, shouldCloseOnOverlayClick = true } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={style}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
