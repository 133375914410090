import * as React from "react";
import Countdown, { type CountdownRenderProps } from "react-countdown";
import dayjs from "dayjs";

// Components
import Button from "@components/Button";
import Progress from "./MiningProgress";
import Levels from "./MiningLevels";
import Spinner from "@components/Spinner";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance, setMining } from "@store/reducers/app";

// Hooks
import useCountdown from "@hooks/useCountdown";
import useWebApp from "@hooks/useWebApp";

// Utils
import { formatPoints } from "@utils/format";
import { claimMining, getBalance } from "@utils/api";
import type { TMapDay, TMiningInfo } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  levelMap: TMapDay[];
  isDayComplete: boolean;
  onShowMining: () => void;
  onPlay: () => void;
  onComplete: () => void;
  mining: TMiningInfo | null;
  onCompleteMining: () => void;
}

const Mining: React.FC<Props> = (props) => {
  const {
    levelMap,
    isDayComplete,
    onShowMining,
    onPlay,
    onComplete,
    mining,
    onCompleteMining,
  } = props;
  const isMiningInactive =
    mining === null ||
    (mining?.start_farm === null && mining.finish_farm === null);

  const [isFarmFinished, setFarmFinished] = React.useState<boolean>(false);
  const [isButtonLoading, setButtonLoading] = React.useState<boolean>(false);

  const [
    count,
    { startCountdown, setCountStart, stopCountdown, resetCountdown, setStep },
  ] = useCountdown({
    countStart: 0,
    isIncrement: true,
    countStop: mining?.max_claim || 63000,
  });
  const { initData } = useWebApp();

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.app.sessionId);

  React.useEffect(() => {
    if (mining) {
      onCheckMining();
    }
  }, [mining]);

  const onCheckMining = (): void => {
    if (!mining) {
      return;
    }

    const isFinished =
      dayjs().utc().diff(dayjs(mining.finish_farm), "seconds") >= 0;

    if (isFinished) {
      stopCountdown();
      setFarmFinished(true);
      setCountStart(mining.max_claim);
    } else {
      setCountStart(
        dayjs().utc().diff(dayjs(mining.start_farm), "seconds") *
          mining.amount_per_second
      );
      setStep(mining.amount_per_second);
      startCountdown();
    }
  };

  const getProgressLineWidth = (): string => {
    if (!mining) {
      return "0%";
    }

    const { finish_farm, start_farm } = mining;

    const totalFarmSeconds = dayjs(finish_farm).diff(
      dayjs(start_farm),
      "seconds"
    );
    const remainingTime = dayjs().utc().diff(dayjs(start_farm), "seconds");

    return `${(remainingTime / totalFarmSeconds) * 100}%`;
  };

  const onClaim = async (): Promise<void> => {
    setButtonLoading(true);
    dispatch(setMining(await claimMining(initData, sessionId)));
    dispatch(setBalance(await getBalance(initData, sessionId)));
    setButtonLoading(false);
    setFarmFinished(false);
  };

  return (
    <Styles.Container>
      <Styles.Row>
        {isMiningInactive ? (
          <>
            <Progress levelMap={levelMap} isDayComplete={isDayComplete} />
            <Levels
              isDayComplete={isDayComplete}
              onComplete={onComplete}
              levelMap={levelMap}
            />
          </>
        ) : (
          <Styles.MiningIconRow>
            <Styles.Glow src="/assets/price-glow.png" alt="glow" />
            <Styles.MiningIcon src="/assets/mining.gif" alt="mining" />
          </Styles.MiningIconRow>
        )}
        <Styles.Actions>
          {!isMiningInactive ? (
            <Styles.MiningRow>
              <Styles.MiningRowOverlay />
              <Styles.MiningProgress style={{ width: getProgressLineWidth() }}>
                <Styles.MiningProgressIcon
                  src="/assets/cr-coin.png"
                  alt="coin"
                />
                <Styles.MiningProgressValue>
                  {formatPoints(+Number(count).toFixed(1))}
                </Styles.MiningProgressValue>
                <Styles.MiningProgressSymbol>$CP</Styles.MiningProgressSymbol>
              </Styles.MiningProgress>
              <Countdown
                renderer={({
                  days,
                  hours,
                  minutes,
                  seconds,
                }: CountdownRenderProps) => (
                  <span>
                    {days > 0 ? `${days}d ` : ""}
                    {hours > 0 ? `${hours}h ` : ""}{" "}
                    {minutes > 10 ? minutes : `0${minutes}`}m{" "}
                    {seconds > 10 ? seconds : `0${seconds}`}s
                  </span>
                )}
                date={mining.finish_farm}
                onComplete={onCheckMining}
              />
            </Styles.MiningRow>
          ) : (
            <Button title="Play" onClick={onPlay} />
          )}

          {isFarmFinished ? (
            <Styles.FarmButton onClick={onClaim} disabled={isButtonLoading}>
              {isButtonLoading ? <Spinner size={20} /> : "Claim"}
            </Styles.FarmButton>
          ) : null}
          {!isFarmFinished && isMiningInactive ? (
            <Styles.FarmButton
              onClick={onShowMining}
              disabled={!isMiningInactive}
            >
              Mining
            </Styles.FarmButton>
          ) : null}
        </Styles.Actions>
      </Styles.Row>
    </Styles.Container>
  );
};

export default Mining;
