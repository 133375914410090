import * as React from "react";
import { ReactSVG } from "react-svg";

// Styles
import Styles from "./styles";

interface Props {
  size: number;
}

const Spinner: React.FC<Props> = (props) => {
  const { size } = props;

  return (
    <Styles.Container style={{ width: size, height: size }}>
      <ReactSVG
        src="/assets/icons/spinner.svg"
        wrapper="svg"
        width={size}
        height={size}
      />
    </Styles.Container>
  );
};

export default Spinner;
