import * as React from "react";
import cn from "classnames";

// Components
import Spinner from "@components/Spinner";

// Styles
import Styles from "./styles";

interface Props {
  isLoading: boolean;
  price: number;
  currency: "stars" | "ton";
  onClick: () => void;
  disabled: boolean;
}

const ShopOfferButton: React.FC<Props> = (props) => {
  const { isLoading, price, currency, onClick, disabled } = props;

  return (
    <Styles.ActionButton onClick={onClick} disabled={disabled}>
      {isLoading ? (
        <Spinner size={25} />
      ) : (
        <>
          <Styles.ButtonPriceRow>
            <Styles.ButtonPriceLabel>
              Buy{" "}
              <Styles.ButtonPrice
                className={cn({
                  blue: currency === "ton",
                  yellow: currency === "stars",
                })}
              >
                {price}
              </Styles.ButtonPrice>
            </Styles.ButtonPriceLabel>
            <Styles.ButtonCurrencyIcon
              src={`/assets/icons/${currency}.svg`}
              alt="ton"
            />
          </Styles.ButtonPriceRow>
          <Styles.ButtonCurrency>
            {currency === "stars" ? "(telegram stars)" : "(TON)"}
          </Styles.ButtonCurrency>
        </>
      )}
    </Styles.ActionButton>
  );
};

export default ShopOfferButton;
