import * as React from "react";

// Components
import Avatar from "@components/Avatar";

// Utils
import { formatPoints } from "@utils/format";
import type { TReferral } from "@utils/api/types";

// Styles
import Styles from "./styles";

interface Props {
  referral: TReferral;
}

const ReferalCard: React.FC<Props> = (props) => {
  const {
    referral: { chat_id, reward, avatar_url, user_name },
  } = props;

  return (
    <Styles.Container>
      <Avatar size={50} avatar={avatar_url} />
      <Styles.Row>
        <Styles.Username>{user_name || `user${chat_id}`}</Styles.Username>
        <Styles.Rank>#{chat_id}</Styles.Rank>
      </Styles.Row>
      <Styles.CoinsRow>
        <Styles.CoinsIcon src="/assets/cr-coin.png" alt="coin" />
        <Styles.CoinsValue>{formatPoints(reward)}</Styles.CoinsValue>
        <Styles.CoinsSymbol>$CP</Styles.CoinsSymbol>
      </Styles.CoinsRow>
    </Styles.Container>
  );
};

export default ReferalCard;
