import * as React from "react";
import { Sheet } from "react-modal-sheet";
import cn from "classnames";

// Styles
import Styles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  snapPoints?: number[];
  children: React.ReactNode;
  bottom?: React.ReactNode;
  onCloseEnd?: () => void;
  disableDrag?: boolean;
  isBlack?: boolean;
  initialSnap?: number;
  hideBackdrop?: boolean;
  removeDetent?: boolean;
  backgroundImage?: string;
}

const SheetWrapper: React.FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    snapPoints,
    children,
    bottom,
    onCloseEnd,
    disableDrag,
    isBlack,
    initialSnap,
    hideBackdrop,
    removeDetent,
    backgroundImage,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <Styles.Container
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={snapPoints}
      detent={removeDetent ? undefined : "content-height"}
      onCloseEnd={onCloseEnd}
      disableDrag={disableDrag}
      className={cn({
        black: isBlack,
        hideBackdrop,
      })}
      initialSnap={initialSnap}
    >
      <Sheet.Container style={{ backgroundImage }}>
        <Sheet.Header>
          <Styles.Heading>
            {!disableDrag ? <Styles.Handle /> : null}
          </Styles.Heading>
        </Sheet.Header>
        <Sheet.Content>
          <Sheet.Scroller>{children}</Sheet.Scroller>
          {bottom ? <Styles.Bottom>{bottom}</Styles.Bottom> : null}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Styles.Container>
  );
};

export default SheetWrapper;
