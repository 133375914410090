import * as React from "react";
import { useInterval } from "usehooks-ts";

// Components
import Wrapper from "@components/Wrapper";
import TopUser from "@components/TopUser";
import Button from "@components/Button";
import Avatar from "@components/Avatar";
import Spinner from "@components/Spinner";
import LeaderboardCard from "@components/LeaderboardCard";

// Sheets
import BoostRatingSheet from "@sheets/BoostRating";
import PaymentApprovedSheet from "@sheets/PaymentApproved";
import PaymentNotApprovedSheet from "@sheets/PaymentNotApproved";

// Store
import { useAppDispatch, useAppSelector } from "@store/index";
import { setBalance } from "@store/reducers/app";

// Hooks
import useWebApp from "@hooks/useWebApp";

// Utils
import {
  getHillKings,
  getLeaderboard,
  getBecomeKingInvoice,
  getBalance,
} from "@utils/api";
import { formatPoints } from "@utils/format";
import type { THillKing, TLeaderboardStats } from "@utils/api/types";

// Styles
import Styles from "./styles";

const LeaderboardPage: React.FC = () => {
  const [stats, setStats] = React.useState<TLeaderboardStats | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [activeSheet, setActiveSheet] = React.useState<
    "boost-rating" | "approved" | "not-approved" | null
  >(null);
  const [isBecomeLoading, setBecomeLoading] = React.useState<boolean>(false);
  const [isSecondButtonVisible, setSecondButtonVisible] =
    React.useState<boolean>(false);
  const [hillKings, setHillKings] = React.useState<THillKing[]>([]);
  const [isUserTopOne, setUserTopOne] = React.useState<boolean>(false);

  const { initData, openInvoice, openTelegramLink } = useWebApp();

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.app.sessionId);
  const user = useAppSelector((state) => state.app.user);

  useInterval(() => {
    onGetKings();
  }, 5000);

  React.useEffect(() => {
    onGetStats();
    onGetKings();
  }, []);

  const onGetKings = async (): Promise<void> => {
    const kings = await getHillKings(initData, sessionId);

    setHillKings(kings);

    if (kings.length === 3) {
      const [king1, king2, king3] = kings;

      setHillKings([king2, king1, king3]);
      setUserTopOne(king1.user_id === user?.id);
    }
  };

  const onGetStats = async (): Promise<void> => {
    if (!sessionId) {
      return;
    }

    setLoading(true);
    setStats(await getLeaderboard(initData, sessionId));
    setLoading(false);
  };

  const onBoostRating = (): void => {
    setActiveSheet("boost-rating");
  };

  const onCloseSheet = (): void => {
    setActiveSheet(null);
  };

  const onBecomeKing = async (): Promise<void> => {
    if (isBecomeLoading) {
      return;
    }

    setBecomeLoading(true);

    const invoiceUrl = await getBecomeKingInvoice(initData, sessionId);

    if (!invoiceUrl) {
      setBecomeLoading(false);
      setActiveSheet("not-approved");
      return;
    }

    openInvoice(invoiceUrl, (status) => {
      if (status === "paid") {
        setActiveSheet("approved");
        onGetKings();
      } else if (status === "failed") {
        setActiveSheet("not-approved");
      }
    });

    setBecomeLoading(false);
  };

  const onScroll = (e: React.UIEvent<HTMLElement>): void => {
    const { scrollTop } = e.currentTarget;

    if (scrollTop >= 400 && !isSecondButtonVisible) {
      setSecondButtonVisible(true);
    } else if (scrollTop < 400 && isSecondButtonVisible) {
      setSecondButtonVisible(false);
    }
  };

  const onSuccessPaid = async (): Promise<void> => {
    setActiveSheet("approved");

    if (sessionId) {
      dispatch(setBalance(await getBalance(initData, sessionId)));
      setStats(await getLeaderboard(initData, sessionId));
    }
  };

  const onFailedPaid = (): void => {
    setActiveSheet("not-approved");
  };

  const onOpenProfile = (king: THillKing) => (): void => {
    if (king.user_name) {
      openTelegramLink(`https://t.me/${king.user_name}`);
    }
  };

  return (
    <Wrapper withProfile withNavigation>
      <Styles.Container onScroll={onScroll}>
        <Styles.CoverSpace src="/assets/airdrop-space.gif" alt="space" />
        {/* <Styles.Overlay /> */}
        {/* <Styles.Coins src="/assets/leaderboard-coins.png" alt="coins" /> */}
        <Styles.Row>
          <Styles.Logo src="/assets/king-of-the-hills.png" alt="logo" />
          {hillKings.length ? (
            <Styles.TopUsers>
              {hillKings.map((king) => (
                <TopUser
                  key={`${king.id}/${king.user_id}`}
                  king={king}
                  onOpenProfile={onOpenProfile(king)}
                />
              ))}
            </Styles.TopUsers>
          ) : null}
          <Styles.Actions>
            <Button
              title="Become a king"
              price={100}
              onClick={onBecomeKing}
              isLoading={isBecomeLoading}
              disabled={isUserTopOne}
            />
            <Button
              title="boost"
              yellowTitle="your $rating"
              onClick={onBoostRating}
            />
          </Styles.Actions>
        </Styles.Row>
        <Styles.List>
          {isLoading ? (
            <Styles.SpinnerRow>
              <Spinner size={40} />
            </Styles.SpinnerRow>
          ) : null}
          {!isLoading && stats ? (
            <>
              <Styles.ListHeading>
                <Styles.ListHeadingTitle>
                  {formatPoints(stats.count)} players
                </Styles.ListHeadingTitle>
                <Styles.ListHeadingTitle>Rating</Styles.ListHeadingTitle>
              </Styles.ListHeading>
              <Styles.UsersList>
                {stats.top.map((user, userIndex) => (
                  <LeaderboardCard
                    key={`${user.user_id}/${user.chat_id}/${userIndex}`}
                    rank={user.user_rank}
                    points={user.user_balance}
                    avatar={user.avatar_url}
                    user_name={user.user_name}
                    chat_id={user.chat_id}
                  />
                ))}
              </Styles.UsersList>
            </>
          ) : null}
        </Styles.List>
        {stats ? (
          <Styles.Card>
            <Styles.CardUser>
              <Avatar size={50} avatar={stats.current_user.avatar_url} />
              <Styles.CardRow>
                <Styles.CardTitle>
                  {stats.current_user.user_name ||
                    `user${stats.current_user.chat_id}`}
                </Styles.CardTitle>
                <Styles.CardStats>
                  <Styles.CardStatsIcon src="/assets/cr-coin.png" alt="coin" />
                  <Styles.CardStatsValue>
                    {formatPoints(stats.current_user.user_balance)}
                  </Styles.CardStatsValue>
                  <Styles.CardStatsSymbol>$CP</Styles.CardStatsSymbol>
                </Styles.CardStats>
              </Styles.CardRow>
              <Styles.CardValueRow>
                <Styles.CardValue>
                  #{stats.current_user.user_rank}
                </Styles.CardValue>
                {!isSecondButtonVisible ? (
                  <Styles.BoostLabel onClick={onBoostRating}>
                    boost $rating
                  </Styles.BoostLabel>
                ) : null}
              </Styles.CardValueRow>
            </Styles.CardUser>
            {isSecondButtonVisible ? (
              <Button
                title="boost"
                yellowTitle="your $rating"
                onClick={onBoostRating}
              />
            ) : null}
          </Styles.Card>
        ) : null}
      </Styles.Container>
      {stats ? (
        <BoostRatingSheet
          isOpen={activeSheet === "boost-rating"}
          onClose={onCloseSheet}
          user={stats.current_user}
          onSuccessPaid={onSuccessPaid}
          onFailedPaid={onFailedPaid}
        />
      ) : null}
      <PaymentApprovedSheet
        isOpen={activeSheet === "approved"}
        onClose={onCloseSheet}
      />
      <PaymentNotApprovedSheet
        isOpen={activeSheet === "not-approved"}
        onClose={onCloseSheet}
      />
    </Wrapper>
  );
};

export default LeaderboardPage;
