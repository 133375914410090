const texts = [
  `Hi! I'm Azura.\nI'll guide you in Crypto Rush.\nLet's start!`,
  `To move, swipe your finger on the\nscreen and your hero will follow.`,
  `Great!\nYou got points! Save them for skins,\nskills, and new features.`,
  `Warning! An enemy ahead — it's\ndangerous and can take your lives 🩷.\nTry to avoid collisions!`,
  `Watch out! Hidden spikes ahead!\nThey appear when you're close and\nactivate when you pass.`,
  `Caution: laser barriers ahead!\nDon't touch. Find a safe way\naround.`,
  `Cannon ahead! Wait for it to fire, then\nquickly pass.`,
  `Great job!\nYou beat level 1 and earned your first\n$CP! More cool stuff ahead!`,
];

export default texts;
